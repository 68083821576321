import React, { FC, JSX } from "react";
import { Button } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import css from "./ButtonCustom.module.css";

interface IButtonPrimaryProps {
  id?: string;
  size?: SizeType;
  text?: string;
  type: "text" | "link" | "default" | "primary" | "dashed" | undefined;
  ghost?: boolean;
  danger?: boolean;
  className?: string;
  disabled?: boolean;
  children?: React.ReactElement;
  htmlType?: "button" | "submit" | "reset" | undefined;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  loading?: boolean;
}

export const ButtonCustom: FC<IButtonPrimaryProps> = ({
  id,
  size,
  text = "Button",
  type,
  ghost = false,
  danger = false,
  onClick,
  className = "",
  disabled = false,
  children,
  htmlType,
  onKeyDown,
  loading = false
}): JSX.Element => {

  const btnStyle = (btnType: string | undefined): string => {
    switch (btnType) {
      case "primary":
        return ghost ? css.buttonPrimaryGhost : css.buttonPrimary;
      case "default":
        return ghost ? css.buttonSecondaryGhost : css.buttonSecondary;
      default:
        return "";
    }
  };

  return (
    <Button
      id={id}
      className={`${disabled ? "" : btnStyle(type)} ${className}`}
      size={size}
      type={type}
      ghost={ghost}
      danger={danger}
      onClick={onClick}
      disabled={disabled}
      htmlType={htmlType}
      onKeyDown={onKeyDown}
      loading={loading}
    >
      {children}
      {text}
    </Button>
  );
};
