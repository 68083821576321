import React, { FC, JSX } from "react";
import { UserFullInfoType } from "app/types";
import { handlePhoneNumberChange } from "../../../../utils/phoneNumberMask";
import css from "./PerformerPersonalInformation.module.css";
import ListItem from "../../../ui-kit/RenderListItem/ListItem";

interface IPerformerPersonalInformationProps {
  personalInfo: UserFullInfoType | null;
}

const PerformerPersonalInformation: FC<IPerformerPersonalInformationProps> = ({
  personalInfo
}): JSX.Element => {

  return (
    <div className="mb-4">
      {/*TODO скрываем пока не будем получать с бэка данные по ролям*/}
      {/*<div className="p-5 mb-3 bg-white border-bottom-gray rounded-lg">*/}
      {/*  <h1 className={css.userLabel}>*/}
      {/*    Роли и права*/}
      {/*  </h1>*/}
      {/*</div>*/}
      <div className="p-5 mb-3 bg-white border-bottom-gray rounded-lg">
        <div className="flex justify-between items-center mb-5">
          <h1 className="header-text">
            Персональные данные
          </h1>
        </div>
        <ul>
          <ListItem value={personalInfo?.attributes?.last_name ?? "-"} label="Фамилия" />
          <ListItem value={personalInfo?.attributes?.first_name ?? "-"} label="Имя" />
          <ListItem value={personalInfo?.attributes?.second_name ?? "-"} label="Отчество" />
          <ListItem
            value={personalInfo?.attributes?.phone
              ? handlePhoneNumberChange(personalInfo?.attributes?.phone.toString() ?? "-")
              : "-"}
            label="Телефон"
          />
          <ListItem value={personalInfo?.email ?? "-"} label="Электронная почта" />
        </ul>
      </div>
    </div>
  );
};

export default PerformerPersonalInformation;
