import axios from "axios";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
  GET_USER_ROLE_LIST_SUCCESS,
  GET_USER_ROLE_LIST_FAILURE,
  GET_USER_ROLE_SUCCESS,
  GET_USER_ROLE_FAILURE,
  GET_USER_FULL_INFO_SUCCESS,
  GET_USER_FULL_INFO_FAILURE,
  UPDATE_USER_FULL_INFO_SUCCESS,
  UPDATE_USER_FULL_INFO_FAILURE,
  GET_USER_TOKEN_DECRYPTION_SUCCESS,
  GET_USER_TOKEN_DECRYPTION_FAILURE,
  GET_USERS_LISTING_SUCCESS,
  GET_USERS_LISTING_FAILURE,
  DECODE_TOKEN,
  GET_PERFORMERS_LISTING_SUCCESS,
  GET_PERFORMERS_LISTING_FAILURE,
  GET_CLIENTS_SERVED_SUCCESS,
  GET_CLIENTS_SERVED_FAILURE,
  GET_CLIENTS_SERVED_PAGE_SIZE,
  GET_CLIENTS_SERVED_PAGE_NUMBER,
} from "../constants/actionTypes";
import asyncLocalStorage from "../utils/asyncLocalStorage";
import * as AccountAPI from "../api/account.api";
import {
  RoleListType,
  UserInfoType,
  UserFullInfoType,
  UserInfoUpdateDataType,
  TokenDecryptionType,
  UserListingType,
  ProfileClientsListType,
  FilterProfileClientsType,
  UsersListingFilterType,
  AllUsersListingType,
} from "app/types";
import * as jose from "jose";

export type AccountActionsTypes =
  | LogoutType
  | LoginSuccessType
  | LoginFailureType
  | RefreshTokenSuccessType
  | RefreshTokenFailureType
  | FetchUserInfoSuccessType
  | FetchUserInfoFailureType
  | GetUserRoleListFailureType
  | GetUserRoleListSuccessType
  | GetUserRoleSuccessType
  | GetUserRoleFailureType
  | GetUserFullInfoSuccessType
  | GetUserFullInfoFailureType
  | UpdateUserFullInfoSuccessType
  | UpdateUserFullInfoFailureType
  | GetUserTokenDecryptionSuccessType
  | GetUserTokenDecryptionFailureType
  | GetUsersListingSuccessType
  | GetUsersListingFailureType
  | DecodeTokenType
  | GetPerformersListingSuccessType
  | GetPerformersListingFailureType
  | GetClientsServedListingSuccessType
  | GetClientsServedListingFailureType
  | GetClientsServedPageSizeType
  | GetClientsServedPageNumberType;

// Логика расшифровки токена авторизованного пользователя
type DecodeTokenType = {
  type: typeof DECODE_TOKEN;
  payload: {
    decodedToken: jose.JWTPayload;
  };
};

export const decodeToken = (token: any): DecodeTokenType => {
  const decodedToken: jose.JWTPayload = jose.decodeJwt(token);

  return {
    type: DECODE_TOKEN,
    payload: {
      decodedToken
    }
  };
};

// Логика деавторизации
type LogoutType = {
  type: typeof LOGOUT;
};

export const logout = (): LogoutType => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("lastNotification");
  localStorage.removeItem("plateClientDraft");

  return { type: LOGOUT };
};

// Логика авторизации
type LoginSuccessType = {
  type: typeof LOGIN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const loginSuccess = (access_token: string, refresh_token: string): LoginSuccessType => ({
  type: LOGIN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

type LoginFailureType = {
  type: typeof LOGIN_FAILURE;
  payload: {
    error: string;
  };
};

export const loginFailure = (error: string): LoginFailureType => ({
  type: LOGIN_FAILURE,
  payload: {
    error
  }
});

export const login = (
  username: string,
  password: string
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.login(username, password);

    asyncLocalStorage
    .setItem("authToken", JSON.stringify(response.data.access_token))
    .then((): void => {
      dispatch(loginSuccess(response.data.access_token, response.data.refresh_token));
      axios.defaults.headers.common.Authorization = response.data.access_token;
    });

    localStorage.setItem("refreshToken", JSON.stringify(response.data.refresh_token));
    localStorage.setItem("plateClientDraft", JSON.stringify(true));

  } catch (error: any) {
    dispatch(loginFailure(error.message));
  }
};

// Логика обновления токена через refresh_token
type RefreshTokenSuccessType = {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const refreshTokenSuccess = (access_token: string, refresh_token: string): RefreshTokenSuccessType => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

type RefreshTokenFailureType = {
  type: typeof REFRESH_TOKEN_FAILURE;
  payload: {
    error: string;
  };
};

export const refreshTokenFailure = (error: string): RefreshTokenFailureType => ({
  type: REFRESH_TOKEN_FAILURE,
  payload: {
    error
  }
});

export const fetchNewToken = (refreshToken: string | null):
  ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.updateToken(refreshToken);

    asyncLocalStorage
    .setItem("authToken", JSON.stringify(response.data.access_token))
    .then((): void => {
      dispatch(refreshTokenSuccess(response.data.access_token, response.data.refresh_token));
      axios.defaults.headers.common.Authorization = response.data.access_token;
    });

    localStorage.setItem("refreshToken", JSON.stringify(response.data.refresh_token));

  } catch (error: any) {
    dispatch(refreshTokenFailure(error.message));
    dispatch(logout());
  }
};

// Логика получения информации об авторизованном пользователе по токену
type FetchUserInfoSuccessType = {
  type: typeof FETCH_USER_INFO_SUCCESS;
  payload: {
    userInfo: UserInfoType;
  };
};

export const fetchUserInfoSuccess = (userInfo: UserInfoType): FetchUserInfoSuccessType => ({
  type: FETCH_USER_INFO_SUCCESS,
  payload: {
    userInfo
  }
});

type FetchUserInfoFailureType = {
  type: typeof FETCH_USER_INFO_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchUserInfoFailure = (error: string): FetchUserInfoFailureType => ({
  type: FETCH_USER_INFO_FAILURE,
  payload: {
    error
  }
});

export const fetchUserInfo = ():
    ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.fetchUserInfo();

    dispatch(fetchUserInfoSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchUserInfoFailure(error.message));
  }
};

// Логика получения подробной информации об авторизованном пользователе по uuid
type GetUserFullInfoSuccessType = {
  type: typeof GET_USER_FULL_INFO_SUCCESS;
  payload: {
    userFullInfo: UserFullInfoType;
  };
};

export const getUserFullInfoSuccess = (userFullInfo: UserFullInfoType): GetUserFullInfoSuccessType => ({
  type: GET_USER_FULL_INFO_SUCCESS,
  payload: {
    userFullInfo
  }
});

type GetUserFullInfoFailureType = {
  type: typeof GET_USER_FULL_INFO_FAILURE;
  payload: {
    error: string;
  };
};

export const getUserFullInfoFailure = (error: string): GetUserFullInfoFailureType => ({
  type: GET_USER_FULL_INFO_FAILURE,
  payload: {
    error
  }
});

export const getUserFullData = (userId?: string):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getUserFullInfo(userId);

    dispatch(getUserFullInfoSuccess(response.data));
  } catch (error: any) {
    dispatch(getUserFullInfoFailure(error.message));
  }
};

// Логика получения информации о доступных ролях пользователей
type GetUserRoleListSuccessType = {
  type: typeof GET_USER_ROLE_LIST_SUCCESS;
  payload: {
    userRoleList: RoleListType[];
  };
};

export const getUserRoleListSuccess = (userRoleList: RoleListType[]): GetUserRoleListSuccessType => ({
  type: GET_USER_ROLE_LIST_SUCCESS,
  payload: {
    userRoleList
  }
});

type GetUserRoleListFailureType = {
  type: typeof GET_USER_ROLE_LIST_FAILURE;
  payload: {
    error: string;
  };
};

export const getUserRoleListFailure = (error: string): GetUserRoleListFailureType => ({
  type: GET_USER_ROLE_LIST_FAILURE,
  payload: {
    error
  }
});

export const getUserRoleListData = ():
    ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getUserRoleList();

    dispatch(getUserRoleListSuccess(response.data));
  } catch (error: any) {
    dispatch(getUserRoleListFailure(error.message));
  }
};

// Логика получения информации о конкретной роли пользователя
type GetUserRoleSuccessType = {
  type: typeof GET_USER_ROLE_SUCCESS;
  payload: {
    userRoleInfo: UserListingType[];
  };
};

export const getUserRoleSuccess = (userRoleInfo: UserListingType[]): GetUserRoleSuccessType => ({
  type: GET_USER_ROLE_SUCCESS,
  payload: {
    userRoleInfo
  }
});

type GetUserRoleFailureType = {
  type: typeof GET_USER_ROLE_FAILURE;
  payload: {
    error: string;
  };
};

export const getUserRoleFailure = (error: string): GetUserRoleFailureType => ({
  type: GET_USER_ROLE_FAILURE,
  payload: {
    error
  }
});

export const getUserRoleInfo = (roleName: string | null,): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getUserRole(roleName);

    dispatch(getUserRoleSuccess(response.data));
  } catch (error: any) {
    dispatch(getUserRoleFailure(error.message));
  }
};

// Логика обновления информации об авторизованном пользователе
type UpdateUserFullInfoSuccessType = {
  type: typeof UPDATE_USER_FULL_INFO_SUCCESS;
  payload: {
    userUpdatedInfo: UserFullInfoType;
  };
};

export const updateUserFullInfoSuccess = (userUpdatedInfo: UserFullInfoType): UpdateUserFullInfoSuccessType => ({
  type: UPDATE_USER_FULL_INFO_SUCCESS,
  payload: {
    userUpdatedInfo
  }
});

type UpdateUserFullInfoFailureType = {
  type: typeof UPDATE_USER_FULL_INFO_FAILURE;
  payload: {
    error: string;
  };
};

export const updateUserFullInfoFailure = (error: string): UpdateUserFullInfoFailureType => ({
  type: UPDATE_USER_FULL_INFO_FAILURE,
  payload: {
    error
  }
});

export const updateUserData = (userId?: string, updatedUserData?: UserInfoUpdateDataType):
    ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.updateUserAttrInfo(userId, {attributes: updatedUserData});

    dispatch(updateUserFullInfoSuccess(response.data));
  } catch (error: any) {
    dispatch(updateUserFullInfoFailure(error.message));
  }
};

// Логика получения ключа шифрования токена
type GetUserTokenDecryptionSuccessType = {
  type: typeof GET_USER_TOKEN_DECRYPTION_SUCCESS;
  payload: {
    userTokenDecryption: TokenDecryptionType;
  };
};

export const getUserTokenDecryptionSuccess = (userTokenDecryption: TokenDecryptionType)
  : GetUserTokenDecryptionSuccessType => ({
  type: GET_USER_TOKEN_DECRYPTION_SUCCESS,
  payload: {
    userTokenDecryption
  }
});

type GetUserTokenDecryptionFailureType = {
  type: typeof GET_USER_TOKEN_DECRYPTION_FAILURE;
  payload: {
    error: string;
  };
};

export const getUserTokenDecryptionFailure = (error: string): GetUserTokenDecryptionFailureType => ({
  type: GET_USER_TOKEN_DECRYPTION_FAILURE,
  payload: {
    error
  }
});

export const getUserTokenDecryptionData = ():
    ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getUserTokenDecryption();

    dispatch(getUserTokenDecryptionSuccess(response.data));
  } catch (error: any) {
    dispatch(getUserTokenDecryptionFailure(error.message));
  }
};

//логика получения списка пользователей
type GetUsersListingSuccessType = {
  type: typeof GET_USERS_LISTING_SUCCESS;
  payload: {
    usersListing: AllUsersListingType;
  };
};

export const getUsersListingSuccess = (
  usersListing: AllUsersListingType,
): GetUsersListingSuccessType => ({
  type: GET_USERS_LISTING_SUCCESS,
  payload: {
    usersListing,
  }
});

type GetUsersListingFailureType = {
  type: typeof GET_USERS_LISTING_FAILURE;
  payload: {
    error: string;
  };
};

export const getUsersListingFailure = (error: string): GetUsersListingFailureType => ({
  type: GET_USERS_LISTING_FAILURE,
  payload: {
    error
  }
});

export const getUsersListingData = (params?: UsersListingFilterType | null):ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
> => async (dispatch) => {
  try {
    const usersList = await AccountAPI.getUsersListing(params);

    dispatch(getUsersListingSuccess(usersList.data));
  } catch (error: any) {
    dispatch(getUsersListingFailure(error.message));
  }
};

//Логика получения списка исполнителей
type GetPerformersListingSuccessType = {
  type: typeof GET_PERFORMERS_LISTING_SUCCESS;
  payload: {
    performersListing: UserListingType[];
  };
};

export const getPerformersListingSuccess = (performersListing: UserListingType[])
  : GetPerformersListingSuccessType => ({
  type: GET_PERFORMERS_LISTING_SUCCESS,
  payload: {
    performersListing
  }
});

type GetPerformersListingFailureType = {
  type: typeof GET_PERFORMERS_LISTING_FAILURE;
  payload: {
    error: string;
  };
};

export const getPerformersListingFailure = (error: string): GetPerformersListingFailureType => ({
  type: GET_PERFORMERS_LISTING_FAILURE,
  payload: {
    error
  }
});

export const getPerformersListingData = ():
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.getPerformersListing();

    dispatch(getPerformersListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getPerformersListingFailure(error.message));
  }
};

//Логика получения листинга обслуживаемых клиентов
type GetClientsServedListingSuccessType = {
  type: typeof GET_CLIENTS_SERVED_SUCCESS;
  payload: {
    clientsServedListing: ProfileClientsListType;
  };
};

export const getClientsServedListingSuccess = (clientsServedListing: ProfileClientsListType)
  : GetClientsServedListingSuccessType => ({
  type: GET_CLIENTS_SERVED_SUCCESS,
  payload: {
    clientsServedListing
  }
});

type GetClientsServedListingFailureType = {
  type: typeof GET_CLIENTS_SERVED_FAILURE;
  payload: {
    error: string;
  };
};

export const getClientsServedListingFailure = (error: string): GetClientsServedListingFailureType => ({
  type: GET_CLIENTS_SERVED_FAILURE,
  payload: {
    error
  }
});

//+пагинация листинга обслуживаемых клиентов
export type GetClientsServedPageSizeType = {
  type: typeof GET_CLIENTS_SERVED_PAGE_SIZE;
  payload: {
    pageSize: number;
  };
};

export const getClientsServedPageSize = (pageSize: number): GetClientsServedPageSizeType => ({
  type: GET_CLIENTS_SERVED_PAGE_SIZE,
  payload: {
    pageSize
  }
});

export type GetClientsServedPageNumberType = {
  type: typeof GET_CLIENTS_SERVED_PAGE_NUMBER,
  payload: {
    pageNumber: number;
  };
};

export const getClientsServedPageNumber = (pageNumber: number): GetClientsServedPageNumberType => ({
  type: GET_CLIENTS_SERVED_PAGE_NUMBER,
  payload: {
    pageNumber
  }
});

export const getClientsServedData = (
  uuid: string,
  params: null | FilterProfileClientsType = null
):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response =
      await AccountAPI.getClientsServedListing(uuid, params);

    dispatch(getClientsServedListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getClientsServedListingFailure(error.message));
  }
};
