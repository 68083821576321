import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";
import {
  GET_SCHEMA_CLIENT_SUCCESS,
  GET_SCHEMA_CLIENT_FAILURE,
  GET_DOCUMENT_CLIENT_SUCCESS,
  GET_DOCUMENT_CLIENT_FAILURE,
  GET_CLIENT_LISTING_SUCCESS,
  GET_CLIENT_LISTING_FAILURE,
  GET_CLIENTS_PAGE_SIZE,
  GET_CLIENTS_PAGE_NUMBER,
  GET_CLIENT_TARIFF_SUCCESS,
  GET_CLIENT_TARIFF_FAILURE,
  GET_CLIENT_BANKS_DATA_SUCCESS,
  GET_CLIENT_BANKS_DATA_FAILURE,
  SEND_CLIENT_REQUEST_SUCCESS,
  SEND_CLIENT_REQUEST_FAILURE,
  GET_SCHEMA_LABOUR_REGULATION_SUCCESS,
  GET_SCHEMA_LABOUR_REGULATION_FAILURE,
  CREAT_CLIENT_LABOUR_REGULATION_SUCCESS,
  CREAT_CLIENT_LABOUR_REGULATION_FAILURE,
  CLIENT_LISTING_FILTER_DATA,
  CLIENT_LISTING_PARAMS_DATA,
  CLIENT_LISTING_LOADING_DEFAULT,
  CLIENT_CARD_TAG_KEY,
  CLIENT_BANKS_DATA_PAGE_NUMBER,
  CLIENT_BANKS_DATA_PAGE_SIZE,
  CLIENT_BANKS_DATA_SORTER,
  CLIENT_BANKS_DATA_SEARCH,
  GET_CLIENT_COMMENTS_DATA_SUCCESS,
  GET_CLIENT_COMMENTS_DATA_FAILURE,
  SET_POPOVER_TARIFF_SWITCH
} from "../constants/actionTypes";
import * as ClientAPI from "../api/client.api";
import {
  ClientTariffDataType,
  ClientsListingType,
  DocumentClientType,
  FilterClientsType,
  SchemaType,
  ClientBanksDataType,
  TypicalRequestToClientResponseType,
  TypicalRequestToClientType,
  SchemaLabourRegulationType,
  CreateLabourRegulationType,
  ClientLabourRegulationType,
  DocumentClientFieldsUpdateType,
  ClientBanksDataParamsType,
  CommentType,
  getCommentsParamsType,
} from "app/types";
import { sendRequestToTariffIncrease, sendRequestToTerminateService } from "../api/client.api";

export type ClientActionsTypes =
  | GetSchemaClientSuccessType
  | GetSchemaClientFailureType
  | GetClientsListingSuccessType
  | GetClientsListingFailureType
  | GetClientsPageSizeType
  | GetClientsPageNumberType
  | GetDocumentClientSuccessType
  | GetDocumentClientFailureType
  | GetClientTariffSuccessType
  | GetClientTariffFailureType
  | GetClientBanksDataSuccessType
  | GetClientBanksDataFailureType
  | SendRequestToClientSuccessType
  | SendRequestToClientFailureType
  | GetSchemaLabourRegulationSuccessType
  | GetSchemaLabourRegulationFailureType
  | CreateClientLabourRegulationSuccessType
  | CreateClientLabourRegulationFailureType
  | SetClientsListingFilterData
  | SetClientsListingParamsData
  | SetClientsListingLoadingDefault
  | SetClientCardTagKey
  | GetClientBanksPageSizeType
  | GetClientBanksPageNumberType
  | GetClientBanksSorterType
  | GetClientBanksSearchType
  | GetCommentsSuccessType
  | GetCommentsFailureType
  | SetPopoverTariffType;

// Логика получения схемы клиента
export type GetSchemaClientSuccessType = {
  type: typeof GET_SCHEMA_CLIENT_SUCCESS;
  payload: {
    schemaProfile: SchemaType;
  };
};

const getSchemaClientSuccess = (schemaProfile: SchemaType): GetSchemaClientSuccessType => ({
  type: GET_SCHEMA_CLIENT_SUCCESS,
  payload: {
    schemaProfile
  }
});

export type GetSchemaClientFailureType = {
  type: typeof GET_SCHEMA_CLIENT_FAILURE;
  payload: {
    error: any;
  };
};

export const getSchemaClientFailure = (error?: any): GetSchemaClientFailureType => ({
  type: GET_SCHEMA_CLIENT_FAILURE,
  payload: {
    error
  }
});

export const fetchSchemaClient = ():
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
      ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getSchemaClient();

    dispatch(getSchemaClientSuccess(response.data));
  } catch (error: any) {
    dispatch(getSchemaClientFailure(error.message));
  }
};

//Логика получения листинга клиентов
export type GetClientsListingSuccessType = {
  type: typeof GET_CLIENT_LISTING_SUCCESS;
  payload: {
    clientsListing: ClientsListingType;
  };
};

const getClientListingSuccess = (clientsListing: ClientsListingType): GetClientsListingSuccessType => ({
  type: GET_CLIENT_LISTING_SUCCESS,
  payload: {
    clientsListing
  }
});

export type GetClientsListingFailureType = {
  type: typeof GET_CLIENT_LISTING_FAILURE;
  payload: {
    error: any;
  };
};

const getClientsListingFailure = (error: any): GetClientsListingFailureType => ({
  type: GET_CLIENT_LISTING_FAILURE,
  payload: {
    error
  }
});

//+пагинация листинга клиента
export type GetClientsPageSizeType = {
  type: typeof GET_CLIENTS_PAGE_SIZE;
  payload: {
    size: number;
  };
};

export const getClientsPageSize = (size: number): GetClientsPageSizeType => ({
  type: GET_CLIENTS_PAGE_SIZE,
  payload: {
    size
  }
});

export type GetClientsPageNumberType = {
  type: typeof GET_CLIENTS_PAGE_NUMBER,
  payload: {
    page: number;
  };
};

export const getClientsPageNumber = (page: number): GetClientsPageNumberType => ({
  type: GET_CLIENTS_PAGE_NUMBER,
  payload: {
    page
  }
});

export const getClientsData = (params: null | FilterClientsType = null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getListingClients(params);

    dispatch(getClientListingSuccess(response.data));
  } catch (error: any) {
    dispatch(getClientsListingFailure(error.message));
  }
};

//Логика фильтрации листинга клиентов
export type SetClientsListingFilterData = {
  type: typeof CLIENT_LISTING_FILTER_DATA;
  payload: {
    clientsFilter: FilterClientsType | null;
  };
};

export const setClientsListingFilter = (clientsFilter: FilterClientsType | null): SetClientsListingFilterData => ({
  type: CLIENT_LISTING_FILTER_DATA,
  payload: {
    clientsFilter
  }
});

export type SetClientsListingParamsData = {
  type: typeof CLIENT_LISTING_PARAMS_DATA;
  payload: {
    clientsParams: FilterClientsType | null;
  };
};

export const setClientsListingParams = (clientsParams: FilterClientsType | null): SetClientsListingParamsData => ({
  type: CLIENT_LISTING_PARAMS_DATA,
  payload: {
    clientsParams
  }
});

export type SetClientsListingLoadingDefault = {
  type: typeof CLIENT_LISTING_LOADING_DEFAULT;
  payload: {
    loadingDefault: boolean;
  };
};

export const setClientsListingLoadingDefault = (loadingDefault: boolean): SetClientsListingLoadingDefault => ({
  type: CLIENT_LISTING_LOADING_DEFAULT,
  payload: {
    loadingDefault
  }
});

export type SetClientCardTagKey = {
  type: typeof CLIENT_CARD_TAG_KEY;
  payload: {
    clientTagKey: string;
  };
};

export const setClientCardTagKey = (clientTagKey: string): SetClientCardTagKey => ({
  type: CLIENT_CARD_TAG_KEY,
  payload: {
    clientTagKey
  }
});

// Логика получения данных схемы клиента по uuid документа
export type GetDocumentClientSuccessType = {
  type: typeof GET_DOCUMENT_CLIENT_SUCCESS;
  payload: {
    documentClient: DocumentClientType;
  };
};

const getDocumentClientSuccess = (documentClient: DocumentClientType)
  : GetDocumentClientSuccessType => ({
  type: GET_DOCUMENT_CLIENT_SUCCESS,
  payload: {
    documentClient
  }
});

export type GetDocumentClientFailureType = {
  type: typeof GET_DOCUMENT_CLIENT_FAILURE;
  payload: {
    error: any;
  };
};

export const getDocumentClientFailure = (error?: any): GetDocumentClientFailureType => ({
  type: GET_DOCUMENT_CLIENT_FAILURE,
  payload: {
    error
  }
});

export const fetchDocumentClient = (uuid: string):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getDocumentClient(uuid);

    dispatch(getDocumentClientSuccess(response.data));
  } catch (error: any) {
    dispatch(getDocumentClientFailure(error.message));
  }
};

//Логика обновления сущности клиента
export const updateClientData = (uuid: string, body: DocumentClientFieldsUpdateType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.updateDocumentClient(uuid, body);

    dispatch(getDocumentClientSuccess(response.data));
  } catch (error: any) {
    dispatch(getDocumentClientFailure(error.message));
  }
};

// Логика получения данных тарифа клиента по uuid
export type GetClientTariffSuccessType = {
  type: typeof GET_CLIENT_TARIFF_SUCCESS;
  payload: {
    clientTariffData: ClientTariffDataType;
  };
};

const getClientTariffSuccess = (clientTariffData: ClientTariffDataType): GetClientTariffSuccessType => ({
  type: GET_CLIENT_TARIFF_SUCCESS,
  payload: {
    clientTariffData
  }
});

export type GetClientTariffFailureType = {
  type: typeof GET_CLIENT_TARIFF_FAILURE;
  payload: {
    error: any;
  };
};

export const getClientTariffFailure = (error?: any): GetClientTariffFailureType => ({
  type: GET_CLIENT_TARIFF_FAILURE,
  payload: {
    error
  }
});

export const getClientTariffInfo = (tariffUuid: string):
    ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getClientTariff(tariffUuid);

    dispatch(getClientTariffSuccess(response.data));
  } catch (error: any) {
    dispatch(getClientTariffFailure(error.message));
  }
};

//Логика получения информации о банках клиента
export type GetClientBanksDataSuccessType = {
  type: typeof GET_CLIENT_BANKS_DATA_SUCCESS;
  payload: {
    clientBanksData: ClientBanksDataType;
  };
};

const getClientBankDataSuccess = (clientBanksData: ClientBanksDataType): GetClientBanksDataSuccessType => ({
  type: GET_CLIENT_BANKS_DATA_SUCCESS,
  payload: {
    clientBanksData
  }
});

export type GetClientBanksDataFailureType = {
  type: typeof GET_CLIENT_BANKS_DATA_FAILURE;
  payload: {
    error: any;
  };
};

export const getClientBanksDataFailure = (error?: any): GetClientBanksDataFailureType => ({
  type: GET_CLIENT_BANKS_DATA_FAILURE,
  payload: {
    error
  }
});

//+пагинация листинга задач
export type GetClientBanksPageSizeType = {
  type: typeof CLIENT_BANKS_DATA_PAGE_SIZE;
  payload: {
    size: number;
  };
};

export const getClientBanksPageSize = (size: number): GetClientBanksPageSizeType => ({
  type: CLIENT_BANKS_DATA_PAGE_SIZE,
  payload: {
    size
  }
});

export type GetClientBanksPageNumberType = {
  type: typeof CLIENT_BANKS_DATA_PAGE_NUMBER,
  payload: {
    page: number;
  };
};

export const getClientBanksPageNumber = (page: number): GetClientBanksPageNumberType => ({
  type: CLIENT_BANKS_DATA_PAGE_NUMBER,
  payload: {
    page
  }
});

//+сортировка и поиск
export type GetClientBanksSorterType = {
  type: typeof CLIENT_BANKS_DATA_SORTER,
  payload: {
    bankSorter: string;
  };
};

export const getClientBanksSorter = (bankSorter: string): GetClientBanksSorterType => ({
  type: CLIENT_BANKS_DATA_SORTER,
  payload: {
    bankSorter
  }
});

export type GetClientBanksSearchType = {
  type: typeof CLIENT_BANKS_DATA_SEARCH,
  payload: {
    bankSearch: string;
  };
};

export const getClientBanksSearch= (bankSearch: string): GetClientBanksSearchType => ({
  type: CLIENT_BANKS_DATA_SEARCH,
  payload: {
    bankSearch
  }
});

export const getClientBanksDataInfo = (uuid?: string, params: ClientBanksDataParamsType | null = null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getClientBanksData(uuid, params);

    dispatch(getClientBankDataSuccess(response.data));
  } catch (error: any) {
    dispatch(getClientBanksDataFailure(error.message));
  }
};

//Логика отправки данных Обращения к клиенту
export type SendRequestToClientSuccessType = {
  type: typeof SEND_CLIENT_REQUEST_SUCCESS;
  payload: {
    requestToClient: TypicalRequestToClientResponseType;
  };
};

const sendRequestToClientSuccess = (
  requestToClient: TypicalRequestToClientResponseType
): SendRequestToClientSuccessType => ({
  type: SEND_CLIENT_REQUEST_SUCCESS,
  payload: {
    requestToClient
  }
});

export type SendRequestToClientFailureType = {
  type: typeof SEND_CLIENT_REQUEST_FAILURE;
  payload: {
    error: any;
  };
};

const sendRequestToClientFailure = (error: any): SendRequestToClientFailureType => ({
  type: SEND_CLIENT_REQUEST_FAILURE,
  payload: {
    error
  }
});

export const sendTypicalRequestToClient = (body?: TypicalRequestToClientType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.sendRequestToClient(body);
    dispatch(sendRequestToClientSuccess(response.data));
  } catch (error: any) {
    dispatch(sendRequestToClientFailure(error.message));
  }
};

export const sendPaidConsultationsRequestToClient = (body?: TypicalRequestToClientType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.sendRequestToClientPaidConsultations(body);
    dispatch(sendRequestToClientSuccess(response.data));
  } catch (error: any) {
    dispatch(sendRequestToClientFailure(error.message));
  }
};

export const sendTerminateServiceRequestToClient = (body?: TypicalRequestToClientType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.sendRequestToTerminateService(body);
    dispatch(sendRequestToClientSuccess(response.data));
  } catch (error: any) {
    dispatch(sendRequestToClientFailure(error.message));
  }
};

export const sendTariffIncreaseRequestToClient = (body?: TypicalRequestToClientType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.sendRequestToTariffIncrease(body);
    dispatch(sendRequestToClientSuccess(response.data));
  } catch (error: any) {
    dispatch(sendRequestToClientFailure(error.message));
  }
};

//Логика получения схемы "Положения об оплате труда"
export type GetSchemaLabourRegulationSuccessType = {
  type: typeof GET_SCHEMA_LABOUR_REGULATION_SUCCESS;
  payload: {
    schemaLabour: SchemaLabourRegulationType;
  };
};

const getSchemaLabourRegulationSuccess = (schemaLabour: SchemaLabourRegulationType
): GetSchemaLabourRegulationSuccessType => ({
  type: GET_SCHEMA_LABOUR_REGULATION_SUCCESS,
  payload: {
    schemaLabour
  }
});

export type GetSchemaLabourRegulationFailureType = {
  type: typeof GET_SCHEMA_LABOUR_REGULATION_FAILURE;
  payload: {
    error: any;
  };
};

const getSchemaLabourRegulationFailure = (error: any): GetSchemaLabourRegulationFailureType => ({
  type: GET_SCHEMA_LABOUR_REGULATION_FAILURE,
  payload: {
    error
  }
});

export const fetchSchemaClientLabourRegulation = ():
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getLabourRegulationSchema();

    dispatch(getSchemaLabourRegulationSuccess(response.data));
  } catch (error: any) {
    dispatch(getSchemaLabourRegulationFailure(error.message));
  }
};

//Логика создания, получения и обновления "Положения об оплате труда"
export type CreateClientLabourRegulationSuccessType = {
  type: typeof CREAT_CLIENT_LABOUR_REGULATION_SUCCESS;
  payload: {
    clientLabourRegulation: ClientLabourRegulationType;
  };
};

const createClientLabourRegulationSuccess = (clientLabourRegulation: ClientLabourRegulationType
): CreateClientLabourRegulationSuccessType => ({
  type: CREAT_CLIENT_LABOUR_REGULATION_SUCCESS,
  payload: {
    clientLabourRegulation
  }
});

export type CreateClientLabourRegulationFailureType = {
  type: typeof CREAT_CLIENT_LABOUR_REGULATION_FAILURE;
  payload: {
    error: any;
  };
};

export const createClientLabourRegulationFailure = (error?: any): CreateClientLabourRegulationFailureType => ({
  type: CREAT_CLIENT_LABOUR_REGULATION_FAILURE,
  payload: {
    error
  }
});

export const createClientLabourRegulationData = (body?: CreateLabourRegulationType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.creatClientLabourRegulation(body);

    dispatch(createClientLabourRegulationSuccess(response.data));
  } catch (error: any) {
    dispatch(createClientLabourRegulationFailure(error.message));
  }
};

export const getClientLabourRegulationData = (uuid: string):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getClientLabourRegulation(uuid);

    dispatch(createClientLabourRegulationSuccess(response.data));
  } catch (error: any) {
    dispatch(createClientLabourRegulationFailure(error.message));
  }
};

export const updateClientLabourRegulationData = (uuid: string, body?: CreateLabourRegulationType | null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.updateClientLabourRegulation(uuid, body);

    dispatch(createClientLabourRegulationSuccess(response.data));
  } catch (error: any) {
    dispatch(createClientLabourRegulationFailure(error.message));
  }
};

//логика получения комментариев
export type GetCommentsSuccessType = {
  type: typeof GET_CLIENT_COMMENTS_DATA_SUCCESS;
  payload: {
    clientsComments: CommentType;
  };
};

const getCommentsDataSuccess = (clientsComments: CommentType
): GetCommentsSuccessType => ({
  type: GET_CLIENT_COMMENTS_DATA_SUCCESS,
  payload: {
    clientsComments
  }
});

export type GetCommentsFailureType = {
  type: typeof GET_CLIENT_COMMENTS_DATA_FAILURE;
  payload: {
    error: any;
  };
};

export const getCommentsDataFailure = (error?: any): GetCommentsFailureType => ({
  type: GET_CLIENT_COMMENTS_DATA_FAILURE,
  payload: {
    error
  }
});

export const getClientCommentsData = (params: null | getCommentsParamsType = null):
  ThunkAction<
    Promise<any>,
    AppStateType,
    undefined,
    ClientActionsTypes
  > => async (dispatch) => {
  try {
    const response = await ClientAPI.getComments(params);

    dispatch(getCommentsDataSuccess(response.data));
  } catch (error: any) {
    dispatch(getCommentsDataFailure(error.message));
  }
};

//логика получения комментариев
export type SetPopoverTariffType = {
  type: typeof SET_POPOVER_TARIFF_SWITCH;
  payload: {
    showClientTariff: boolean;
  };
};

export const setPopoverTariffType = (showClientTariff: boolean): SetPopoverTariffType => ({
  type: SET_POPOVER_TARIFF_SWITCH,
  payload: {
    showClientTariff
  }
});
