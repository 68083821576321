import React, { FC, JSX } from "react";
import css from "../TasksListing.module.css";
import { FilterNamesType, FilterTasksType, ResponsibleFilterType } from "app/types";
import { Tag } from "antd";

interface ITasksListingTagsProps {
  filterData: FilterTasksType | null;
  deleteResponsibleFilterData: (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    id: string,
    value: ResponsibleFilterType[]
  ) => void;
  deleteFilterData: (e: React.MouseEvent<HTMLElement>, key: string) => void;
  FilterNames: FilterNamesType;
  clearFilterData: (e: React.MouseEvent<HTMLElement>) => void;
}

const TasksListingTags: FC<ITasksListingTagsProps> = ({
  filterData,
  deleteResponsibleFilterData,
  deleteFilterData,
  FilterNames,
  clearFilterData
}): JSX.Element => {
  const TagRender = ({ keyData, value, id = keyData, name = "", responsible = false }: {
    keyData: string;
    value: string | number | string[] | ResponsibleFilterType[] | null;
    id?: string;
    name?: string;
    responsible?: boolean;
  }): JSX.Element => {
    const valueData: string = responsible ? name as string : value as string;
    const expired: boolean = keyData === "is_expired";

    return (
      <Tag
        key={id}
        className={`${css.commonTagStyle} ${css.alignedTag}`}
        closable
        onClose={(e: React.MouseEvent<HTMLElement>): void =>
          responsible
            ? deleteResponsibleFilterData(e, keyData, id, value as ResponsibleFilterType[])
            : deleteFilterData(e, keyData)
        }
      >
        <div className={css.tagBlock}>
          <div className={expired ? css.tagValue : css.tagKey}>{FilterNames[keyData]}</div>
          <div className={`${css.tagValue} ml-1`}>{valueData}</div>
        </div>
      </Tag>
    );
  };

  return (
    <div className={css.tag}>
      {Object.entries(filterData ?? {}).map(
        ([key, value]: [string, string | number | string[] | ResponsibleFilterType[] | null]) => {
          if (!value) {
            return null;
          }

          if (key === "responsible_reference.uuid" && Array.isArray(value)) {
            return (value as ResponsibleFilterType[]).map((responsible: ResponsibleFilterType) => (
              <TagRender
                key={responsible.id}
                keyData={key}
                value={value}
                id={responsible.id}
                name={responsible.name}
                responsible
              />
            ));
          }

          return (
            <TagRender key={key} keyData={key} value={value} />
          );
        })
      }
      <Tag className={`${css.commonTagStyle} ${css.tagButton}`} onClick={clearFilterData}>
        Сбросить фильтры
      </Tag>
    </div>
  );
};

export default TasksListingTags;