import React, {
  FC,
  JSX,
  useState,
  useEffect,
  ChangeEvent,
  Dispatch,
  SetStateAction
} from "react";
import {
  taskActions,
  taskActionsType,
  ITaskActionsProps,
} from "../taskActions";
import css from "./ModelContent.module.css";
import {
  Form,
  Input,
  Modal,
  Radio,
  Space,
  Select,
  message,
  Skeleton,
  DatePicker,
  UploadFile,
  UploadProps,
  notification,
  RadioChangeEvent,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../../assets/icons/delete_file.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search_icon.svg";
import { ReactComponent as CloseImplementer } from "../../../../assets/icons/close_blue.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/close_red.svg";
import { ReactComponent as SuccessIcon } from "../../../../assets/icons/check_circle_icon_blue.svg";
import {
  ActionHistoryType,
  DocumentTaskType,
  ParamsGetFile1CType,
  ParamsGetUuid1CType,
  TypicalTaskDecisionType,
  UploadedFilesPropsType,
  UserListingType,
  UsersListingFilterType
} from "app/types";
import { fetchUserInfo, getUsersListingData } from "../../../../actions/account.actions";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { decodeToken } from "../../../../actions/account.actions";
import { AppDispatch } from "../../../../store/store";
import { UploadChangeParam } from "antd/es/upload";
import { deleteUploadFile, uploadFiles } from "../../../../api/document.api";
import {
  getOperationMarkupFiles,
  getOperationMarkupFilesFailure,
  getProcessTaskAutomaticallyFile,
  getTaskFile1CFailure,
  getTaskFileC,
  getTaskUuid1C,
  getTaskUuid1CFailure,
  sendTypicalTaskDecision,
  sendTypicalTaskDecisionFailure
} from "../../../../actions/tasks.actions";
import * as jose from "jose";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import { downloadFile, downloadUrl } from "../../../../utils/downloadFile";
import ZeroSearch from "../../../ClientsList/ZeroSearch/ZeroSearch";
import { roleResolver } from "../../../../utils/roleResolver";
import { validFileFormat } from "../../../../utils/ModalUploadItems/validFileFormat";
import { notificationProcessing } from "../../../../utils/Notifications/TaskCardNotifications/TaskCardNotifications";
import DraggerForm from "../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import KnapRecognize from "./ModalContentItem/RecognizeKNAP/KnapRecognize";
import TopicOfAppeal from "./ModalContentItem/SelectTopicOfAppeal/TopicOfAppeal";
import ReassignTask from "./ModalContentItem/ReassignTask/ReassignTask";

interface IModelContentProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  documentTask: DocumentTaskType | null;
  setUpdateHistory: Dispatch<SetStateAction<boolean>>;
  actionHistory: ActionHistoryType | null;
  currentTaskType?: string;
  setClarificationStatus: Dispatch<SetStateAction<boolean>>;
  setCheckClarification: Dispatch<SetStateAction<boolean>>;
}

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

enum TaskValue {
  TaskType203 = 203,
  TaskType204 = 204,
  TaskType303 = 303,
  TaskType304 = 304,
  TaskType305 = 305,
  TaskType306 = 306,
  TaskType307 = 307,
  TaskType308 = 308,
  TaskType309 = 309,
  TaskType310 = 310,
  TaskType312 = 312,
  TaskType403 = 403,
}

enum TaskCode {
  writeOff= 403,
  salaryCalculation= 204,
  advanceCalculation = 203,
}

const ModelContent: FC<IModelContentProps> = ({
  isModalOpen,
  setIsModalOpen,
  documentTask,
  setUpdateHistory,
  actionHistory,
  currentTaskType,
  setClarificationStatus,
  setCheckClarification
}): JSX.Element => {
  const {
    decisionTypicalTask,
    decisionTypeStatus,
    error,
    markupTaskFileFrom1C,
    markupTaskStatusForm1C,
    parsingResultStatus,
    file1CDownloadStatus, //статус ответов из 1С
    taskFile1CUuid, // uuid файла из 1С
    taskFile1CData, //загрузка файла из 1с
  } = useSelector((state: AppStateType) => state.tasks);

  const [searchEmployeeValue, setSearchEmployeeValue] = useState<string>("");

  const [fileCountClarification, setFileCountClarification] = useState<number>(0);
  const [fileCountAutomatically, setFileCountAutomatically] = useState<number>(0);
  const [fileCountManual, setFileCountManual] = useState<number>(0);

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const [selectedResponsible, setSelectedResponsible] = useState<UserListingType | null>(null);
  const [selectedResponsibleRule, setSelectedResponsibleRule] = useState<string | null>(null);

  const [selectedAction, setSelectedAction] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string | null>(null);

  const [commentClarification, setCommentClarification] = useState<string>("");
  const [remainingCharsClarification, setRemainingCharsClarification] = useState<number>(0);

  const [commentManual, setCommentManual] = useState<string>("");
  const [remainingCharsManual, setRemainingCharsManual] = useState<number>(0);

  const [uploadedFilesClarification, setUploadedFilesClarification] = useState<UploadedFilesPropsType[] | null>(null);
  const [uploadedFilesManual, setUploadedFilesManual] = useState<UploadedFilesPropsType[] | null>(null);

  const [uploadFilesLoaderClarification, setUploadFilesLoaderClarification] = useState<boolean>(false);
  const [uploadFilesLoaderManual, setUploadFilesLoaderManual] = useState<boolean>(false);

  const [uploadedFilesKNAP, setUploadedFilesKNAP] = useState<UploadedFilesPropsType[]>([]);
  const [decisionTask, setDecisionTask] = useState<TypicalTaskDecisionType | null>(null);
  const [checkUploadFiles, setCheckUploadFiles] = useState<boolean>(false);

  const { TextArea } = Input;
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { topicAppeal, reassignText } = values || {};
  
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const dispatch = useDispatch<AppDispatch>();

  const {authToken, userData} = useSelector((state: AppStateType) => state.account);

  //логика "обработать задачу автоматически"
  const [getFile, setGetFile] = useState<boolean>(true);
  const [errorDownload, setErrorDownload] = useState<boolean>(false);
  const [upload, setUpload] = useState<boolean>(false);
  const [uploadedFilesAutomatically, setUploadedFilesAutomatically] = useState<UploadedFilesPropsType[] | null>(null);
  const [uploadFilesLoaderAutomatically, setUploadFilesLoaderAutomatically] = useState<boolean>(false);
  const [isBlockButton, setBlockButton] = useState<boolean>(false);

  useEffect(() => {
    if (isModalOpen) {
      const params: UsersListingFilterType = {
        name: searchEmployeeValue?.length ? searchEmployeeValue : null,
        page_size: 100,
      };

      dispatch(getUsersListingData(params));
    }
  }, [isModalOpen, searchEmployeeValue]);

  const isMarkupTask: boolean = currentTaskType === "Разметка операций";
  const isAutomaticProcessing: boolean = selectedAction === "Обработать задачу автоматически";

  //Данные возможно поменяют, но пока так
  const taskType = (text: string): number | null => {
    switch (text) {
      case ("Разметка операций"):
        return TaskValue.TaskType303;
      case ("Запрос КУДиР"):
        return TaskValue.TaskType304;
      case ("Формирование декларации УСН"):
        return TaskValue.TaskType305;
      case ("Формирование декларации по имуществу ООО"):
        return TaskValue.TaskType306;
      case ("Формирование декларации 3-НДФЛ для ИП на ОСНО"):
        return TaskValue.TaskType307;
      case ("Формирование единой упрощённой декларации"):
        return TaskValue.TaskType308;
      case ("Формирование декларации НДС"):
        return TaskValue.TaskType309;
      case ("Формирование декларации по налогу на прибыль"):
        return TaskValue.TaskType310;
      case ("Формирование уведомления об исчисленных налогах"):
        return TaskValue.TaskType312;
      case ("Списание"):
        return TaskValue.TaskType403;
      case ("Списание платной консультации"):
        return TaskValue.TaskType403;
      case ("Задача по расчету авансового платежа по сотруднику"):
        return TaskValue.TaskType203;
      case ("Задача по расчету заработной платы сотрудника"):
        return TaskValue.TaskType204;
      default:
        return null;
    }
  };

  const showLoader = (): void => {
    setErrorDownload(false);
    setGetFile(false);
  };

  useEffect(() => {
    const hasNoneFetch1C: boolean = (!parsingResultStatus && !file1CDownloadStatus)
      || (!!file1CDownloadStatus && file1CDownloadStatus === 200);

    if (hasNoneFetch1C) {
      const hasMarkupTask1CData: boolean = !markupTaskStatusForm1C &&
        !markupTaskFileFrom1C &&
        isMarkupTask &&
        isAutomaticProcessing &&
        !parsingResultStatus;

      if (hasMarkupTask1CData) {
        showLoader();
        dispatch(getOperationMarkupFiles(documentTask?.fields?.parsing_result?.[0]?.uuid));
      }
    }

    const isSuccessStatus: boolean = !markupTaskFileFrom1C &&
      markupTaskStatusForm1C === "SUCCESS" &&
      isMarkupTask &&
      isAutomaticProcessing;
    const isErrorStatus: boolean = (!!parsingResultStatus && parsingResultStatus !== 200)
      || (!!file1CDownloadStatus && file1CDownloadStatus !== 200);

    if (isSuccessStatus || isErrorStatus) {
      setErrorDownload(true);
      setUpload(false);
      setGetFile(true);
    }

  }, [
    markupTaskFileFrom1C,
    markupTaskStatusForm1C,
    documentTask,
    isAutomaticProcessing,
    parsingResultStatus,
    file1CDownloadStatus
  ]);

  //логика получения файла из 1с для Разметки операций
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (parsingResultStatus === 200) {
      if (!markupTaskStatusForm1C) {
        setErrorDownload(false);
        setUpload(false);
        setGetFile(true);
      }

      if (markupTaskStatusForm1C === "IN_PROGRESS") {
        timeoutId = setInterval(() => {
          dispatch(getOperationMarkupFiles(documentTask?.fields?.parsing_result?.[0]?.uuid));
        }, 20000);
      }

      if (markupTaskStatusForm1C === "SUCCESS" && markupTaskFileFrom1C === null) {
        setUpload(false);
        setGetFile(true);
        setErrorDownload(true);
      }
    }

    return () => clearInterval(timeoutId);
  }, [
    markupTaskStatusForm1C,
    parsingResultStatus,
    markupTaskFileFrom1C,
  ]);
  //конец логики

  //получаем данный из 1С
  const downloadFileAutomatically = (): void => {
    if (documentTask && currentTaskType === "Разметка операций") {
      showLoader();
      setBlockButton(true);
      dispatch(getProcessTaskAutomaticallyFile(documentTask?.fields?.uuid))
        .then(() => {
          showLoader();
        })
        .finally(() => {
          setBlockButton(true);
          setTimeout(() => {
            dispatch(getOperationMarkupFilesFailure(0));
            setBlockButton(false);
          }, 1000);
        });
    } else {
      setGetFile(false);

      //логика получения uuid файла из 1с
      const paramsUuid1C: ParamsGetUuid1CType = {
        task_uuid: documentTask?.fields?.uuid ?? null,
        start_date: startDate ?? null,
        end_date: endDate ?? null,
        report_type: taskType(selectedType ?? "") ? taskType(selectedType ?? "")!.toString() : null,
        inn: documentTask?.fields?.inn ?? null,
      };
      dispatch(getTaskUuid1C(paramsUuid1C));
    }
  };

  //отображаем полученные данные либо отображаем ошибку
  useEffect(() => {
    const isSuccess: boolean = markupTaskStatusForm1C === "SUCCESS";
    const hasFileFrom1C: boolean = markupTaskFileFrom1C !== null;
    const isFetchError: boolean = (file1CDownloadStatus !== 200 && !!file1CDownloadStatus)
      || (parsingResultStatus !== 200 && !!parsingResultStatus);

    if (markupTaskFileFrom1C && isSuccess && hasFileFrom1C) {
      setUpload(false);
      //Логика переключения на "Отправить на уточнение"
      setUploadedFilesClarification([markupTaskFileFrom1C?.[0]]);
      setSelectedAction("Отправить на уточнение");
      form.setFieldsValue({ taskAction: "Отправить на уточнение" });
      setGetFile(true);
    }

    if (taskFile1CData && taskFile1CData?.files?.length) {
      setUpload(true);
      setUploadedFilesAutomatically(taskFile1CData?.files);
    }

    if (isFetchError) {
      setUpload(false);
      setGetFile(true);
      setErrorDownload(true);
    }

  }, [
    getFile,
    markupTaskFileFrom1C,
    markupTaskStatusForm1C,
    file1CDownloadStatus,
    taskFile1CData,
    parsingResultStatus
  ]);

  //логика получения файла из 1с после получения uuid
  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined;

    const fetchData = (): void => {
      if (taskFile1CUuid && file1CDownloadStatus === 200) {
        const paramsUuid1C: ParamsGetFile1CType = {
          task_uuid: documentTask?.fields?.uuid ? documentTask?.fields?.uuid : null,
          one_s_task_uuid: taskFile1CUuid,
        };
        dispatch(getTaskFileC(paramsUuid1C));
      }
    };

    if (file1CDownloadStatus === 200) {
      if (taskFile1CData?.status === "PROCESSING") {
        setErrorDownload(false);
        setUpload(false);

        intervalId = setInterval(fetchData, 5000);
      } else if (taskFile1CData?.files) {
        if (!taskFile1CData?.files?.length) {
          setErrorDownload(true);
          setUpload(false);
        } else {
          setErrorDownload(false);
          setUpload(true);
          setUploadedFilesAutomatically(taskFile1CData.files);
        }

        if (typeof intervalId !== "undefined") {
          clearInterval(intervalId);
        }
      }
    }

    fetchData();

    return (): void => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [taskFile1CUuid, file1CDownloadStatus, taskFile1CData?.status]);

  useEffect(() => {
    if (file1CDownloadStatus === 200 && taskFile1CData?.files) {
      setErrorDownload(!taskFile1CData?.files?.length);
      setUpload(!!taskFile1CData?.files?.length);
      if (taskFile1CData?.files?.length) {
        setUploadedFilesAutomatically(taskFile1CData?.files);
      }
    }
  }, [taskFile1CData?.files, file1CDownloadStatus]);
//конец логики получения данных из 1с

  const deleteFileAutomatically = (): void => {
    if (upload && taskFile1CData?.files && uploadedFilesAutomatically) {
      const personallyUploadedFiles: UploadedFilesPropsType[] = uploadedFilesAutomatically.filter(
        (file: UploadedFilesPropsType): boolean =>
      !taskFile1CData?.files?.some((taskFile: UploadedFilesPropsType): boolean => taskFile?.uuid === file?.file_name)
      );

      setUploadedFilesAutomatically(taskFile1CData?.files);
      setFileCountAutomatically(1);

      if (personallyUploadedFiles.length > 0) {
        deleteAllUploadFiles(personallyUploadedFiles);
      }
    } else {
      deleteAllUploadFiles(uploadedFilesAutomatically);
      setUploadedFilesAutomatically(null);
      setFileCountAutomatically(0);
    }
  };

  const deleteFileClarification = (): void => {
    if (markupTaskFileFrom1C?.[0] && uploadedFilesClarification) {
      const personallyUploadedFiles: UploadedFilesPropsType[] = uploadedFilesClarification.filter(
        (file: UploadedFilesPropsType): boolean => file?.file_name !== markupTaskFileFrom1C?.[0]?.file_name
      );

      setUploadedFilesClarification([markupTaskFileFrom1C?.[0]]);
      setFileCountClarification(1);

      if (personallyUploadedFiles?.length > 0) {
        deleteAllUploadFiles(personallyUploadedFiles);
      }

    } else {
      deleteAllUploadFiles(uploadedFilesClarification);
      setUploadedFilesClarification(null);
      setFileCountClarification(0);
    }

  };

  const maxSizeFile: number = 50;

  useEffect((): void => {
    dispatch(fetchUserInfo());
    dispatch(decodeToken(authToken));
  }, []);

  const defaultCurrentTaskType: boolean =
    currentTaskType !== "Исходящая задача" &&
    currentTaskType !== "Обращение" &&
    currentTaskType !== "Типовая задача";

  useEffect((): void => {
    if (currentTaskType && defaultCurrentTaskType) {
      setSelectedType(currentTaskType);
      form.setFieldsValue({ taskType: currentTaskType });
    } else {
      setSelectedType(null);
    }
  }, [documentTask, isModalOpen]);

  useEffect((): void => {
    if (documentTask && decisionTask) {
      notificationProcessing(checkUploadFiles, "processing");
      dispatch(sendTypicalTaskDecision(documentTask?.schema_version, decisionTask));
    }
  }, [dispatch, documentTask?.schema_version, decisionTask?.task,
    decisionTask?.common_task_type, decisionTask?.files, decisionTask?.task_decision_type,
    decisionTask?.new_responsible_user_uuid, decisionTask?.new_responsible_rule,
    decisionTask?.comment, decisionTask?.topic, decisionTask?.date_start, decisionTask?.date_end]);

  useEffect((): void => {
    if (decisionTypeStatus) {
      notification.destroy();
    }

    if (decisionTypeStatus === 200) {
      notificationProcessing(checkUploadFiles, "success");
      setTimeout((): void => {
        setUpdateHistory((prev: boolean) => !prev);
      }, 500);
      setClarificationStatus(false);
    }

    if (!!decisionTypeStatus && decisionTypeStatus !== 200) {
      notificationProcessing(checkUploadFiles, "failure");
      setClarificationStatus(false);
    }

    setTimeout((): void => {
      setCheckUploadFiles(false);
      dispatch(sendTypicalTaskDecisionFailure(0));
    }, 5000);
  }, [decisionTypicalTask, error, decisionTypeStatus]);

  const { usersListing, decodedToken } = useSelector((state: AppStateType) => state.account);

  const filteredAccountants: UserListingType[] = usersListing?.filter((user: UserListingType): boolean => {
    const isTaskTypeAppeal: boolean = documentTask?.fields?.task_type?.[0]?.label === "Обращение";
    const positions: string[] = user?.roles;

    if (positions?.length) {
      if (isTaskTypeAppeal) {
        return positions.some((position: string) => position === "accountant-role"
          || position === "assistant-role"
          || position === "contact-center-role"
        );
      } else {
        return positions.includes("accountant-role") || positions.includes("assistant-role");
      }
    }

    return false;
  });

  const filterTaskAction = (
    taskActions: ITaskActionsProps[],
    decodedToken: IJWTPayloadProps | null,
  ): ITaskActionsProps[] => {
    const choseChangeTopic: boolean = actionHistory?.results?.[0]?.fields?.task_decision_type === "change_task_topic";
    const isDraftClient: boolean = !!documentTask?.fields?.client?.[0]?.status_client
      && documentTask?.fields?.client?.[0]?.status_client === "DRAFT";
    
    const taskCode: number | null | undefined = documentTask?.fields?.category_code;
    
    const filterKNAPTask: boolean = taskCode === TaskCode.writeOff
      || taskCode === TaskCode.salaryCalculation
      || taskCode == TaskCode.advanceCalculation;

    const filterAction: ITaskActionsProps[] =
      documentTask?.fields?.task_type[0]?.label === "Исходящая задача"
        ? taskActions.filter((action: ITaskActionsProps): boolean =>
          action.value !== "Обработать задачу автоматически"
          && action.value !== "Обработать задачу вручную"
          && action.value !== "Переназначить задачу")
        : taskActions;

    let filteredTaskActions: ITaskActionsProps[] =
      choseChangeTopic ? filterAction.filter((action: ITaskActionsProps): boolean =>
        action.value !== "Выбрать тему обращения") : filterAction;

    if (currentTaskType !== "Обращение") {
      filteredTaskActions = filteredTaskActions.filter((action: ITaskActionsProps): boolean =>
          action.value !== "Переназначить задачу"
          && action.value !== "Выбрать тему обращения");
    }

    if (currentTaskType === "Разметка операций") {
      filteredTaskActions = filteredTaskActions.filter((action: ITaskActionsProps): boolean =>
        action.value !== "Обработать задачу вручную");
    }
    
    if (currentTaskType === "Наличные операции") {
      filteredTaskActions = filteredTaskActions.filter(
        (action: ITaskActionsProps): boolean => action.value === "Распознать документы в КНАП"
      );
    }
    
    if (filterKNAPTask) {
      filteredTaskActions = filteredTaskActions.filter((action: ITaskActionsProps): boolean =>
       action.value !== "Распознать документы в КНАП");
    }

    if (isDraftClient) {
      filteredTaskActions = filterAction.filter((action: ITaskActionsProps): boolean =>
        action.value !== "Переназначить задачу");
    }

    if (decodedToken) {
      const admin: boolean = roleResolver(decodedToken).isAdmin;

      const accountant: boolean = roleResolver(decodedToken).isAccountant;

      const contactCenterEmployee: boolean = roleResolver(decodedToken).isContactCenter;

      const assistant: boolean = roleResolver(decodedToken).isAssistant;

      if (accountant && !admin) {
        return filteredTaskActions.filter((action: ITaskActionsProps): boolean =>
          action.value !== "Сменить исполнителя"
          && action.value !== "Выбрать тему обращения");
      }

      else if (assistant && !admin) {
        return filteredTaskActions.filter((action: ITaskActionsProps): boolean =>
          action.value !== "Сменить исполнителя"
          && action.value !== "Выбрать тему обращения"
          && action.value !== "Распознать документы в КНАП");
      }

      else if (contactCenterEmployee && !admin) {
        return filteredTaskActions.filter((action: ITaskActionsProps): boolean =>
          action.value !== "Сменить исполнителя"
          && action.value !== "Обработать задачу автоматически"
          && action.value !== "Распознать документы в КНАП");
      }

      else {
        return filteredTaskActions;
      }

    } else {
      return filteredTaskActions;
    }
  };

  const handleResetRequest1C = (): void => {
    setErrorDownload(false);
    setGetFile(true);
    setUpload(false);
    setUploadedFilesAutomatically(null);
    setUploadFilesLoaderAutomatically(false);
    dispatch(getTaskUuid1CFailure(0, null));
    dispatch(getTaskFile1CFailure(0, null));
  };

  const handleSelectType = (value: string | null): void => {
    if (value) {
      setSelectedType(value);
    } else {
      setSelectedType(null);
    }

    handleResetRequest1C();
  };

  enum totalFiles {
    Total100 = 100,
  }

  enum FileListing {
    Empty = 0,
  }

  const uploadFileClarification = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoaderClarification(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesClarification((prevData: UploadedFilesPropsType[] | null) => {
        const safePrevData: UploadedFilesPropsType[] = prevData || [];
        let newData: UploadedFilesPropsType[] = [...safePrevData];
        let newFileCount: number = safePrevData?.length;

        response?.data?.forEach((file: UploadedFilesPropsType): void => {
          if (!newData.some((existingFile: UploadedFilesPropsType): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });

        if (newData?.length > 100) {
          newData = newData.slice(0, 100);
          newFileCount = 100;
        }

        setFileCountClarification(newFileCount);
        setCheckUploadFiles(!!newFileCount);

        return newData;
      });

      if (response?.status === 200) {
        setUploadFilesLoaderClarification(false);
      }
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoaderClarification(false);
    }
  };

  const propsClarification: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: (info: UploadChangeParam): boolean => {
      if (info.fileList.length > 100) {
        info.fileList.splice(100);
      }

      const allFilesUploading: boolean =
        info.fileList.every((file: UploadFile<unknown>): boolean => file.status === "uploading");

      if (allFilesUploading) {
        const filterFile: UploadFile<unknown>[] = info.fileList.filter((file: UploadFile<unknown>): boolean => {
          if (!file) return false;

          const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
          return size < maxSizeFile;
        });

        if (filterFile.length < info.fileList.length) {
          message.error("Объем некоторых файлов или файла не должен превышать 50мб.");
        }

        const files: File[] = filterFile.map((file: UploadFile<unknown>) => file.originFileObj!) as File[];

        if (files.length > 0) {
          uploadFileClarification(files);
        }

      }
      info.fileList.length = 0;
      return false;
    },
    showUploadList: false,
  };

  const showUploadClarification: boolean = fileCountClarification >= totalFiles.Total100;

  const handleRemoveClarification = async (
    e: React.MouseEvent<HTMLElement>,
    fileRemove: UploadedFilesPropsType
  ): Promise<void> => {
    e.preventDefault();

    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove?.uuid);

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setUploadedFilesClarification((prevFiles: UploadedFilesPropsType[] | null): UploadedFilesPropsType[] | null => {
        if (prevFiles) {
          const newFiles: UploadedFilesPropsType[] =
            prevFiles.filter((file: UploadedFilesPropsType): boolean => file?.uuid !== fileRemove?.uuid);
          setFileCountClarification(newFiles?.length);

          if (newFiles?.length === 0) {
            setCheckUploadFiles(false);
          }

          return newFiles?.length > 0 ? newFiles : null;
        }
        return null;
      });
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const counterUploadClarification = (fileCountClarification: number): boolean =>
    fileCountClarification === totalFiles.Total100 || fileCountClarification > totalFiles.Total100;

  const uploadFileAutomatically = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoaderAutomatically(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesAutomatically((prevData: UploadedFilesPropsType[] | null) => {
        const safePrevData: UploadedFilesPropsType[] = prevData || [];
        let newData: UploadedFilesPropsType[] = [...safePrevData];
        let newFileCount: number = safePrevData.length;

        response.data.forEach((file: UploadedFilesPropsType): void => {
          if (!newData.some((existingFile: UploadedFilesPropsType): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });

        if (newData.length > 100) {
          newData = newData.slice(0, 100);
          newFileCount = 100;
        }

        setFileCountAutomatically(newFileCount);
        setCheckUploadFiles(!!newFileCount);

        return newData;
      });

      if (response.status === 200) {
        setUploadFilesLoaderAutomatically(false);
      }
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoaderAutomatically(false);
    }
  };

  const propsAutomatically: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: (info: UploadChangeParam): boolean => {
      if (info.fileList.length > 100) {
        info.fileList.splice(100);
      }

      const allFilesUploading: boolean =
        info.fileList.every((file: UploadFile<unknown>): boolean => file.status === "uploading");

      if (allFilesUploading) {
        const filterFile: UploadFile<unknown>[] = info.fileList.filter((file: UploadFile<unknown>): boolean => {
          if (!file) return false;

          const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
          return size < maxSizeFile;
        });

        if (filterFile.length < info.fileList.length) {
          message.error("Объем некоторых файлов или файла не должен превышать 50мб.");
        }

        const files: File[] = filterFile.map((file: UploadFile<unknown>) => file.originFileObj!) as File[];

        if (files.length > 0) {
          uploadFileAutomatically(files);
        }

      }
      info.fileList.length = 0;
      return false;
    },
    showUploadList: false,
  };

  const showUploadAutomatically: boolean = fileCountAutomatically >= totalFiles.Total100;

  const handleRemoveAutomatically = async (
    e: React.MouseEvent<HTMLElement>,
    fileRemove: UploadedFilesPropsType
  ): Promise<void> => {
    e.preventDefault();

    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove?.uuid);

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setUploadedFilesAutomatically((prevFiles: UploadedFilesPropsType[] | null): UploadedFilesPropsType[] | null => {
        if (prevFiles) {
          const newFiles: UploadedFilesPropsType[] =
            prevFiles.filter((file: UploadedFilesPropsType): boolean => file?.uuid !== fileRemove.uuid);
          setFileCountAutomatically(newFiles?.length);

          if (newFiles.length === 0) {
            setCheckUploadFiles(false);
          }

          return newFiles?.length > 0 ? newFiles : null;
        }
        return null;
      });
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const counterUploadAutomatically = (fileCountClarification: number): boolean =>
    fileCountClarification === totalFiles.Total100 || fileCountClarification > totalFiles.Total100;

  const uploadFileManual = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoaderManual(true);

    try {
      const response = await uploadFiles(formData);

      setUploadedFilesManual((prevData: UploadedFilesPropsType[] | null) => {
        const safePrevData: UploadedFilesPropsType[] = prevData || [];
        let newData: UploadedFilesPropsType[] = [...safePrevData];
        let newFileCount: number = safePrevData.length;

        response.data.forEach((file: UploadedFilesPropsType): void => {
          if (!newData.some((existingFile: UploadedFilesPropsType): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });

        if (newData?.length > 100) {
          newData = newData.slice(0, 100);
          newFileCount = 100;
        }

        setFileCountManual(newFileCount);
        setCheckUploadFiles(!!newFileCount);

        return newData;
      });

      if (response.status === 200) {
        setUploadFilesLoaderManual(false);
      }

    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoaderManual(false);
    }
  };

  const propsManual: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: (info: UploadChangeParam): boolean => {
      if (info.fileList.length > 100) {
        info.fileList.splice(100);
      }

      const allFilesUploading: boolean =
        info.fileList.every((file: UploadFile<unknown>): boolean => file.status === "uploading");

      if (allFilesUploading) {
        const filterFile: UploadFile<unknown>[] = info.fileList.filter((file: UploadFile<unknown>): boolean => {
          if (!file) return false;

          const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
          return size < maxSizeFile;
        });

        if (filterFile.length < info.fileList.length) {
          message.error("Объем некоторых файлов или файла не должен превышать 50мб.");
        }

        const files: File[] = filterFile.map((file: UploadFile<unknown>) => file.originFileObj!) as File[];

        if (files.length > 0) {
          uploadFileManual(files);
        }

      }
      info.fileList.length = 0;
      return false;
    },
    showUploadList: false,
  };

  const showUploadManual: boolean = fileCountManual >= totalFiles.Total100;

  const handleRemoveManual = async (
    e: React.MouseEvent<HTMLElement>,
    fileRemove: UploadedFilesPropsType
  ): Promise<void> => {
    e.preventDefault();

    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove?.uuid);

      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);

      setUploadedFilesManual((prevFiles: UploadedFilesPropsType[] | null): UploadedFilesPropsType[] | null => {
        if (prevFiles) {
          const newFiles: UploadedFilesPropsType[] =
            prevFiles.filter((file: UploadedFilesPropsType): boolean => file.uuid !== fileRemove.uuid);
          setFileCountManual(newFiles.length);

          if (newFiles.length === 0) {
            setCheckUploadFiles(false);
          }

          return newFiles.length > 0 ? newFiles : null;
        }

        return null;
      });
    } catch (error) {
      message.error("Файл не удален!");
    }
  };

  const counterUploadManual = (fileCountManual: number): boolean =>
    fileCountManual === totalFiles.Total100 || fileCountManual > totalFiles.Total100;

  const placeholderInput = (text: string): JSX.Element => {
    return (
      <div className="flex items-center">
        <SearchIcon className={`${css.searchIcon} mr-2`}/>
        <div>
          {text}
        </div>
      </div>
    );
  };

  const handleSelectChange = (value: string): void => {
    const selectUser: UserListingType[] = usersListing.filter((user: UserListingType): boolean => user.id === value);
    
    setSelectedResponsible(selectUser[0]);
    setSelectedResponsibleRule("one");
  };

  const clearImplementer = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setSelectedResponsible(null);
    setSelectedResponsibleRule(null);
    form.resetFields(["isApproved", "selectImplementer"]);
  };

  const handleCommentClarificationChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;
    const charsCount: number = value.length;

    setCommentClarification(value);
    setRemainingCharsClarification(charsCount);
  };

  const handleCommentManualChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const value: string = e.target.value;
    const charsCount: number = value.length;

    setCommentManual(value);
    setRemainingCharsManual(charsCount);
  };

  const handleDateChange = (dates: null | (Dayjs | null)[]): void | null => {
    if (dates && dates[0] && dates[1]) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setStartDate(null);
      setEndDate(null);
    }

    handleResetRequest1C();
  };

  const allFieldsFilled: boolean =
    !!selectedAction &&
    !!selectedType &&
    (currentTaskType !== "Разметка операций" ? (!!startDate && !!endDate) : true);

  const renderButtonFetch1C = (): JSX.Element => {
    const isRepeatedFetch: boolean = isMarkupTask
      && (
        !!markupTaskStatusForm1C ||
        !!file1CDownloadStatus ||
        (!!parsingResultStatus && parsingResultStatus !== 200)
      );

    const textFetch1CButton: string = isRepeatedFetch ? "Обработать повторно" : "Обработать";

    return (
      <ButtonCustom
        className={css.buttonHandle}
        type="primary"
        text={textFetch1CButton}
        htmlType="button"
        disabled={!allFieldsFilled}
        onClick={() => downloadFileAutomatically()}
      />
    );
  };

  const downloadFile1CStatus = (): JSX.Element | null => {
    const hasMarkupParsingResult: boolean = !markupTaskFileFrom1C && !markupTaskStatusForm1C;
    const isMarkupTaskSuccess: boolean = parsingResultStatus === 200 && markupTaskFileFrom1C === null;

    const isAppealTaskSuccess: boolean = file1CDownloadStatus === 200 && !!taskFile1CData?.files?.length;

    if (hasMarkupParsingResult) return null;

    return (isMarkupTaskSuccess || isAppealTaskSuccess) ?
      (
        <>
          <div className={css.successNoFileOneC}>
            <SuccessIcon className="flex-shrink-0"/>
            <div className="mt-7">
              Запрос обработан успешно
            </div>
            <div>
              Пожалуйста, подтвердите обработку задачи
            </div>
          </div>
          {currentTaskType === "Разметка операций" && (
            <div className="mt-5">
              {renderButtonFetch1C()}
            </div>
          )}
        </>
      ) : (
        <>
          <div className={css.errorFileOneC}>
            <div>
              <ErrorIcon />
            </div>
            <div className="mt-7">
              Ошибка загрузки документов из 1С
            </div>
            {currentTaskType !== "Разметка операций" && (
              <div>
                Пожалуйста, обработайте задачу вручную, выбрав действие по задаче.
              </div>
            )}
          </div>
          {currentTaskType === "Разметка операций" && (
            <div className="mt-5">
              {renderButtonFetch1C()}
            </div>
          )}
        </>
      );
  };

  const loadingFiles = (): JSX.Element | null => {
    return !upload ? (
      <div className={css.getFileOneC}>
        <div>
          <LoadingCustom fontSize={32}/>
        </div>
        <div className="mt-7">
          Пожалуйста, подождите, мы загружаем документы из 1С
        </div>
      </div>
    ) : null;
  };

  const renderUploadAutomaticallyWindow: JSX.Element | null = (!showUploadAutomatically ? (
    <>
      <div className={`${css.modalText} mb-5`}>
        Прикрепите файлы если это необходимо
      </div>
      <DraggerForm
        props={{...propsAutomatically}}
        fileCount={fileCountAutomatically}
        counterUpload={counterUploadAutomatically}
      />
    </>
  ) : null);

  const uploadFilesAutomaticallyList: JSX.Element[] | null = uploadedFilesAutomatically?.length ? (
    uploadedFilesAutomatically.map((file: UploadedFilesPropsType, index: number) => (
      <div key={index} className={css.documentsAction}>
        <div className="flex items-center pl-[2px]">
          <AttachFileIcon className="flex-shrink-0"/>
          <a className={css.fileName} onClick={() => downloadFile(file?.file_name, downloadUrl(file.uuid))}>
            {file?.file_name}
          </a>
        </div>
        {!(file?.uuid === taskFile1CData?.files?.[index]?.uuid) && (
          <div
            className={css.deleteIcon}
            onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveAutomatically(e, file)}
          >
            <DeleteDocument className="flex-shrink-0"/>
          </div>
        )}
      </div>
    ))) : null;

  const filesUploadAutomatically = (uploadFilesLoaderAutomatically: boolean):JSX.Element => {
    return (
      <div className={`${css.fileListing} grid justify-items-start items-center`}>
        {uploadFilesLoaderAutomatically ? (
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              width: "100%"
            }}
          />
        ) : uploadFilesAutomaticallyList}
      </div>
    );
  };

  const uploadedFilesClarificationList: JSX.Element[] | null = uploadedFilesClarification?.length ? (
    uploadedFilesClarification.map((file: UploadedFilesPropsType, index: number) => (
      <div key={index} className={css.documentsAction}>
        <div className="flex items-center pl-0.5">
          <AttachFileIcon className="flex-shrink-0" />
          <a className={css.fileName} onClick={() => downloadFile(file?.file_name, downloadUrl(file.uuid))}>
            {file?.file_name}
          </a>
        </div>
        {!(file?.uuid === markupTaskFileFrom1C?.[0]?.uuid) ? (
          <div
            className={css.deleteIcon}
            onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveClarification(e, file)}
          >
            <DeleteDocument className="flex-shrink-0" />
          </div>
        ) : null}
      </div>)
    )) : null;

  const uploadedFilesManualList: JSX.Element[] | null = uploadedFilesManual?.length ? (
    uploadedFilesManual.map((file: UploadedFilesPropsType, index: number) => (
      <div key={index} className={css.documentsAction}>
        <div className="flex items-center pl-0.5">
          <AttachFileIcon className="flex-shrink-0"/>
          <a className={css.fileName} onClick={() => downloadFile(file.file_name, downloadUrl(file.uuid))}>
            {file.file_name}
          </a>
        </div>
        <div
          className={css.deleteIcon}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveManual(e, file)}
        >
          <DeleteDocument className="flex-shrink-0"/>
        </div>
      </div>)
  )) : null;

  const isRequiredFieldClarification: boolean = !remainingCharsClarification;

  const isRequiredFieldManual: boolean = !remainingCharsManual
    && (currentTaskType === "Разметка операций" || currentTaskType === "Обращение");

  const filterTaskActions = (taskActions: ITaskActionsProps[]): ITaskActionsProps[] => {
    if (currentTaskType === "Разметка операций") {
      return [{
          value: "Разметка операций",
          label: "Разметка операций",
      }];
    } else {
      return taskActions;
    }
  };

  const renderActionTo1C = (): JSX.Element | null => (
    getFile ? renderButtonFetch1C() : loadingFiles()
  );

  const modalContent = (selectedAction: string): JSX.Element | null => {
    switch (selectedAction) {
      case ("Отправить на уточнение"):
        return (
          <div className="mb-5">
            <div className={`${css.modalText} mb-5 mt-[-7px]`}>
              Перед отправкой ответа клиенту опишите требования для уточнения
              данных в произвольной форме. Ваш комментарий увидит клиент.
            </div>
            <div className="mb-5">
              <Form.Item
                noStyle
                validateStatus={isRequiredFieldClarification ? "error" : ""}
              >
                <TextArea
                  id="textClarification"
                  rows={4}
                  maxLength={2000}
                  className="text-base font-normal leading-5 text-gray-900"
                  placeholder="Ваш комментарий клиенту"
                  value={commentClarification}
                  onChange={handleCommentClarificationChange}
                />
                <div className="text-xs font-normal leading-100 text-gray-700 text-right">
                  {remainingCharsClarification}/2000 символов
                </div>
              </Form.Item>
            </div>
            {!showUploadClarification ? (
              <>
                <div className={`${css.modalText} mb-5`}>
                  Прикрепите файлы если это необходимо
                </div>
                <DraggerForm
                  props={{...propsClarification}}
                  fileCount={fileCountClarification}
                  counterUpload={counterUploadClarification}
                />
              </>
            ) : null}
            <div className={`${css.fileListing} grid justify-items-start items-center`}>
              {uploadFilesLoaderClarification ? (
                <Skeleton
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    width: "100%"
                  }}
                />
              ) : uploadedFilesClarificationList}
            </div>
          </div>
        );
      case ("Обработать задачу автоматически"):
        return (
          <>
            <Form.Item
              name="taskType"
              className="mb-7"
              validateStatus={!selectedType ? "error" : ""}
            >
              <Select
                id="taskType"
                placeholder="Тип задачи"
                options={filterTaskActions(taskActionsType)}
                showSearch
                allowClear
                size="large"
                className={css.modalDropType}
                onChange={handleSelectType}
                value={selectedType}
                notFoundContent={<ZeroSearch dataFilter={true} />}
              />
            </Form.Item>
            <div className="pb-5">
              {currentTaskType !== "Разметка операций" && (
                <>
                  <div className={`${css.modalText} mb-5`}>
                    Выберите период
                  </div>
                  <Form.Item
                    name="datePeriod"
                    validateStatus={!startDate && !endDate ? "error" : ""}
                  >
                    <RangePicker
                      id="datePeriod"
                      size="large"
                      className="w-full mb-2"
                      format="DD.MM.YYYY"
                      placeholder={["С даты", "По дату"]}
                      maxDate={dayjs()}
                      onChange={(dates: null | (Dayjs | null)[]): void | null => handleDateChange(dates)}
                    />
                  </Form.Item>
                </>
              )}
              {errorDownload
                ? downloadFile1CStatus()
                : renderActionTo1C()
              }
              {upload ? (
                <>
                  {renderUploadAutomaticallyWindow}
                  {filesUploadAutomatically(uploadFilesLoaderAutomatically)}
                </>
              ) : null }
            </div>
          </>
        );
      case ("Обработать задачу вручную"):
        return (
          <div className="mb-5">
            <div className={`${css.modalText} mb-5 mt-[-7px]`}>
              Перед отправкой ответа клиенту опишите требования для уточнения
              данных в произвольной форме. Ваш комментарий увидит клиент.
            </div>
            <div className="mb-5">
              <Form.Item
                noStyle
                validateStatus={isRequiredFieldManual ? "error" : ""}
              >
                <TextArea
                  id="textManual"
                  rows={4}
                  maxLength={2000}
                  className="text-base font-normal leading-5 text-gray-900"
                  placeholder="Ваш комментарий клиенту"
                  value={commentManual}
                  onChange={handleCommentManualChange}
                />
                <div className="text-xs font-normal leading-100 text-gray-700 text-right">
                  {remainingCharsManual}/2000 символов
                </div>
              </Form.Item>
            </div>
            {!showUploadManual ? (
              <>
                <div className={`${css.modalText} mb-5`}>
                  Прикрепите файлы если это необходимо
                </div>
                <DraggerForm
                  props={{...propsManual}}
                  fileCount={fileCountManual}
                  counterUpload={counterUploadManual}
                />
              </>
            ) : null}
            <div className={`${css.fileListing} grid justify-items-start items-center`}>
              {uploadFilesLoaderManual ? (
                <Skeleton
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    width: "100%"
                  }}
                />
              ) : uploadedFilesManualList}
            </div>
          </div>
        );
      case ("Сменить исполнителя"):
        return (
          <Form form={form}>
            <div>
              <div className={`${css.modalText} mb-5`}>
                Вы можете изменить исполнителя, закрепленного за задачей. Для этого воспользуйтесь поиском ниже, чтобы
                выбрать нужного сотрудника.
              </div>
              {/*TODO временно скрываем данный функционал*/}
              {/*<Form.Item name="isApproved" className="text-base font-normal leading-5 text-blue-800">*/}
              {/*  <Radio.Group onChange={handleChangeResponsible}>*/}
              {/*    <Space direction="vertical">*/}
              {/*      <Radio value="one" className={css.radioVariant}>Только для этой задачи</Radio>*/}
              {/*      <Radio value="all" className={css.radioVariant}>Для всех задач данного исполнителя</Radio>*/}
              {/*      <Radio value="task_type" className={css.radioVariant}>По конкретному типу задач</Radio>*/}
              {/*    </Space>*/}
              {/*  </Radio.Group>*/}
              {/*</Form.Item>*/}
              {selectedResponsible ? (
                <div>
                  <div className="text-base font-medium leading-5 text-black-800">
                    Задача будет назначена
                  </div>
                  <div className="flex justify-between items-center mt-3 mb-5">
                    <div>
                      {/*<div className="inline-block gray-color text-sm">*/}
                      {/*  Бухгалтер*/}
                      {/*</div>*/}
                      <div className={css.textName}>
                        {selectedResponsible?.attributes?.last_name}
                        {" "}
                        {selectedResponsible?.attributes?.first_name}
                        {" "}
                        {selectedResponsible?.attributes?.second_name}
                      </div>
                    </div>
                    <div className="flex">
                      <ButtonCustom
                        className={`${css.buttonSelect} mr-2`}
                        type="primary"
                        ghost
                        text="О пользователе"
                      />
                      <ButtonCustom
                        className={css.buttonClose}
                        type="primary"
                        ghost
                        text=""
                        onClick={(e: React.MouseEvent<HTMLElement>) => clearImplementer(e)}
                      >
                        <CloseImplementer className="flex-shrink-0"/>
                      </ButtonCustom>
                    </div>
                  </div>
                </div>
              ) : (
                <Form.Item name="selectImplementer">
                  <Select
                    id="selectImplementer"
                    className={css.selectImplementer}
                    placeholder={placeholderInput("ФИО пользователя")}
                    showSearch
                    allowClear
                    size="large"
                    suffixIcon={null}
                    filterOption={false}
                    onSelect={handleSelectChange}
                    onSearch={setSearchEmployeeValue}
                    onClear={() => setSearchEmployeeValue("")}
                    searchValue={searchEmployeeValue}
                    notFoundContent={<ZeroSearch dataFilter={true} />}
                  >
                    {filteredAccountants?.map((option: UserListingType) => (
                      <Option key={option.id} value={option?.id}>
                        <div className="flex justify-between items-center">
                          <div>
                            {option?.attributes?.last_name}
                            {" "}
                            {option?.attributes?.first_name}
                            {" "}
                            {option?.attributes?.second_name}
                          </div>
                          <ButtonCustom
                            className={css.buttonSelect}
                            type="primary"
                            ghost
                            text="О пользователе"
                          />
                        </div>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </div>
          </Form>
        );
      case ("Переназначить задачу"):
        return (
          <ReassignTask reassignText={reassignText} />
        );
      case ("Выбрать тему обращения"):
        return (
          <TopicOfAppeal />
        );
      case ("Распознать документы в КНАП"):
        return (
          <KnapRecognize
            uploadedFilesKNAP={uploadedFilesKNAP}
            setUploadedFilesKNAP={setUploadedFilesKNAP}
          />
        );
      default:
        return null;
    }
  };

  const decisionType = (text: string): string => {
    switch (text) {
      case ("Отправить на уточнение"):
        return "clarification_decision";
      case ("Обработать задачу автоматически"):
        return "auto_decision";
      case ("Обработать задачу вручную"):
        return "manual_decision";
      case ("Сменить исполнителя"):
        return ("change_responsible");
      case ("Переназначить задачу"):
        return ("reassign_task");
      case ("Выбрать тему обращения"):
        return ("change_task_topic");
      case ("Распознать документы в КНАП"):
        return ("knap_decision");
      default:
        return "";
    }
  };

  const commentVariant = (decisionResult: string): string | null => {
    switch (decisionResult) {
      case ("clarification_decision"):
        return commentClarification;
      case ("manual_decision"):
        return commentManual;
      case ("reassign_task"):
        return reassignText;
      default:
        return null;
    }
  };

  const filesDataVariant = (decisionResult: string): string[] | undefined | null => {
    let uuids: string[] | undefined | null = null;
    switch (decisionResult) {
      case "clarification_decision":
        uuids = uploadedFilesClarification ?
          uploadedFilesClarification?.map((file: UploadedFilesPropsType) => file.uuid) : [];
        break;
      case "auto_decision":
        uuids = uploadedFilesAutomatically ?
          uploadedFilesAutomatically?.map((file: UploadedFilesPropsType) => file.uuid) : [];
        break;
      case "manual_decision":
        uuids = uploadedFilesManual ?
          uploadedFilesManual?.map((file: UploadedFilesPropsType) => file.uuid) : [];
        break;
      case "knap_decision":
        uuids = uploadedFilesKNAP?.length ?
          uploadedFilesKNAP?.map((file: UploadedFilesPropsType) => file.uuid) : [];
        break;
      default:
        break;
    }
    return uuids;
  };

  const resetStates = (): void => {
    form.resetFields();
    setSelectedAction("");
    setCommentClarification("");
    setCommentManual("");
    setSelectedResponsibleRule("");
    setSelectedResponsible(null);
    setRemainingCharsManual(0);
    setRemainingCharsClarification(0);
    setStartDate(null);
    setEndDate(null);
    setErrorDownload(false);
    setGetFile(true);
    setUpload(false);
    setUploadedFilesAutomatically(null);
    setUploadFilesLoaderAutomatically(false);
    setUploadedFilesKNAP([]);
  };

  const modalOk = (): void => {
    const decisionResult: string = decisionType(selectedAction);
    const taskTypeData: number | null = decisionResult === "auto_decision" ? taskType(selectedType ?? "") : null;
    const dateFrom: string | null = decisionResult === "auto_decision" ? startDate : null;
    const dateTo: string | null = decisionResult === "auto_decision" ? endDate : null;
    const commentData: string | null = commentVariant(decisionResult);
    const filesData: string[] | undefined | null = filesDataVariant(decisionResult);
    const responsible: string | null = selectedResponsible ? selectedResponsible.id : null;
    const responsibleData: string | null =
      decisionResult === "change_responsible" ? responsible : null;
    const responsibleRule: string | null = selectedResponsibleRule ? selectedResponsibleRule : null;
    const topicResult: string | null = decisionResult === "change_task_topic" ? topicAppeal : null;
    
    if (decisionResult === "clarification_decision") {
      setClarificationStatus(true);
      setCheckClarification(false);
    }

    setDecisionTask({
      task: documentTask?.fields?.uuid,
      common_task_type: taskTypeData,
      task_decision_type: decisionResult,
      comment: commentData ?? null,
      files: filesData,
      date_start: dateFrom?.slice(0, 10),
      date_end: dateTo?.slice(0, 10),
      new_responsible_user_uuid: responsibleData,
      new_responsible_rule: responsibleRule,
      topic: topicResult,
    });

    setIsModalOpen(false);
    resetStates();
    setUploadedFilesClarification(null);
    setFileCountClarification(0);
    setUploadedFilesAutomatically(null);
    setFileCountAutomatically(0);
    setUploadedFilesManual(null);
    setUploadedFilesKNAP([]);
    setFileCountManual(0);

    if (currentTaskType && defaultCurrentTaskType) {
      setSelectedType(currentTaskType);
    } else {
      setSelectedType(null);
    }
  };

  const modalCancel = (): void => {
    setIsModalOpen(false);
    resetStates();

    if (markupTaskStatusForm1C === "IN_PROGRESS") {
      dispatch(getOperationMarkupFilesFailure(0));
    }

    if (uploadedFilesClarification) {
      deleteFileClarification();
    }

    if (uploadedFilesManual) {
      deleteAllUploadFiles(uploadedFilesManual);
      setUploadedFilesManual(null);
      setFileCountManual(0);
    }

    if (uploadedFilesAutomatically) {
      deleteFileAutomatically();
    }

    if (currentTaskType && defaultCurrentTaskType) {
      setSelectedType(currentTaskType);
    } else {
      setSelectedType( null);
    }
  };

  const deleteAllUploadFiles = async (uploadedFilesData: UploadedFilesPropsType[] | null): Promise<void | null> => {
    if (uploadedFilesData) {
      const uuids: string[] = uploadedFilesData.map((file: UploadedFilesPropsType) => file.uuid);
      const params: URLSearchParams = new URLSearchParams();
      uuids.forEach((uuid: string) => params.append("file_uuid", uuid));

      try {
        await deleteUploadFile(params.toString());
      } catch (error) {
        message.error("Файлы не удалены!");
      }
    }
    return null;
  };

  const modalHeader = (text: string): JSX.Element => {
    return (
      <div className={css.modalHeader}>{text}</div>
    );
  };

  const changeResponsible: boolean =
    selectedAction === "Сменить исполнителя"
    && !!selectedResponsibleRule
    && !!selectedResponsible;

  const successNoFile1C: boolean =
    (!markupTaskFileFrom1C && parsingResultStatus === 200)
    || !!uploadedFilesAutomatically?.length;

  const downloadFileStatus: boolean =
    (file1CDownloadStatus === 200 || parsingResultStatus === 200)
    && (markupTaskStatusForm1C === "SUCCESS" || !!taskFile1CData?.files?.length)
    && successNoFile1C;

  const automaticProcessing: boolean = (
    selectedAction === "Обработать задачу автоматически" &&
    !!selectedType &&
    !!endDate &&
    !!startDate &&
    downloadFileStatus
  ) || (
    currentTaskType === "Разметка операций" &&
    !!selectedType &&
    downloadFileStatus
  );

  const isApplyButton: boolean = !selectedAction ||
    (selectedAction === "Сменить исполнителя" && !changeResponsible) ||
    (selectedAction === "Обработать задачу автоматически" && !automaticProcessing) ||
    (selectedAction === "Отправить на уточнение" && isRequiredFieldClarification) ||
    (selectedAction === "Обработать задачу вручную" && isRequiredFieldManual) ||
    (selectedAction === "Выбрать тему обращения" && !topicAppeal) ||
    (selectedAction === "Распознать документы в КНАП" && !uploadedFilesKNAP?.length);

  const modalFooter: JSX.Element = (
    <div className="mt-5">
      <ButtonCustom
        className={`${css.buttonBack} mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={modalCancel}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={modalOk}
        className={css.buttonOk}
        disabled={isApplyButton || isBlockButton}
      />
    </div>
  );
  
  const changeTaskAction = (value: string): void => {
    setSelectedAction(value);
  };
  
  const clearTaskAction = (): void => {
    setSelectedAction("");
    setStartDate(null);
    setEndDate(null);
    form.resetFields();
  };

  return (
    <Form form={form}>
      <Modal
        width={580}
        title={modalHeader("Действия по задаче")}
        open={isModalOpen}
        onCancel={modalCancel}
        footer={modalFooter}
      >
        <div className={`${css.modalText}  mt-3`}>Выберите действие по задаче.</div>
        <Form.Item name="taskAction" className="mt-[-5px] mb-0">
          <Select
            id="taskAction"
            placeholder="Действие"
            options={filterTaskAction(taskActions, decodedToken)}
            showSearch
            allowClear
            size="large"
            className={`${css.modalDrop} mb-7`}
            notFoundContent={<ZeroSearch dataFilter={true} />}
            onChange={changeTaskAction}
            onClear={clearTaskAction}
          />
        </Form.Item>
        {modalContent(selectedAction)}
      </Modal>
    </Form>
  );
};

export default ModelContent;
