import { ClientTariffDataType } from "app/types";

enum ClientConsultation {
  hasNone = 0,
}

export const getConsultationsNumber = (clientTariffData: ClientTariffDataType | null): string | number => {
  if (clientTariffData?.number_of_consultations) {
    const consultations: number = parseInt(clientTariffData.number_of_consultations);
    return consultations > ClientConsultation.hasNone
      ? clientTariffData.number_of_consultations
      : ClientConsultation.hasNone;
  } else {
    return `${ClientConsultation.hasNone}`;
  }
};
