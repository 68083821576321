import React, {
  FC,
  JSX,
  Dispatch,
  useEffect,
  SetStateAction,
} from "react";
import css from "./TasksListing.module.css";
import {
  Button,
  Form,
  FormInstance,
  Input,
  Table,
} from "antd";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search_icon.svg";
import {
  FilterNamesType,
  FilterTasksType,
  ResponsibleFilterType,
} from "app/types";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../reducers/mainReducer";
import ZeroTasks from "../ZeroTasks/ZeroTasks";
import * as jose from "jose";
import { transformStatuses } from "../../../utils/transformStatuses";
import { AppDispatch } from "../../../store/store";
import {
  getTaskPageNumber,
  setTaskListingFilter,
  setTaskListingParams,
  setTaskListingSorter,
  setTaskListingTagKey
} from "../../../actions/tasks.actions";
import ZeroSearch from "../../ClientsList/ZeroSearch/ZeroSearch";
import { FormPropsType, TasksListingType } from "../TasksType/TasksListingType/TasksListingType";
import TasksListingTags from "./TasksListingItem/TasksListingTags";
import { useInfiniteScroll, UseInfiniteScrollHookParams } from "../../../Hooks/useInfiniteScroll/useInfiniteScroll";
import TaskListingFastFilters from "./TasksListingItem/TaskListingFastFilters";

interface ITasksProps {
  form: FormInstance;
  tasksListingProps: TasksListingType;
  setLoadingData: Dispatch<SetStateAction<boolean>>;
}

enum ListingPage {
  firstPage = 1,
  pagePerScroll = 50,
}

const TasksListing: FC<ITasksProps> = ({
  form,
  tasksListingProps,
  setLoadingData,
}): JSX.Element => {
  const values: FormPropsType = Form.useWatch([], form);
  const { organizationName } = values || {};

  const {
    activeKey,
    prevActiveKey,
    tabChange,
    columns,
    currentPage,
    tasksData,
    filterData,
    setFilterData,
    pageSize,
    totalDocs,
    paramsData,
    setParamsData,
    setCurrentPage,
    showModal,
    handlePageChange,
    handlePageSizeChange,
    sortValue,
    isLoadingData,
    setFilterStatusLabel
  } = tasksListingProps;

  const tasksPageSize: number = useSelector((state: AppStateType) => state.tasks.tasksPageSize);

  const dispatch = useDispatch<AppDispatch>();

  useInfiniteScroll({
    totalDocs,
    paramsData,
    pagePerScroll: ListingPage.pagePerScroll,
    setParamsData,
    setLoadingData,
  } as UseInfiniteScrollHookParams);

  useEffect(() => {
    if (organizationName?.trim() === "") {
      setParamsData((prev: FilterTasksType | null) => {
        if (prev && prev.clients !== null) {
          const { clients, ...rest } = prev;
          dispatch(setTaskListingParams({...rest}));
          return {...rest};
        } else {
          dispatch(setTaskListingParams(prev));
          return prev;
        }
      });
    }

    const timeout: NodeJS.Timeout = setTimeout(():void => {
      const hasInputValue: boolean = organizationName?.trim() !== ""
        && typeof organizationName === "string"
        && (!paramsData?.clients || paramsData?.clients !== organizationName);

      if (hasInputValue) {
        setParamsData((prev: FilterTasksType | null) => {
          const updatedParams = {
            ...prev,
            clients: organizationName?.trim()
          };
          dispatch(setTaskListingParams(updatedParams));
          return updatedParams;
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [organizationName]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue("organizationName", e.target.value);

    if (e.target.value) {
      setTimeout((): void => {
        setCurrentPage(ListingPage.firstPage);
        dispatch(getTaskPageNumber(ListingPage.firstPage));
      }, 2000);
    } else {
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
    }
  };

  const FilterNames: FilterNamesType = {
    "clients_lookup.short_name": "ОПФ:",
    status_task: "",
    "clients_lookup.tax_system": "Тип налогообложения:",
    "responsible_reference.uuid": "Ответственный:",
    created_at_gte: "Период от:",
    updated_at_lte: "Период до:",
    initiation_date_gte: "Период взятия в работу:",
    is_expired: "",
  };

  const filterTabs = (activeKey: string): FilterTasksType | null => {
    setCurrentPage(ListingPage.firstPage);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    form.setFieldValue("organizationName", null);

    const pageDataProps: FilterTasksType = {
      page_size: tasksPageSize,
      page: ListingPage.firstPage,
      order_by: sortValue,
      task_type_lookup: null,
    };

    switch (activeKey) {
      case ("2"):
        return { ...pageDataProps, task_type_lookup: "[301]" };
      case ("3"):
        return { ...pageDataProps, task_type_lookup: "[302]" };
      case ("4"):
        return { ...pageDataProps, task_type_lookup: "[100-300]" };
      default:
        return pageDataProps;
    }
  };

  const deleteFilterData = (e: React.MouseEvent<HTMLElement>, key: string): void => {
    e.preventDefault();
    setFilterData((prev: FilterTasksType | null) => {
      if (prev) {
        dispatch(setTaskListingFilter({ ...prev, [key]: null }));
        return { ...prev, [key]: null };
      }
      dispatch(setTaskListingFilter(null));
      return null;
    });

    if (key === "clients") {
      form.setFieldValue("organizationName", null);
    }

    setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
      setCurrentPage(ListingPage.firstPage);
      dispatch(getTaskPageNumber(ListingPage.firstPage));
      const updatedParams: FilterTasksType | null = prev
        ? { ...prev, [key]: null, page: 1, ... (key === "task_type_lookup" ? { order_by: "-number" } : {}) }
        : null;

      if (key === "created_at_gte" && updatedParams) updatedParams["created_at_lte"] = null;
      if (key === "initiation_date_gte" && updatedParams) updatedParams["initiation_date_lte"] = null;
      if (key === "updated_at_lte" && updatedParams) {
        updatedParams["updated_at_gte"] = null;

        if (activeKey === "1") updatedParams["status_task"] = null;
      }

      if (key === "task_type_lookup") {
        dispatch(setTaskListingSorter("-number"));
      }

      if (updatedParams) {
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      } else {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        filterTabs(activeKey);
      }
      dispatch(setTaskListingParams(null));
      return null;
    });

  };

  const deleteStatusFilterData = (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    filterStatus: string,
    value: string[]
  ): void => {
    e.preventDefault();
    setCurrentPage(ListingPage.firstPage);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    
    setFilterData((prev: FilterTasksType | null) => {
      const filterStatusData: string[] = value.filter((status:string):boolean => status !== filterStatus);
      const returnStatus: string[] | null = filterStatusData.length ? filterStatusData : null;

      if (prev) {
        dispatch(setTaskListingFilter({ ...prev, [key]: returnStatus }));
        return { ...prev, [key]: returnStatus };
      }

      dispatch(setTaskListingFilter(null));
      return null;
    });
    setParamsData((prev: FilterTasksType | null) => {
      const filterStatusData: string[] = value.filter((status:string):boolean => status !== filterStatus);
      const returnStatus: string | null = filterStatusData.length ? `[${transformStatuses(filterStatusData)}]` : null;

      const updatedParams: FilterTasksType | null =
        prev ? { ...prev, [key]: returnStatus, page: 1 } : null;
      if (updatedParams) {
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      } else {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        filterTabs(activeKey);
      }

      dispatch(setTaskListingParams(null));
      return null;
    });
  };

  const deleteResponsibleFilterData = (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    id: string,
    value: ResponsibleFilterType[]
  ): void => {
    e.preventDefault();
    setCurrentPage(1);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    
    setFilterData((prev: FilterTasksType | null) => {
      const filterResponsibleData: ResponsibleFilterType[] =
        value.filter((resp: ResponsibleFilterType):boolean => resp.id !== id);
      const returnResponsible: ResponsibleFilterType[] | null =
        filterResponsibleData.length ? filterResponsibleData : null;

      if (prev) {
        dispatch(setTaskListingFilter({ ...prev, [key]: returnResponsible }));
        return { ...prev, [key]: returnResponsible };
      }

      dispatch(setTaskListingFilter(null));
      return null;
    });

    setParamsData((prev: FilterTasksType | null) => {
      const filterResponsibleData: ResponsibleFilterType[] =
        value.filter((resp: ResponsibleFilterType):boolean => resp.id !== id);
      const nameResponsible: string[] = filterResponsibleData.map((name: ResponsibleFilterType) => name.id);
      const returnResponsible: string | null = filterResponsibleData.length ? `[${nameResponsible}]` : null;

      const updatedParams =
        prev ? { ...prev, [key]: returnResponsible, page: 1 } : null;
      if (updatedParams) {
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      } else {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        filterTabs(activeKey);
      }

      dispatch(setTaskListingParams(null));
      return null;
    });
  };

  const clearFilterData = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setFilterData(null);
    form.setFieldValue("organizationName", null);
    dispatch(setTaskListingSorter(sortValue));
    dispatch(setTaskListingFilter(null));
    setParamsData(filterTabs(activeKey));
    dispatch(setTaskListingParams(filterTabs(activeKey)));
  };

  useEffect(() => {
    if (activeKey !== prevActiveKey && tabChange) {
      setFilterData(() => {
        dispatch(setTaskListingFilter(null));
        return null;
      });

      dispatch(setTaskListingTagKey(activeKey));
      setParamsData(() => {
        dispatch(setTaskListingParams(filterTabs(activeKey)));
        return filterTabs(activeKey);
      });
    }
  }, [activeKey]);

  const zeroTasks = (): JSX.Element => {
    const hasFilter: boolean = !!paramsData?.clients
      || !!paramsData?.task_type_lookup
      || !!paramsData?.status_task
      || !!filterData;

    return (
      <>
        {!isLoadingData && hasFilter && <ZeroSearch />}
        {!isLoadingData && !hasFilter && <ZeroTasks key={activeKey} activeKey={activeKey} />}
      </>
    );
  };

  return (
    <Form form={form}>
      <div className={css.blockTable}>
        {filterData
          && Object.values(filterData).find((
            value: string | number | string[] | ResponsibleFilterType[] | null
            ) => value) ? (
            <div className={css.blockInputData}>
              <div className="flex mb-3">
                <Form.Item name="organizationName" noStyle>
                  <Input
                    id="organizationName"
                    allowClear
                    className={css.input}
                    suffix={<SearchIcon className={css.searchIcon} />}
                    placeholder="Наименование клиента, ИНН"
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Button
                  className={css.button}
                  onClick={showModal}
                >
                  Фильтры
                </Button>
              </div>
              <TaskListingFastFilters
                filter
                setParamsData={setParamsData}
                setCurrentPage={setCurrentPage}
                activeKey={activeKey}
                prevActiveKey={prevActiveKey}
                setFilterStatusLabel={setFilterStatusLabel}
              />
              <div className={css.borderLine} />
              <TasksListingTags
                filterData={filterData}
                deleteResponsibleFilterData={deleteResponsibleFilterData}
                deleteFilterData={deleteFilterData}
                FilterNames={FilterNames}
                clearFilterData={clearFilterData}
              />
            </div>
          ) : (
           <>
            <div className={css.blockInput}>
              <Form.Item name="organizationName" noStyle>
                <Input
                  id="organizationName"
                  className={css.input}
                  allowClear
                  suffix={<SearchIcon className={css.searchIcon} />}
                  placeholder="Наименование клиента, ИНН"
                  onChange={handleInputChange}
                />
              </Form.Item>
              <Button
                className={css.button}
                onClick={showModal}
              >
                Фильтры
              </Button>
            </div>
            <TaskListingFastFilters
              filter={false}
              setParamsData={setParamsData}
              setCurrentPage={setCurrentPage}
              activeKey={activeKey}
              prevActiveKey={prevActiveKey}
              setFilterStatusLabel={setFilterStatusLabel}
            />
           </>
          )
        }
        <Table
          className={css.table}
          loading={isLoadingData}
          columns={columns}
          dataSource={tasksData?.length ? tasksData : []}
          pagination={false}
          locale={{ emptyText: zeroTasks() }}
        />
        {tasksData?.length ? (
          <PaginationCustom
            total={totalDocs ? totalDocs : 0}
            currentPage={currentPage}
            defaultPageSize={tasksPageSize}
            pageSize={pageSize}
            defaultCurrent={tasksPageSize}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        ) : (
          <div className={css.noTasks}/>
        )}
      </div>
    </Form>
  );
};

export default TasksListing;
