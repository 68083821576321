import React, { FC, JSX } from "react";
import css from "./DraggerForm.module.css";
import { ButtonCustom } from "../../../components/ui-kit/ButtonCustom/ButtonCustom";
import { Upload, UploadProps } from "antd";

interface IDraggerFormProps {
  props: UploadProps;
  fileCount: number;
  counterUpload: (fileCount: number) => boolean
}

const DraggerForm: FC<IDraggerFormProps> = ({
  props,
  fileCount,
  counterUpload
}): JSX.Element => {
  const { Dragger } = Upload;
  
  return (
    <Dragger
      {...props}
      disabled={counterUpload(fileCount)}
    >
      <>
        <div className={css.uploadText}>
          Переместите сюда или выберите не более 100 файлов формата <br/>
          PDF, SVG, JPEG, PNG, XML, EXCEL, TXT, WORD, ZIP, RAR, 7Z, TAR, CSV
        </div>
        <div className={`${css.uploadFooterText} mt-2`}>
          Объём каждого файла не должен превышать 50 мб.
        </div>
        <ButtonCustom
          className={`${css.uploadButton} mt-3`}
          type="default"
          text="Выбрать файлы"
        />
      </>
    </Dragger>
  );
};

export default DraggerForm;