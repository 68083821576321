import React, { FC, JSX, useEffect, useRef, useState } from "react";
import css from "./TaskCardInfo.module.css";
import { Card, Spin, Steps, Tooltip } from "antd";
import DeadLine from "../../../../utils/DeadLine";
import { ActionHistoryFileType, TaskStepsType } from "app/types";
import { downloadFile } from "../../../../utils/downloadFile";
import ActionHistory from "../ActionHistory/ActionHistory";
import ZeroHistory from "../ZeroHistory/ZeroHistory";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { ReactComponent as FirstStep } from "../../../../assets/icons/step_first.svg";
import { ReactComponent as FirstStepActive } from "../../../../assets/icons/step_first_active.svg";
import { ReactComponent as SecondStep } from "../../../../assets/icons/step_second.svg";
import { ReactComponent as SecondStepActive } from "../../../../assets/icons/step_second_active.svg";
import { ReactComponent as ThirdStep } from "../../../../assets/icons/step_third.svg";
import { ReactComponent as ThirdStepActive } from "../../../../assets/icons/step_third_active.svg";
import { ReactComponent as FourthStep } from "../../../../assets/icons/step_fourth.svg";
import { ReactComponent as FourthStepActive } from "../../../../assets/icons/step_fourth_active.svg";
import { ReactComponent as FifthStep } from "../../../../assets/icons/step_fifth.svg";
import { ReactComponent as FifthStepActive } from "../../../../assets/icons/step_fifth_active.svg";
import { ReactComponent as SixthStep } from "../../../../assets/icons/step_sixth.svg";
import { ReactComponent as SixthStepActive } from "../../../../assets/icons/step_sixth_active.svg";
import { ReactComponent as ErrorIcon } from "../../../../assets/icons/status_error_v2.svg";
import { ReactComponent as FinishedStep } from "../../../../assets/icons/status-checked.svg";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help.svg";
import { NavigateFunction, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { reformatName } from "../../../../utils/reformatName";

interface ITaskCardInfoProps {
  currentStep: number | undefined;
  isAdministrator: boolean | undefined;
  setNavBarHidden: React.Dispatch<React.SetStateAction<boolean>>;
  isCheckAccess: boolean;
  isHistoryStepsBlockLoading: boolean;
}

enum StepValue {
  Step4 = 4,
  Step5 = 5,
  Step6 = 6,
}

const TaskCardInfo: FC<ITaskCardInfoProps> = ({
  currentStep,
  isAdministrator,
  setNavBarHidden,
  isCheckAccess,
  isHistoryStepsBlockLoading,
}): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const refTaskInfo: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refTaskContent: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const refClientInfo: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const navigate: NavigateFunction = useNavigate();

  const {
    statusTask,
    documentTask,
    actionHistory,
    schemaTaskData,
    actionHistoryStatus,
  } = useSelector((state: AppStateType) => state.tasks);

  const {
    schemaClient,
    clientTariffData,
  } = useSelector((state: AppStateType) => state.client);

  const haveActionHistory: boolean = actionHistory ? actionHistory?.results?.length > 0 : false;

  const taskLabels = {
    responsibleReference: schemaTaskData?.fields?.find(i => i.name === "responsible_reference"),
    status: schemaTaskData?.fields?.find(i => i.name === "status"),
    client: schemaTaskData?.fields?.find(i => i.name === "client"),
    client_profile: schemaTaskData?.fields?.find(i => i.name === "client_profile"),
    taskType: schemaTaskData?.fields?.find(i => i.name === "task_type"),
    tariff: schemaTaskData?.fields?.find(i => i.name === "tariff"),
    deadline: schemaTaskData?.fields?.find(i => i.name === "deadline"),
    author: schemaTaskData?.fields?.find(i => i.name === "author"),
    uuid: schemaTaskData?.fields?.find(i => i.name === "uuid"),
    task_content: schemaTaskData?.fields?.find(i => i.name === "task_content"),
    task_author: schemaTaskData?.fields?.find(i => i.name === "task_author"),
    task_reason: schemaTaskData?.fields?.find(i => i.name === "task_reason"),
    //моковый лейбл, его нет и возможно не будет (поэтому пока оставил моковые данные)
    attachments_files: schemaTaskData?.fields?.find(i => i.name === "attachments_files"),
    support_code: schemaTaskData?.fields?.find(i => i.name === "support_code"),
    topic: schemaTaskData?.fields?.find(i => i.name === "topic"),
    support_topic: schemaTaskData?.fields?.find(i => i.name === "support_topic"),
  };

  const clientLabel = {
    opf: schemaClient?.fields?.find(i => i.name === "opf"),
    inn: schemaClient?.fields?.find(i => i.name === "inn"),
    kpp: schemaClient?.fields?.find(i => i.name === "kpp"),
    name: schemaClient?.fields?.find(i => i.name === "name"),
    address: schemaClient?.fields?.find(i => i.name === "address"),
    tax_system: schemaClient?.fields?.find(i => i.name === "tax_system"),
    company_ogrn: schemaClient?.fields?.find(i => i.name === "company_ogrn"),
    primary_okved: schemaClient?.fields?.find(i => i.name === "primary_okved"),
    client_tariff: schemaClient?.fields?.find(i => i.name === "client_tariff"),
    secondary_okved: schemaClient?.fields?.find(i => i.name === "secondary_okved"),
    service_expired: schemaClient?.fields?.find(i => i.name === "service_expired"),
    online_desk_qty: schemaClient?.fields?.find(i => i.name === "online_desk_qty"),
    paid_consultations: schemaClient?.fields?.find(i => i.name === "paid_consultations"),
    service_life_months: schemaClient?.fields?.find(i => i.name === "service_life_months"),
    responsible_reference: schemaClient?.fields?.find(i => i.name === "responsible_reference"),
    agency_agreements_qty: schemaClient?.fields?.find(i => i.name === "agency_agreements_qty"),
    accounting_restore_months: schemaClient?.fields?.find(i => i.name === "accounting_restore_months")
  };

  const attachmentsFiles: boolean | undefined = documentTask?.fields?.files && documentTask?.fields?.files?.length > 0;
  const taskType: string | undefined = documentTask?.fields?.task_type?.[0]?.label;
  const isTaskTerminationService: boolean = taskType === "Прекращение обслуживания";

  const mainFiles: boolean | undefined =
    documentTask?.fields?.main_files && documentTask?.fields?.main_files?.length > 0;

  const cashTransactionsTask: boolean = documentTask?.fields?.task_type?.[0]?.label === "Наличные операции";

  const deadlineStyle = (data: string | undefined): string | null => {
    if (data) {
      const targetDate: Date = new Date(data);
      const now: Date = new Date();

      if (targetDate > now) {
        const delta: number = targetDate.getTime() - now.getTime();
        const millisecondsPerDay: number = 24 * 60 * 60 * 1000;
        const remainingDays: number = Math.round(delta / millisecondsPerDay);

        return remainingDays.toString();
      } else {
        return null;
      }
    }
    return null;
  };

  const contentTooltip: JSX.Element = (
    <div className={css.tooltip}>
      {!!clientTariffData?.name &&
        <div className="mb-3">{clientLabel?.client_tariff?.label}:{" "}{clientTariffData?.name}</div>
      }
      {!!clientTariffData?.online_desk_qty &&
        <div>{clientLabel?.online_desk_qty?.label}:{" "}{clientTariffData?.online_desk_qty}</div>
      }
      {!!clientTariffData?.agency_contracts_qty &&
        <div>{clientLabel?.agency_agreements_qty?.label}:{" "}{clientTariffData?.agency_contracts_qty}</div>
      }
      {!!clientTariffData?.account_recovery_months &&
        <div>
          {clientLabel?.accounting_restore_months?.label}:{" "}{clientTariffData?.account_recovery_months} мес
        </div>
      }
    </div>
  );

  const renderClientLabel: string | undefined = documentTask?.fields?.client_profile_ooo?.length
    ? documentTask?.fields?.client_profile_ooo?.[0]?.label
    : documentTask?.fields?.client_profile_ip?.[0]?.label;

  const navigateToQuestionnaireCard = (): void => {
    const correctClientProfile = documentTask?.fields.client_profile_ooo?.length
      ? documentTask?.fields.client_profile_ooo?.[0]?.uuid
      : documentTask?.fields.client_profile_ip?.[0]?.uuid;

    setNavBarHidden(true);
    navigate(`/task/${documentTask?.fields?.uuid}/questionnaire/${correctClientProfile}/`);
  };

  const firstStepIcon = (status?: string): JSX.Element => {
    switch (status) {
      case ("in_progress"):
        return <FirstStepActive/>;
      case ("passed"):
        return <FinishedStep/>;
      case ("error"):
        return <ErrorIcon/>;
      default:
        return <FirstStep/>;
    }
  };

  const secondStepIcon = (status?: string): JSX.Element => {
    switch (status) {
      case ("in_progress"):
        return <SecondStepActive/>;
      case ("passed"):
        return <FinishedStep/>;
      case ("error"):
        return <ErrorIcon/>;
      default:
        return <SecondStep/>;
    }
  };

  const thirdStepIcon = (status?: string): JSX.Element => {
    switch (status) {
      case ("in_progress"):
        return <ThirdStepActive/>;
      case ("passed"):
        return <FinishedStep/>;
      case ("error"):
        return <ErrorIcon/>;
      default:
        return <ThirdStep/>;
    }
  };

  const fourthStepIcon = (status?: string): JSX.Element => {
    switch (status) {
      case ("in_progress"):
        return <FourthStepActive/>;
      case ("passed"):
        return <FinishedStep/>;
      case ("error"):
        return <ErrorIcon/>;
      default:
        return <FourthStep/>;
    }
  };

  const fifthStepIcon = (statusOne?: string, statusTwo?: string, statusThree?: string): JSX.Element => {
    switch (true) {
      case (statusOne === "in_progress" || statusTwo === "in_progress" || statusThree === "in_progress"):
        return <FifthStepActive/>;
      case (statusOne === "passed" && statusTwo === "skipped" && statusThree === "passed"):
        return <FinishedStep/>;
      case (statusOne === "error" || statusTwo === "error" || statusThree === "error"):
        return <ErrorIcon/>;
      default:
        return <FifthStep/>;
    }
  };

  const sixthStepIcon = (status?: string): JSX.Element => {
    switch (status) {
      case ("in_progress"):
        return <SixthStepActive/>;
      case ("passed"):
        return <FinishedStep/>;
      case ("error"):
        return <ErrorIcon/>;
      default:
        return <SixthStep/>;
    }
  };

  const fifthStepError: boolean = statusTask?.statuses?.[4]?.status === "error"
    || statusTask?.statuses?.[5]?.status === "error"
    || statusTask?.statuses?.[6]?.status === "error";

  const questionnaireSteps: TaskStepsType[] = [
    {
      icon: firstStepIcon(statusTask?.statuses?.[0]?.status),
      title: (
        <span className={css.taskStepDescr}>
          {statusTask?.statuses?.[0]?.label || ""}
        </span>
      ),
      status: statusTask?.statuses?.[0]?.status === "error" ? "error" : undefined
    },
    {
      icon: secondStepIcon(statusTask?.statuses?.[1]?.status),
      title: (
        <span className={css.taskStepDescr}>
          {statusTask?.statuses?.[1]?.label || ""}
        </span>
      ),
      status: statusTask?.statuses?.[1]?.status === "error" ? "error" : undefined
    },
    {
      icon: thirdStepIcon(statusTask?.statuses?.[2]?.status),
      title: (
        <span className={css.taskStepDescr}>
          {statusTask?.statuses?.[2]?.label || ""}
        </span>
      ),
      status: statusTask?.statuses?.[2]?.status === "error" ? "error" : undefined
    },
    {
      icon: fourthStepIcon(statusTask?.statuses?.[3]?.status),
      title: (
        <span className={css.taskStepDescr}>
          {statusTask?.statuses?.[3]?.label || ""}
        </span>
      ),
      status: statusTask?.statuses?.[3]?.status === "error" ? "error" : undefined
    },
    {
      icon: fifthStepIcon(
        statusTask?.statuses?.[4]?.status,
        statusTask?.statuses?.[5]?.status,
        statusTask?.statuses?.[6]?.status,
      ),
      title: (fifthStepError ? (
          <div className="flex flex-col mt-2">
            <span className={css.taskStepDescr}>
              {statusTask?.statuses?.[4]?.label || ""}
            </span>
            <span className={css.fifthStepError}>
              Ошибка создания карточки. Пожалуйста, проверьте внесение данных.
            </span>
          </div>
        ) : (
          <span className={css.taskStepDescr}>
            {statusTask?.statuses?.[4]?.label || ""}
          </span>
        )
      ),
      status: fifthStepError ? "error" : undefined
    },
    {
      icon: sixthStepIcon(statusTask?.statuses?.[statusTask?.statuses?.length - 1]?.status),
      title: (
        <span className={css.taskStepDescr}>
          {statusTask?.statuses?.[statusTask?.statuses?.length - 1]?.label || ""}
        </span>
      ),
      status: statusTask?.statuses?.[statusTask?.statuses?.length - 1]?.status === "error" ? "error" : undefined
    }
  ];

  const isFinalSteps: boolean = currentStep === StepValue.Step5 || currentStep === StepValue.Step6;

  const isTaskContentHidden: boolean = !!documentTask?.fields?.topic ||
    !!mainFiles ||
    !!attachmentsFiles ||
    !!documentTask?.fields?.task_content;

  const actionHistoryHeight: number = (refTaskInfo?.current?.clientHeight ?? 0) +
    (isTaskContentHidden ? (refTaskContent?.current?.clientHeight ?? 0) : -12) +
    (refClientInfo?.current?.clientHeight ?? 0);

  const finalizeTaskOneC = (): void => {
    const correctClientProfile: string | undefined = documentTask?.fields.client_profile_ooo?.length
      ? documentTask?.fields.client_profile_ooo?.[0]?.uuid
      : documentTask?.fields.client_profile_ip?.[0]?.uuid;

    navigate(`/task/${documentTask?.fields?.uuid}/questionnaire/${correctClientProfile}/validation`);
  };

  const renderActionHistory = (): JSX.Element => {
    return haveActionHistory ? (
      <ActionHistory
        actionHistory={actionHistory ?? null}
        heightHistory={actionHistoryHeight}
        widthHistory={refTaskContent?.current?.clientWidth}
      />
    ) : <ZeroHistory heightHistory={actionHistoryHeight} />;
  };

  const isAdministratorHistory: boolean = !!isAdministrator && !!actionHistoryStatus;
  
  const renderTaskContent = (label: string | undefined, text: string | undefined): JSX.Element => (
    <div className="flex">
      <div className={css.taskInfoLabel}>{label}</div>
      <div id={`id-${label}`} className={css.taskInfoValue}>
        {text ?? "-"}
      </div>
    </div>
  );

  return (
    <div className={css.cardsContent}>
      <div className={css.card}>
        <Card className="mb-[12px]">
          <div className={css.cardInfo} ref={refTaskInfo}>
            <div>
              <div>
                <div className={css.cardInfoBlock}>
                  <span className={css.taskInfoHeader}>
                    О задаче
                  </span>
                  <div className="flex min-w-[100%]">
                    <div className={css.taskInfoLabel}>{taskLabels?.taskType?.label}</div>
                    <div className={css.taskInfoValue}>{documentTask?.fields?.task_type?.[0]?.label ?? "-"}</div>
                  </div>
                  <div className="flex min-w-[100%]">
                    <div className={css.taskInfoLabel}>Дата создания</div>
                    <div className={`${css.taskInfoValue} whitespace-nowrap`}>
                      {`
                       ${dayjs(documentTask?.created_at)?.format("DD.MM.YY")} 
                       ${dayjs(documentTask?.created_at)?.format("HH:mm")}
                    `}
                    </div>
                  </div>
                  <div className="flex min-w-[100%]">
                    <div className={css.taskInfoLabel}>{taskLabels?.deadline?.label}</div>
                    {isAdministrator ? (
                      <div className={
                        deadlineStyle(documentTask?.fields?.deadline) === "1"
                          ? css.dateOneDay
                          : deadlineStyle(documentTask?.fields?.deadline) === "2"
                            ? css.dateTwoDays
                            : css.cardInfoData
                      }>
                        {DeadLine(documentTask?.fields?.deadline) ?? "-"}
                      </div>
                    ) : (
                      <div className={css.taskInfoValue}>
                        {DeadLine(documentTask?.fields?.deadline) ?? "-"}
                      </div>
                    )}
                  </div>
                  <div className="flex min-w-[100%]">
                    <div className={css.taskInfoLabel}>{taskLabels?.responsibleReference?.label}</div>
                    <div className={css.taskInfoValue}>
                      {reformatName(documentTask?.fields?.responsible_reference?.[0]?.label ?? "-")}
                    </div>
                  </div>
                  {isTaskTerminationService && (
                    <>
                      <div className="flex min-w-[100%]">
                        <div className={css.taskInfoLabel}>{taskLabels?.task_author?.label}</div>
                        <div className={css.taskInfoValue}>{documentTask?.fields?.task_author?.[0]?.label ?? "-"}</div>
                      </div>
                      <div className="flex min-w-[100%]">
                        <div className={css.taskInfoLabel}>{taskLabels?.task_reason?.label}</div>
                        <div className={css.taskInfoValue}>{documentTask?.fields?.task_reason ?? "-"}</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
        {isTaskContentHidden && (
          <Card ref={refTaskContent}>
            <span className={css.taskInfoHeader}>
              Содержание задачи
            </span>
            <div>
              <div>
                {documentTask?.fields?.task_type?.[0]?.label === "Обращение" && (
                  <div>
                    {renderTaskContent(taskLabels?.support_code?.label, documentTask?.fields?.support_code)}
                    {renderTaskContent(taskLabels?.support_topic?.label, documentTask?.fields?.support_topic)}
                  </div>
                )}
                {documentTask?.fields?.topic ? (
                  renderTaskContent(taskLabels?.topic?.label, documentTask?.fields?.topic)
                ) : null}
                <div>
                  {(!!mainFiles && cashTransactionsTask) && (
                    <div className="flex">
                      <div className={css.taskInfoLabel}>Основные документы</div>
                      <div className={css.taskInfoValue}>
                        {documentTask?.fields?.main_files?.map((
                          doc: ActionHistoryFileType,
                          index: number
                        ): JSX.Element => (
                          <a
                            key={index}
                            className="flex blue-color"
                            onClick={(): void => {
                              if (isAdministrator) {
                                downloadFile(doc?.label, doc?.url);
                              }
                            }}
                          >
                            <AttachFileIcon className="flex-shrink-0 mr-1" />
                            <div className={css.docFile}>
                              {doc?.label}
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                  {!!attachmentsFiles && (
                    <div className="flex">
                      <div className={css.taskInfoLabel}>Вложенные файлы</div>
                      <div className={css.taskInfoValue}>
                        {documentTask?.fields?.files?.map((doc: ActionHistoryFileType, index: number): JSX.Element => (
                          <a
                            key={index}
                            className="flex blue-color"
                            onClick={(): void => {
                              if (isAdministrator) {
                                downloadFile(doc?.label, doc?.url);
                              }
                            }}
                          >
                            <AttachFileIcon className="flex-shrink-0 mr-1" />
                            <div className={css.docFile}>
                              {doc?.label}
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                  {isAdministrator ? (
                    !!documentTask?.fields?.task_content && (
                      renderTaskContent(taskLabels?.task_content?.label, documentTask?.fields?.task_content)
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </Card>
        )}
        <Card className="mt-3" ref={refClientInfo}>
          <span className={css.taskInfoHeader}>
            Клиент
          </span>
          <div>
            <div>
              {isAdministrator ? (
                <>
                  <div className="flex">
                    <div className={css.taskInfoLabel}>Организация</div>
                    <a
                      className={css.taskInfoValue}
                      href={`/task/${documentTask?.fields?.uuid}/clients/${documentTask?.fields?.client?.[0]?.uuid}`}
                    >
                      {documentTask?.fields?.client?.[0]?.label ?? "-"}
                    </a>
                  </div>
                </>
              ) : (
                <div className="flex">
                  <div className={css.taskInfoLabel}>Организация</div>
                  <div className={css.taskInfoValue}>{renderClientLabel ?? "-"}</div>
                </div>
              )}
              {isAdministrator ? (
                <div>
                  <div className="flex">
                    <div className={css.taskInfoLabel}>{clientLabel?.inn?.label}{" "}клиента</div>
                    <div className={css.taskInfoValue}>{documentTask?.fields?.inn ?? "-"}</div>
                  </div>
                  <div className="flex">
                    <div
                      className={`${css.taskInfoLabel} flex items-center`}
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                    >
                      Срок обслуживания
                      <Tooltip
                        open={tooltipOpen}
                        placement="topLeft"
                        title={contentTooltip}
                        trigger="hover"
                        arrow={{ pointAtCenter: true }}
                      >
                        <HelpIcon className="ml-1" />
                      </Tooltip>
                    </div>
                    <div className={css.taskInfoValue}>
                      {DeadLine(clientTariffData?.finished_at) ?? "-"}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </Card>
      </div>
      <Card className={css.card}>
        <Spin
          spinning={isHistoryStepsBlockLoading}
          className="min-h-full"
          style={isAdministrator ? { height: actionHistoryHeight } : {}}
        >
          <span className={css.taskInfoHeader}>
            {isAdministrator ? "История действий" : "Проверка анкеты"}
          </span>
          {isAdministratorHistory && renderActionHistory()}
          {!isAdministrator && (
            <div className={css.taskStepsHeader}>
              <div className="text-base font-normal leading-5 mb-4">
                Этапы проверки анкеты
              </div>
              <Steps
                className="mb-5"
                direction="vertical"
                current={isFinalSteps ? StepValue.Step4 : currentStep}
                items={questionnaireSteps}
              />
              <div key="openForm" className={css.primaryButton}>
                <ButtonCustom
                  id="openButton"
                  className={css.button}
                  type="primary"
                  text="Открыть анкету"
                  htmlType="button"
                  onClick={navigateToQuestionnaireCard}
                  disabled={!isCheckAccess || isHistoryStepsBlockLoading}
                />
                {fifthStepError && (
                  <ButtonCustom
                    type="primary"
                    danger
                    className={isCheckAccess ? css.buttonErrorOneC : css.buttonDisable}
                    text="Проверить"
                    onClick={finalizeTaskOneC}
                    disabled={!isCheckAccess || isHistoryStepsBlockLoading}
                  />
                )}
              </div>
            </div>
          )}
        </Spin>
      </Card>
    </div>
  );
};

export default TaskCardInfo;
