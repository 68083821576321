import { TasksListingDataType } from "app/types";
import React, { JSX } from "react";
import css from "../components/Tasks/Tasks.module.css";
import { Link } from "react-router-dom";
import TagCustom from ".././components/ui-kit/TagCustom/TagCustom";

export const statusIcon = (text: string, record: TasksListingDataType, clientUuid?: string): JSX.Element | string => {
  switch (text) {
    case ("NEW"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customProcessing" text="Новая"/>
        </Link>
      );
    case ("SEARCH_RESPONSIBLE"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customProcessing" text="Поиск исполнителя"/>
        </Link>
      );
    case ("BOOKED"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customProcessing" text="Назначена"/>
        </Link>
      );
    case ("IN_PROGRESS"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customWarning" text="В работе"/>
        </Link>
      );
    case ("PAUSE"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customWarning" text="Приостановлена"/>
        </Link>
      );
    case ("FIN_TRUE"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customSuccess" text="Исполнена"/>
        </Link>
      );
    case ("FIN_FALSE"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customError" text="Не исполнена"/>
        </Link>
      );
    case ("1C_SYNC"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customWarning" text="Синхронизация с 1С"/>
        </Link>
      );
    case ("1C_SUCCESS"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customWarning" text="Отправлено в 1С"/>
        </Link>
      );
    case ("1C_ERROR"):
      return (
        <Link
          className={css.tableCellStatus}
          to={clientUuid ? `/clients/${clientUuid}/task/${record?.fields?.uuid}` : `/task/${record?.fields?.uuid}`}
        >
          <TagCustom color="customError" text="Ошибка синхронизации с 1С"/>
        </Link>
      );
    default:
      return "";
  }
};
