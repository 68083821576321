import React, {
  FC,
  JSX,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import css from "./ActionHistory.module.css";
import { ReactComponent as AttachFileIcon } from "../../../../assets/icons/attach_file_icon.svg";
import {
  ActionHistoryFileType,
  ActionHistoryParamsType,
  ActionHistoryResultsType,
  ActionHistoryType,
} from "app/types";
import { downloadFile } from "../../../../utils/downloadFile";
import { ConfigProvider, Timeline } from "antd";
import ActionHistoryComment from "./ActionHistoryItem/ActionHistoryComment";
import dayjs from "dayjs";
import { reformatName } from "../../../../utils/reformatName";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { getActionHistoryListing } from "../../../../actions/tasks.actions";
import { useLocation, useParams } from "react-router-dom";
import debounce from "debounce";

interface IActionHistoryProps {
  actionHistory: ActionHistoryType | null;
  heightHistory: number;
  widthHistory: number | undefined;
}

enum ActionHistoryPage {
  firstPage = 1,
}

const ActionHistory: FC<IActionHistoryProps> = ({
  actionHistory,
  heightHistory,
  widthHistory
}): JSX.Element => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch<AppDispatch>();
  const paramsData: any = useParams();
  const path: string = useLocation().pathname;
  const params = useMemo(() => paramsData, [path]);
  
  const [actionHistoryData, setActionHistoryData] = useState<ActionHistoryResultsType[]>([]);
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  
  const isFirstPage: boolean = actionHistory?.page === ActionHistoryPage.firstPage;
  
  const updateActionHistory = (): void => {
    setActionHistoryData((prevData: ActionHistoryResultsType[]): ActionHistoryResultsType[] => {
      if(isFirstPage) {
        return actionHistory?.results || [];
      }
      
      const newData: ActionHistoryResultsType[] = actionHistory?.results?.filter(
        (item: ActionHistoryResultsType) =>
          !prevData?.some((prevItem: ActionHistoryResultsType): boolean => prevItem?.id === item?.id)
      ) || [];
      
      return [...prevData, ...newData];
    });
  };
  
  useEffect(() => {
    updateActionHistory();
  }, [actionHistory]);
  
  useEffect(() => {
    const container: HTMLDivElement | null = containerRef.current;

    if (isFirstPage && container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [actionHistory?.page, actionHistoryData]);
  
  const handleScroll: debounce.DebouncedFunction<() => void> = debounce((): void => {
    if (containerRef.current && !isLoadingData) {
      const hasDocuments: boolean = (actionHistory?.total_docs ?? 0) > actionHistoryData?.length;
      const isScrollOnTop: boolean = containerRef.current.scrollTop < 100;
      
      if (isScrollOnTop && hasDocuments) {
        setLoadingData(true);
        
        const paramsData: ActionHistoryParamsType = {
          "task.uuid": params.id,
          page: (actionHistory?.page ?? 0) + 1,
        };
        
        dispatch(getActionHistoryListing(paramsData))
          .finally(() => {
            setLoadingData(false);
          });
      }
    }
  }, 300);
  
  useEffect(() => {
    const container: HTMLDivElement | null = containerRef.current;
    
    if (!container) {
      return;
    }
    
    container.addEventListener("scroll", handleScroll);
    
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef, actionHistory, actionHistoryData]);
  
  const decisionKNAP = (status: string | undefined): string => {
    switch (status) {
      case "pending":
      case "inProgress":
        return "Сформирован запрос на распознавание документов в КНАП. Ожидание результата";
      case "processed":
        return "Распознавание файлов прошло успешно";
      case "needAttention":
        return "При обработке файлов произошла ошибка. Требуется личное внимание, " +
          "пожалуйста, перейдите в Кабинет КНАП.";
      case "error":
        return "При обработке файлов произошла ошибка";
      default:
        return "";
    }
  };
  
  const taskDecision = (text: string, status: string | undefined): string => {
    switch (text) {
      case "manual_decision":
        return "Обработка данных вручную";
      case "auto_decision":
        return "Обработано автоматически";
      case "change_responsible":
        return "Смена ответственного по задаче на пользователя";
      case "change_task_topic":
        return "Выбрана тема обращения";
      case "reassign_task":
        return "Задача переназначена на пользователя";
      case "cancel_task":
        return "Задача отменена";
      case "clarification_decision":
        return "Запрос уточнения у клиента";
      case "knap_decision":
        return decisionKNAP(status);
      default:
        return "";
    }
  };
  
  const renderTimelineItem = (action: ActionHistoryResultsType, index: number): JSX.Element => {
    const {
      task_decision_type,
      decision_maker,
      new_responsible_reference,
    } = action?.fields || {};
    
    const showResponsibleReference: boolean = ["change_responsible", "reassign_task"].includes(task_decision_type);
    const statusKNAP: string | undefined = action.fields.files?.[0]?.knap_package_status;
    const isInProgress: boolean = statusKNAP === "inProgress";
    
    const isSystemResponse: boolean = decision_maker === null
      && task_decision_type === "system_message";
    
    const isKNAPResponse: boolean = decision_maker === null
     && task_decision_type === "knap_decision";
    
    const renderName: JSX.Element = (isSystemResponse || isKNAPResponse) ? (
      <div>{isKNAPResponse ? "Система КНАП" : "Система"}</div>
    ) : (
      <div>{reformatName((decision_maker?.[0]?.label ?? ""), false)}</div>
    );
    
    const renderTaskDecision: JSX.Element = showResponsibleReference ? (
      <div>
        {taskDecision(task_decision_type, statusKNAP)}
        {" "}
        {reformatName(new_responsible_reference?.[0]?.label ?? "")}
      </div>
    ) : (
      <div>
        {taskDecision(task_decision_type, statusKNAP)}
      </div>
    );
    
    const renderFileStatus = (status: string | undefined): string => {
      switch (status) {
        case "processed":
          return "green";
        case "needAttention":
        case "error":
          return "red";
        default:
          return "blue";
      }
    };
    
    const renderFiles = (action: ActionHistoryResultsType): JSX.Element => (
      <>
        {action?.fields?.files?.map((doc: ActionHistoryFileType, index: number): JSX.Element => (
          <a
            key={index}
            className="flex blue-color items-center mb-1"
            onClick={() => downloadFile(doc?.label, doc?.url)}
          >
            <AttachFileIcon className="flex-shrink-0 mr-1" />
            <div className={css.docFile}>{doc?.label}</div>
          </a>
        ))}
      </>
    );
    
    return (
      <Timeline.Item
        key={index}
        color={renderFileStatus(statusKNAP)}
        dot={isInProgress ? <LoadingCustom fontSize={10} /> : null}
      >
        <div className={css.historyHead}>
          <div>{dayjs(action.created_at)?.format("DD.MM.YYYY HH:mm")}</div>
          {renderName}
        </div>
        <div className={css.historyText}>
          {renderTaskDecision}
          <ActionHistoryComment action={action} widthHistory={widthHistory}/>
        </div>
        <div className="mt-3">
          {renderFiles(action)}
        </div>
      </Timeline.Item>
    );
  };

  return (
    <ConfigProvider>
      {actionHistory ? (
        <div
          className={css.container}
          ref={containerRef}
          style={{height: `${heightHistory - 12}px`}}
        >
          {isLoadingData && (
            <div className="text-center mt-2 pb-10">
              <LoadingCustom fontSize={25} />
            </div>
          )}
          <Timeline reverse>
            {actionHistoryData?.map((action: ActionHistoryResultsType, index: number): JSX.Element => (
              renderTimelineItem(action, index)
            ))}
          </Timeline>
        </div>
      ) : <LoadingCustom />}
    </ConfigProvider>
  );
};

export default ActionHistory;
