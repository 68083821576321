import React, {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { Modal, Select } from "antd";
import { ButtonCustom } from "../../components/ui-kit/ButtonCustom/ButtonCustom";
import {
  FilterTasksType,
  UserListingType,
  DocumentTaskType,
  DocumentClientType,
  AllUsersListingType,
  UsersListingFilterType,
  DocumentClientProfileType,
  SearchResponsibleEmployeeType,
  DocumentClientFieldsUpdateType,
  DocumentClientProfileFieldsType,
  TypicalTaskDecisionType
} from "app/types";
import { updateDocumentData } from "../../actions/document.actions";
import { getDocumentTaskFailure, getTasksData, sendTypicalTaskDecision } from "../../actions/tasks.actions";
import { getDocumentClientProfileFailure } from "../../actions/clientProfile.actions";
import css from "./ChangeResponsibleModal.module.css";
import ModalDraggable from "../ModalDraggable/ModalDraggable";
import { handleOut, handleOver } from "../ModalDraggable/DraggableSwitch";
import debounce from "debounce";
import { AxiosResponse } from "axios";
import { getUsersListing } from "../../api/account.api";
import qs from "qs";

interface IClientModalProps {
  docSchema: string;
  taskInfo?: DocumentTaskType | null;
  paramsData?: FilterTasksType | null;
  clientInfo?: DocumentClientType | null;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  clientProfileInfo?: DocumentClientProfileType | null;
  responsibleUuid: string | null;
}

const debouncedGetUsersListing: debounce.DebouncedFunction<(
  params: string,
  onSuccess: (value: AxiosResponse<AllUsersListingType>) => void,
  onError: any,
) => void> = debounce((params, onSuccess, onError): void => {
  getUsersListing(params)
  .then(onSuccess)
  .catch(onError);
}, 1000);

const ChangeResponsibleModal: FC<IClientModalProps> = ({
  docSchema,
  taskInfo,
  paramsData,
  clientInfo,
  isModalOpen,
  setIsModalOpen,
  clientProfileInfo,
  responsibleUuid
}): JSX.Element => {
  const [searchEmployeeValue, setSearchEmployeeValue] = useState<string>("");
  const [
    employeesForChangeResponsibleOptions,
    setEmployeesForChangeResponsibleOptions
  ] = useState<SearchResponsibleEmployeeType[]>([]);
  const [
    selectedNewResponsibleEmployee,
    setSelectedNewResponsibleEmployee
  ] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [responsibleUserList, setResponsibleUserList] = useState<UserListingType[]>([]);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (isModalOpen) {
      setIsUsersLoading(true);

      const params: UsersListingFilterType = {
        page_size: 100,
        roles: userRoles(),
        ...(searchEmployeeValue ? { name: searchEmployeeValue } : {}),
      };

      const queryString: string = qs.stringify(params, { arrayFormat: "repeat" });

      debouncedGetUsersListing(queryString,
        (response: any): void => {
          setResponsibleUserList(response.data.results as UserListingType[]);
          setIsUsersLoading(false);
        },
        (err: any): void => {
          console.error("Get users error:", err);
          setIsUsersLoading(false);
        },
      );

      return (): void => debouncedGetUsersListing.clear();
    }

    return setResponsibleUserList([]);
  }, [isModalOpen, searchEmployeeValue, taskInfo, docSchema]);

  const userRoles = (): string[] => {
    const isTasksListingVariant: boolean = docSchema === "task";
    const taskTypeLabel: string = taskInfo?.fields?.task_type?.[0]?.label ?? "";

    if (isTasksListingVariant) {
      switch (taskTypeLabel) {
        case "Обращение" :
          return ["accountant-role", "assistant-role", "contact-center-role"];
        case "Повышение тарифа" :
        case "Прекращение обслуживания" :
          return ["accountant-role", "supervisor-role"];
        default:
          return ["accountant-role", "assistant-role"];
      }
    } else {
      return ["accountant-role"];
    }
  };

  const filteredAccountants: UserListingType[] =
    responsibleUserList?.filter((user: UserListingType) => responsibleUuid && user?.id !== responsibleUuid);

  const changeResponsibleTitle: JSX.Element = (
    <div
      className={css.changeResponsibleHeader}
      onMouseOver={() => handleOver(disabled, setDisabled)}
      onMouseOut={() => handleOut(setDisabled)}
    >
      Изменение исполнителя
    </div>
  );

  useEffect(() => {
    const employeesForChangeResponsibleOptions: SearchResponsibleEmployeeType[] = filteredAccountants?.map(({
        id,
        attributes,
      }) => (
        {
          value: id,
          label: (
            <span>
              {attributes?.position?.toString()}
              {" "}
              {attributes?.last_name?.toString()}
              {" "}
              {attributes?.first_name?.toString()}
              {" "}
              {attributes?.second_name?.toString()}
            </span>
          )
        }
      )
    );

    setEmployeesForChangeResponsibleOptions(employeesForChangeResponsibleOptions);
  }, [responsibleUserList]);

  const onCancelChangeResponsibleModal = (): void => {
    setIsModalOpen(false);
    setSearchEmployeeValue("");
    setSelectedNewResponsibleEmployee(null);
  };

  const onApplyChangeResponsibleModal = (): void => {
    onCancelChangeResponsibleModal();

    if (docSchema === "client") {
      const newTaskResponsible: DocumentClientFieldsUpdateType = {
        ...clientInfo?.fields,
        responsible_reference: selectedNewResponsibleEmployee,
      } as DocumentClientFieldsUpdateType;

      dispatch(updateDocumentData(docSchema, clientInfo?.fields?.uuid ?? "", newTaskResponsible));
    }

    if (docSchema === "task") {
      const newTaskResponsible: TypicalTaskDecisionType = {
        new_responsible_rule: "one",
        new_responsible_user_uuid: selectedNewResponsibleEmployee,
        task: taskInfo?.fields?.uuid,
        task_decision_type: "change_responsible",
      };

      const newClientResponsible: DocumentClientProfileFieldsType = {
        ...clientProfileInfo?.fields,
        responsible_reference: selectedNewResponsibleEmployee
      } as unknown as DocumentClientProfileFieldsType;

      if (isModalOpen) setTimeout(() => dispatch(getTasksData(paramsData)), 1000);

      if (taskInfo) {
        dispatch(sendTypicalTaskDecision(taskInfo?.schema_version, newTaskResponsible));
      }

      if (clientProfileInfo?.fields?.uuid) {
        dispatch(updateDocumentData(
          clientProfileInfo?.schema_name ?? "",
          clientProfileInfo.fields.uuid,
          newClientResponsible
        ));
        dispatch(getDocumentClientProfileFailure());
      }

      dispatch(getDocumentTaskFailure());
    }
  };

  const changeResponsibleModalFooter: JSX.Element = (
    <div key="modalBotton">
      <ButtonCustom
        className="mr-[8px]"
        ghost
        key="cancel"
        size="large"
        type="default"
        text="Отменить"
        onClick={onCancelChangeResponsibleModal}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={onApplyChangeResponsibleModal}
        disabled={!selectedNewResponsibleEmployee?.length}
      />
    </div>
  );

  const textVariant: string = docSchema === "task" ? "задачей" : "клиентом";

  return (
    <Modal
      title={changeResponsibleTitle}
      open={isModalOpen}
      centered
      closable={false}
      footer={changeResponsibleModalFooter}
      modalRender={(modal) => (
         <ModalDraggable disabled={disabled} modal={modal} />
      )}
    >
      <div>
        <p className={css.changeResponsibleDescription}>
          Вы можете изменить исполнителя, закрепленного за {textVariant}.&nbsp;
          Для этого воспользуйтесь поиском ниже, чтобы выбрать нужного сотрудника.
        </p>
        <Select
          id="employee"
          className={css.changeResponsibleSelect}
          placeholder="ФИО сотрудника"
          showSearch
          allowClear
          size="large"
          loading={isUsersLoading}
          value={selectedNewResponsibleEmployee}
          options={employeesForChangeResponsibleOptions}
          onSearch={setSearchEmployeeValue}
          searchValue={searchEmployeeValue}
          filterOption={false}
          onClear={() => setSearchEmployeeValue("")}
          onChange={setSelectedNewResponsibleEmployee}
        />
      </div>
    </Modal>
  );
};

export default ChangeResponsibleModal;
