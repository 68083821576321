import axios from "../utils/axios";
import {
  ActionHistoryParamsType,
  FilterTasksType,
  NewTaskDataType,
  ParamsGetFile1CType,
  ParamsGetUuid1CType,
  RepeatStepDataType,
  TypicalTaskDecisionType,
  TasksNotificationUpdateType
} from "app/types";

const API_TASKS: string | undefined = process.env.REACT_APP_API_TASKS_URL;
const prodDomain: string = "https://pipeline.smbconnect.ru";

const isProd: boolean = window.location.origin === prodDomain;
const url: string | undefined = isProd ? prodDomain : API_TASKS;

//ручка получения схемы задачи
export const getSchemaTask = async (): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/schemas/task");
};

//ручка получения задач и фильтрации задач
export const getTasks = async (params: null | FilterTasksType = null, signal?: AbortSignal): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/d/task", params, signal);
};

//ручка создания задачи
export const createTask = async (body: NewTaskDataType): Promise<any> => {
  return axios.POST(url, "api/mdm/documents/v1/d/task", body);
};

//ручка получения данных задачи по uuid документа
export const getDocumentTask = async(uuid?: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/task/uuid/${uuid}`);
};

//ручка получения данных обработки действия по задачи "уточнение"
export const getClarificationResult = async(uuid?: string): Promise<any> => {
  return axios.GET(url, `api/mdm/documents/v1/d/send-clarification-result/uuid/${uuid}`);
};

//ручка получения всей статусной модели с актуальными статусами
export const getTaskNotificationStatus = async(uuid?: string): Promise<any> => {
  return axios.GET(url, `api/mdm/task/v1/status/${uuid}`);
};

//ручка завершения задачи, при ошибке создания в 1С
export const errorFinalizeOneC = async (task_uuid?: string): Promise<any> => {
  return axios.GET(url, `api/mdm/task/v1/check-ones-status/${task_uuid}`);
};

//ручка отправки Принятия решения по типовой задаче
export const sendTaskDecision = async (
  schema_version: number,
  body?: TypicalTaskDecisionType | null
): Promise<any> => {
  return axios.POST(url, `api/mdm/documents/v1/d/task-decisions/${schema_version}`, body);
};

//ручка получения данных "история действия"
export const getActionHistory = async(params?: ActionHistoryParamsType | null): Promise<any> => {
  return axios.GET(url, "api/mdm/documents/v1/d/task-decisions", params);
};

//ручка получения файлов в обработке задачи Автоматически
export const getProcessTaskAutomaticallyFileData = async(task_uuid?: string): Promise<any> => {
  return axios.GET(url, `api/fin/bank_statements/v1/statement/parsing/result/${task_uuid}`);
};

//ручка фонового запроса проверки наличия готовых файлов в обработки задачи Автомачески в разметке операций
export const fetchMarkupTaskFiles = async(parsing_uuid?: string): Promise<any> => {
  return axios.GET(API_TASKS, `api/mdm/documents/v1/d/statement-parsing-result/uuid/${parsing_uuid}`);
};

//ручка получения uuid задачи из 1С
export const getTaskUuid1CData = async(body?: ParamsGetUuid1CType): Promise<any> => {
  return axios.POST(url, "api/mdm/storage/v1/get_task_uuid_from_1c", body);
};

//ручка получения файла из 1С при обработке задачи Автоматически
export const getTaskFile1C = async(body?: ParamsGetFile1CType): Promise<any> => {
  return axios.POST(url, "api/mdm/storage/v1/download_files_from_1c", body);
};

//ручка получения списка шагов задачи
export const getTaskStepsInfo = async(executionUuid: string): Promise<any> => {
  return axios.GET(url, `api/or/v1/execution/${executionUuid}/steps?short=False`);
};

//ручка перезапуска шага задачи
export const repeatExecutionStep = async(body: RepeatStepDataType): Promise<any> => {
  return axios.POST(url, "api/or/v1/task/repeat_step", body);
};

//ручка уведомления о прочтении задачи ответственным по задаче
export const updateTaskNotification = async(body: TasksNotificationUpdateType): Promise<any> => {
  return axios.PATCH(url, "api/mdm/v1/notifications/update", body);
};
