import React, {
  FC,
  JSX,
  Dispatch,
  useEffect,
  SetStateAction,
} from "react";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search_icon.svg";
import { Form, Input, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import { PaginationCustom } from "../../ui-kit/PaginationCustom/PaginationCustom";
import {
  FilterNamesType,
  UserListingType,
  AlphaListParamsType,
  FilterPerformersType,
} from "app/types";
import EmptyList from "../../ui-kit/EmptyList/EmptyList";
import css from "./PerformarsListing.module.css";

interface IPerformersListingProps {
  columns: ColumnsType<UserListingType>;
  showModal: () => void;
  filterData: FilterPerformersType | null;
  tableData: UserListingType[];
  setFilterData: React.Dispatch<SetStateAction<FilterPerformersType | null>>;
  usersListingPageSize: number;
  usersListingCurrentPage: number;
  usersListingTotalDocs: number;
  performersListParams: AlphaListParamsType | null;
  setPerformersListParams: Dispatch<SetStateAction<AlphaListParamsType | null>>;
  isLoadingData: boolean;
  totalDocsData: number;
}

enum variantOfPageSize {
  five = 5,
  ten = 10,
  twenty = 20
}

const PerformersListing: FC<IPerformersListingProps> = ({
  columns,
  filterData,
  tableData,
  setFilterData,
  usersListingPageSize,
  usersListingCurrentPage,
  usersListingTotalDocs,
  performersListParams,
  setPerformersListParams,
  isLoadingData,
}): JSX.Element => {
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { performersName } = values || {};
  
  useEffect(() => {
    if (performersName?.trim() === "") {
      setPerformersListParams((prev: AlphaListParamsType | null) => {
        if (prev && prev.search !== null) {
          const { search, ...rest } = prev;

          return {
            ...rest,
            page_size: 10,
          };
        } else {
          return prev;
        }
      });
    }
    
    const timeout: NodeJS.Timeout = setTimeout(():void => {
      const hasInputValue: boolean = performersName?.trim() !== ""
        && typeof performersName === "string"
        && (!performersListParams?.search || performersListParams?.search !== performersName);
      
      if (hasInputValue) {
        setPerformersListParams((prev: AlphaListParamsType | null) => {
          return {
            ...prev,
            page: 1,
            search: performersName?.trim(),
          };
        });
      }
    }, 2000);
    
    return () => clearTimeout(timeout);
  }, [performersName]);

  const handlePerformersPageChange = (newPage: number): void => {
    setPerformersListParams({
      ...performersListParams,
      page: newPage,
    });
  };

  const handlePerformersPageSizeChange = (newPageSize: number): void => {
    setPerformersListParams({
      ...performersListParams,
      page: 1,
      page_size: newPageSize,
    });
  };

  const FilterNames: FilterNamesType = {
    name: "ФИО сотрудника: ",
    role: "Роль: ",
    clients_number_from: "Кол-во клиентов от: ",
    clients_number_to: "Кол-во клиентов до: ",
  };

  const deleteFilterData = (e: React.MouseEvent<HTMLElement>, key: string): void => {
    e.preventDefault();
    setFilterData((prev: FilterPerformersType | null) => {
      if (prev) {
        return { ...prev, [key]: null };
      }
      return null;
    });
  };

  const clearFilterData = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setFilterData(null);
  };
  
  const emptyPerformers = (): JSX.Element => {
    return (
      <>
        {!isLoadingData && !!performersListParams?.search && <EmptyList activeKey="performers" />}
        {!isLoadingData && !performersListParams?.search && <EmptyList activeKey="noPerformers" />}
      </>
    );
  };
  
  const renderInput: JSX.Element = (
    <Form.Item
      name="performersName"
      noStyle
    >
      <Input
        id="performersName"
        className={css.input}
        suffix={<SearchIcon className={css.searchIcon} />}
        placeholder="Найти по ФИО сотрудника"
        allowClear
      />
    </Form.Item>
  );
  
  const renderTags: JSX.Element = (
    <div className={css.tag}>
      {Object.entries(filterData || {}).map(([key, value]: [string, string | string[] | number | null]) => {
        return (
          value && (
            <Tag
              className={css.alignedTag}
              closable
              onClose={(e: React.MouseEvent<HTMLElement>) => deleteFilterData(e, key)}
            >
              <div className={css.tagBlock}>
                <div className={css.tagKey}>{FilterNames[key]}</div>
                <div className={`${css.tagValue} ml-1`}>{value}</div>
              </div>
            </Tag>
          )
        );
      })}
      <ButtonCustom
        className={css.tagButton}
        size="small"
        type="default"
        text="Сбросить фильтр"
        onClick={clearFilterData}
      />
    </div>
  );

  return (
    <Form form={form}>
      {filterData && Object.values(filterData).find(value => value) ? (
        <div className={css.blockInputData}>
          <div className="flex mb-3">
            {renderInput}
            {/* Скрываем кнопку фильтров, пока не реализован функционал на бэке */}
            {/*<Button*/}
            {/*  className={css.button}*/}
            {/*  onClick={showModal}*/}
            {/*>*/}
            {/*  <FilterIcon className="pr-1" />*/}
            {/*  Фильтры*/}
            {/*</Button>*/}
          </div>
          {renderTags}
        </div>
      ) : (
        <div className={css.blockInput}>
          {renderInput}
          {/* Скрываем кнопку фильтров, пока не реализован функционал на бэке */}
          {/*<Button*/}
          {/*  className={css.button}*/}
          {/*  onClick={showModal}*/}
          {/*>*/}
          {/*  <FilterIcon className="pr-1" />*/}
          {/*  Фильтры*/}
          {/*</Button>*/}
        </div>
      )}
      <div className="bg-white px-4 rounded-lg">
        <Table
          className={css.table}
          columns={columns}
          dataSource={tableData}
          loading={isLoadingData}
          pagination={false}
          locale={{ emptyText: emptyPerformers() }}
        />
        <PaginationCustom
          className={css.paginationShowTotal}
          total={usersListingTotalDocs}
          currentPage={usersListingCurrentPage}
          pageSize={usersListingPageSize}
          handlePageChange={handlePerformersPageChange}
          handlePageSizeChange={handlePerformersPageSizeChange}
        />
      </div>
    </Form>
  );
};

export default PerformersListing;
