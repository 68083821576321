import React, { FC, JSX } from "react";
import css from "../ClientsListing.module.css";
import { FilterClientsType, FilterNamesType, ResponsibleFilterType } from "app/types";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { Tag } from "antd";

interface IClientListingTagsProps {
  filterData: FilterClientsType | null;
  deleteResponsibleFilterData: (
    e: React.MouseEvent<HTMLElement>,
    key: string,
    id: string,
    value: ResponsibleFilterType[]
  ) => void;
  deleteFilterData: (e: React.MouseEvent<HTMLElement>, key: string) => void;
  FilterNames: FilterNamesType;
  clearFilterData: (e: React.MouseEvent<HTMLElement>) => void;
}

const ClientListingTags: FC<IClientListingTagsProps> = ({
  filterData,
  deleteResponsibleFilterData,
  deleteFilterData,
  FilterNames,
  clearFilterData,
}): JSX.Element => {
  
  const TagRender = ({ keyData, value, id = keyData, name = "", responsible = false }: {
    keyData: string;
    value: string | number | boolean | string[] | ResponsibleFilterType[] | null;
    id?: string;
    name?: string;
    responsible?: boolean;
  }): JSX.Element => {
    const valueData: string = responsible ? name as string : value as string;
    
    return (
      <Tag
        key={id}
        className={css.alignedTag}
        closable
        onClose={(e: React.MouseEvent<HTMLElement>): void =>
          responsible
            ? deleteResponsibleFilterData(e, keyData, id, value as ResponsibleFilterType[])
            : deleteFilterData(e, keyData)
        }
      >
        <div className={css.tagBlock}>
          <div className={css.tagKey}>{FilterNames[keyData]}</div>
          <div className={`${css.tagValue} ml-1`}>{valueData}</div>
        </div>
      </Tag>
    );
  };
  
  return (
    <div className={css.tag}>
      {Object.entries(filterData ?? {}).map((
        [key, value]: [string, string | number | boolean | string[] | ResponsibleFilterType[] | null]
      ) => {
        if (!value) {
          return null;
        }
      
        if (key === "responsible_reference.uuid" && Array.isArray(value)) {
          return (value as ResponsibleFilterType[]).map((responsible: ResponsibleFilterType) => (
            <TagRender
              key={responsible.id}
              keyData={key}
              value={value}
              id={responsible.id}
              name={responsible.name}
              responsible
            />
          ));
        }
      
        return (
          <TagRender key={key} keyData={key} value={value} />
        );
      })}
      <ButtonCustom
        className={css.tagButton}
        size="small"
        type="default"
        text="Сбросить фильтр"
        onClick={clearFilterData}
      />
    </div>
  );
};

export default ClientListingTags;