import React, {
  Dispatch,
  FC,
  JSX,
  SetStateAction,
  useEffect,
  useState
} from "react";
import css from "../../ModelContent.module.css";
import DraggerForm from "../../../../../../utils/ModalUploadItems/DraggerForm/DraggerForm";
import { message, Skeleton, UploadFile, UploadProps } from "antd";
import { validFileFormat } from "../../../../../../utils/ModalUploadItems/validFileFormat";
import { UploadChangeParam } from "antd/es/upload";
import { deleteUploadFile, uploadFiles } from "../../../../../../api/document.api";
import { ActionHistoryFileType, UploadedFilesPropsType } from "app/types";
import { downloadFile, downloadUrl } from "../../../../../../utils/downloadFile";
import { ReactComponent as AttachFileIcon } from "../../../../../../assets/icons/attach_file_icon.svg";
import { ReactComponent as DeleteDocument } from "../../../../../../assets/icons/delete_file.svg";
import { ReactComponent as WarningIcon } from "../../../../../../assets/icons/warning_blue_icon.svg";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";

interface IKnapRecognizeProps {
  uploadedFilesKNAP: UploadedFilesPropsType[];
  setUploadedFilesKNAP: Dispatch<SetStateAction<UploadedFilesPropsType[]>>;
}

type IFilesProps = {
  label: string;
  uuid: string;
}

enum totalFiles {
  Total100 = 100,
}

enum FileListing {
  Empty = 0,
}

const KnapRecognize: FC<IKnapRecognizeProps> = ({
  uploadedFilesKNAP,
  setUploadedFilesKNAP,
}): JSX.Element => {
  const {
    documentTask,
    actionHistory
  } = useSelector((state: AppStateType) => state.tasks);
  
  const [uploadFilesLoaderKNAP, setUploadFilesLoaderKNAP] = useState<boolean>(false);
  const maxSizeFile: number = 100;
  const showUploadKNAP: boolean = uploadedFilesKNAP?.length >= maxSizeFile;
  
  useEffect(() => {
    type ITasksFilesProps = {
      fields: {
        files?: IFilesProps[];
        main_files?: IFilesProps[];
      }
    }
    
    const compileFiles = (source: ITasksFilesProps): UploadedFilesPropsType[] => {
      return source?.fields?.files
        ?.concat(source?.fields?.main_files || [])
        .filter((file: IFilesProps) => file.label && file.uuid)
        .map((file: IFilesProps): UploadedFilesPropsType => ({
          file_name: file.label,
          uuid: file.uuid
        })) || [];
    };
    
    const documentTaskFiles: UploadedFilesPropsType[] = compileFiles(documentTask as ITasksFilesProps);
    const actionHistoryFiles: UploadedFilesPropsType[] = compileFiles(actionHistory?.results?.[0] as ITasksFilesProps);
    
    const allFiles: UploadedFilesPropsType[] = actionHistoryFiles.length
      ? [...actionHistoryFiles]
      : [...documentTaskFiles];
    
    setUploadedFilesKNAP(allFiles);
  }, [documentTask, actionHistory]);
  
  const uploadFileKNAP = async (fileList: File[]): Promise<void> => {
    const formData: FormData = new FormData();
    fileList.forEach((file: File) => formData.append("files", file));
    setUploadFilesLoaderKNAP(true);
    
    try {
      const response = await uploadFiles(formData);
      
      setUploadedFilesKNAP((prevData: UploadedFilesPropsType[] | null) => {
        const safePrevData: UploadedFilesPropsType[] = prevData || [];
        let newData: UploadedFilesPropsType[] = [...safePrevData];
        let newFileCount: number = safePrevData?.length;
        
        response?.data?.forEach((file: UploadedFilesPropsType): void => {
          if (!newData.some((existingFile: UploadedFilesPropsType): boolean => existingFile.uuid === file.uuid)) {
            newData.push(file);
            newFileCount++;
          }
        });
        
        if (newData?.length > 100) {
          newData = newData.slice(0, 100);
          newFileCount = 100;
        }
        
        return newData;
      });
      
      if (response?.status === 200) {
        setUploadFilesLoaderKNAP(false);
      }
    } catch (error) {
      message.error("Ошибка в загрузке файлов!");
      setUploadFilesLoaderKNAP(false);
    }
  };
  
  const propsKNAP: UploadProps = {
    name: "file",
    multiple: true,
    customRequest: (): boolean => true,
    accept: validFileFormat,
    onChange: (info: UploadChangeParam): boolean => {
      if (info.fileList.length > 100) {
        info.fileList.splice(100);
      }
      
      const allFilesUploading: boolean =
        info.fileList.every((file: UploadFile<unknown>): boolean => file.status === "uploading");
      
      if (allFilesUploading) {
        const filterFile: UploadFile<unknown>[] = info.fileList.filter((file: UploadFile<unknown>): boolean => {
          if (!file) return false;
          
          const size: number = file?.size ? file.size / 1024 / 1024 : FileListing.Empty;
          return size < maxSizeFile;
        });
        
        if (filterFile.length < info.fileList.length) {
          message.error("Объем некоторых файлов или файла не должен превышать 100мб.");
        }
        
        const files: File[] = filterFile.map((file: UploadFile<unknown>) => file.originFileObj!) as File[];
        
        if (files.length > 0) {
          uploadFileKNAP(files);
        }
        
      }
      info.fileList.length = 0;
      return false;
    },
    showUploadList: false,
  };
  
  const counterUploadKNAP = (fileCountKNAP: number): boolean =>
    fileCountKNAP === totalFiles.Total100 || fileCountKNAP > totalFiles.Total100;
  
  const removeFileNAPLocale = (fileRemove: UploadedFilesPropsType) => {
    setUploadedFilesKNAP((prevFiles: UploadedFilesPropsType[]): UploadedFilesPropsType[] => {
      if (prevFiles) {
        const newFiles: UploadedFilesPropsType[] =
         prevFiles.filter((file: UploadedFilesPropsType): boolean => file?.uuid !== fileRemove.uuid);
        return newFiles?.length > 0 ? newFiles : [];
      }
      
      return [];
    });
  };
  
  const isFileInFiles = (
    files: ActionHistoryFileType[] | null | undefined,
    fileRemoveUuid: string
  ): boolean => {
    return Array.isArray(files)
      ? files.some((file: ActionHistoryFileType): boolean => file.uuid === fileRemoveUuid)
      : false;
  };
  
  const handleRemoveKNAP = async (
    e: React.MouseEvent<HTMLElement>,
    fileRemove: UploadedFilesPropsType
  ): Promise<void> => {
    e.preventDefault();
    
    const isFileInDocumentTaskFiles: boolean = isFileInFiles(documentTask?.fields?.files, fileRemove.uuid);
    const isFileInActionHistoryFiles: boolean =
      isFileInFiles(actionHistory?.results?.[0]?.fields?.files, fileRemove.uuid);
    
    if (isFileInDocumentTaskFiles || isFileInActionHistoryFiles) {
      removeFileNAPLocale(fileRemove);
      
      return;
    }
    
    try {
      const params: URLSearchParams = new URLSearchParams();
      params.append("file_uuid", fileRemove?.uuid);
      
      const response = await deleteUploadFile(params.toString());
      message.success(response.data.message);
      
      removeFileNAPLocale(fileRemove);
    } catch (error) {
      message.error("Файл не удален!");
    }
  };
  
  const uploadedFilesKNAPList: JSX.Element[] | null = uploadedFilesKNAP?.length ? (
    uploadedFilesKNAP.map((file: UploadedFilesPropsType, index: number) => (
      <div key={index} className={css.documentsAction}>
        <div className="flex items-center pl-0.5">
          <AttachFileIcon className="flex-shrink-0" />
          <a className={css.fileName} onClick={() => downloadFile(file?.file_name, downloadUrl(file.uuid))}>
            {file?.file_name}
          </a>
        </div>
        <div
          className={css.deleteIcon}
          onClick={(e: React.MouseEvent<HTMLElement>) => handleRemoveKNAP(e, file)}
        >
          <DeleteDocument className="flex-shrink-0" />
        </div>
      </div>)
    )) : null;
  
  return (
    <div>
      {!!uploadedFilesKNAP?.length && (
        <div className={`${css.modalText} mb-5`}>
          Ниже представлены файлы, которые содержались в задаче или были отправлены клиентом.
          Вы можете скорректировать список, удалив ненужные файлы.
          Если необходимо дополнить список, загрузите файлы вручную.
        </div>
      )}
      {!uploadedFilesKNAP?.length && (
        <div className={css.noFiles}>
          <WarningIcon className="flex-shrink-0 w-6 h-6" />
          <div className={css.noFilesText}>
            Новых документов для распознавания не найдено.
            Если необходимо, загрузите документы для распознавания вручную
          </div>
        </div>
      )}
      {!showUploadKNAP && (
        <DraggerForm
          props={{...propsKNAP}}
          fileCount={uploadedFilesKNAP?.length as number}
          counterUpload={counterUploadKNAP}
        />
      )}
      <div className={`${css.fileListing} grid justify-items-start items-center`}>
        {uploadFilesLoaderKNAP ? (
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              width: "100%"
            }}
          />
        ) : uploadedFilesKNAPList}
      </div>
    </div>
  );
};

export default KnapRecognize;