import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import css from "../../ModelContent.module.css";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { Input, Form } from "antd";
import { fetchSelectedUser } from "../../../../../../api/account.api";

interface IReassignTaskProps {
  reassignText: undefined | string;
}

const ReassignTask: FC<IReassignTaskProps> = ({
  reassignText
}): JSX.Element => {
  const { TextArea } = Input;
  const {
    responsible_reference,
    temporary_responsible_reference
  } = useSelector((state: AppStateType) => state.tasks.documentTask?.fields) ?? {};
  
  const [responsibleRoles, setResponsibleRoles] = useState<string[]>([]);
  
  const responsibleReferenceUuid: string = temporary_responsible_reference === null
    ? (responsible_reference?.[0]?.uuid ?? "")
    : (temporary_responsible_reference?.[0]?.uuid ?? "");
  
  useEffect(() => {
    const getUserInfo = async (): Promise<void> => {
      try{
        const response: any = await fetchSelectedUser(responsibleReferenceUuid);
        setResponsibleRoles(response?.data?.roles);
      } catch (err) {
        console.error("Get user info error:", err);
      }
    };

    if (responsibleReferenceUuid) {
      getUserInfo();
    }
  }, []);
  
  const includesRole = (roles: string[], targetRoles: string[]): boolean => {
    return targetRoles.some((role: string) => roles.includes(role));
  };
  
  const reassignedUserRole = (responsibleRoles: string[]): string => {
    if (responsibleRoles.length) {
      return includesRole(responsibleRoles, ["accountant-role", "assistant-role"]) ?
        " сотруднику Контакт центра " :
        " Бухгалтеру ";
    }
    
    return "";
  };
  
  return (
    <div className="mt-[-6px]">
      <div className={`${css.modalText} mb-5`}>
        Вы можете переназначить это обращение
        {reassignedUserRole(responsibleRoles)}
        и по желанию оставить комментарий
      </div>
      <div className="mb-5">
        <Form.Item name="reassignText" noStyle>
          <TextArea
            id="reassignText"
            rows={4}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваш комментарий"
          />
        </Form.Item>
        <div className="text-xs font-normal leading-100 text-gray-700 text-right">
          {reassignText?.length ?? 0}/2000 символов
        </div>
      </div>
    </div>
  );
};

export default ReassignTask;