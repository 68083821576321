export const statusValue = (value: string): string => {
  switch (value) {
    case ("Новая"):
      return "NEW";
    case ("Приостановлена"):
      return "PAUSE";
    case("Назначена"):
      return "BOOKED";
    case ("Поиск исполнителя"):
      return "SEARCH_RESPONSIBLE";
    case("В работе"):
      return "IN_PROGRESS";
    default:
      return "";
  }
};

export const transformStatuses = (inputArray: string[]): string[] => {
  return inputArray.map((text: string) => statusValue(text));
};
