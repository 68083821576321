import React, { FC, JSX } from "react";
import css from "./ZeroHistory.module.css";
import { ReactComponent as HistoryZero } from "../../../../assets/icons/zero_history_v2.svg";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";

interface IZeroHistoryProps {
  heightHistory: number;
  isApplication?: boolean;
}

const ZeroHistory: FC<IZeroHistoryProps> = ({ heightHistory, isApplication }):JSX.Element => {
  return (
    <div
      className={css.container}
      style={{height: `${heightHistory - 12}px`}}
    >
      <div className={css.label}>
        <div className={css.icon}><HistoryZero/></div>
        <div>
          <div className={css.textHeader}>История пуста</div>
          <div className={css.textBody}>
            Здесь будет отображена история действий <br/> по {isApplication ? "заявке" : "задаче"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZeroHistory;
