import React, { FC, JSX } from "react";
import css from "../../ModelContent.module.css";
import { Form, Select } from "antd";
import { ITaskActionsProps, topicOfAppeal } from "../../../taskActions";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { roleResolver } from "../../../../../../utils/roleResolver";
import * as jose from "jose";

interface ITopicOfAppealProps {
}

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

const TopicOfAppeal: FC<ITopicOfAppealProps> = (): JSX.Element => {
  
  const decodedToken: IJWTPayloadProps | null =
    useSelector((state: AppStateType) => state.account.decodedToken);
  
  const filterTopicOfAppeal = (
    topicOfAppeal: ITaskActionsProps[],
    decodedToken: IJWTPayloadProps | null,
  ): ITaskActionsProps[] => {
    if (decodedToken) {
      const admin: boolean = roleResolver(decodedToken).isAdmin;
      const accountant: boolean = roleResolver(decodedToken).isAccountant;
      const contactCenterEmployee: boolean = roleResolver(decodedToken).isContactCenter;
      
      if (contactCenterEmployee && !admin && !accountant) {
        return topicOfAppeal.filter((action: ITaskActionsProps): boolean =>
          action.value !== "Платные консультации");
      }
    }
    
    return topicOfAppeal;
  };
  
  return (
    <>
      <div className={`${css.modalText} mt-[-6px]`}>Тема обращения.</div>
      <Form.Item name="topicAppeal" className="mt-[-6px] mb-0">
        <Select
          id="topicAppeal"
          placeholder="Тема обращения"
          options={filterTopicOfAppeal(topicOfAppeal, decodedToken)}
          showSearch
          allowClear
          size="large"
          className={css.modalDrop}
        />
      </Form.Item>
    </>
  );
};

export default TopicOfAppeal;