import React, {
  FC,
  JSX,
  useState,
  useEffect,
  useMemo,
} from "react";
import {
  NavigateFunction,
  useNavigate,
  useParams,
  Link
} from "react-router-dom";
import {
  EmployeeListingDataType,
  FilterEmployeeType,
} from "app/types";
import ZeroEmployees from "../ZeroEmployees/ZeroEmployees";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { PaginationCustom } from "../../../../../ui-kit/PaginationCustom/PaginationCustom";
import { ReactComponent as SearchIcon } from "../../../../../../assets/icons/search_icon.svg";
import { ReactComponent as ClientActive } from "../../../../../../assets/icons/client_active.svg";
import { ReactComponent as ClientNotActive } from "../../../../../../assets/icons/client_not_active.svg";
import {
  Form,
  Input,
  Table,
  Tooltip,
  Checkbox,
} from "antd";
import { ColumnsType } from "antd/es/table";
import css from "./EmployeesListing.module.css";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import {
  getEmployeesPageSize,
  getEmployeeListingData,
  getEmployeesPageNumber,
  setClientsEmployeesListingParamsData,
} from "../../../../../../actions/employee.actions";
import { AppDispatch } from "../../../../../../store/store";
import LoadingCustom from "../../../../../ui-kit/LoadingCustom/LoadingCustom";
import ZeroSearch from "../../../../ZeroSearch/ZeroSearch";
import { useTableSorter } from "../../../../../../utils/handleSorterTable";
import DraftWarning from "../../../../../../utils/DraftWarning/DraftWarning";

interface IEmployeesListingProps {
}

type FormPropsType = {
  employeeValue: string | null,
}

enum ListingPage {
  firstPage = 1,
}

const EmployeesListing: FC<IEmployeesListingProps> = (): JSX.Element => {
  const [employeeFilterForm] = Form.useForm();
  const values: FormPropsType = Form.useWatch([], employeeFilterForm);
  const { employeeValue } = values || {};
  
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const paramsValue: any = useParams();
  const params = useMemo(() => paramsValue, [paramsValue]);

  const {
    employeeListing,
    employeeListingLoading,
    totalDocs,
    employeesPageSize,
    employeesPage,
    clientsEmployeeParams
  } = useSelector((state: AppStateType) => state.employee);

  const [paramsData, setParamsData] = useState<FilterEmployeeType | null>(clientsEmployeeParams
  ? clientsEmployeeParams
  : {"client.uuid": params?.id,
      page_size: employeesPageSize,
      page: employeesPage,
    }
  );
  const [isLoadingData, setLoadingData] = useState<boolean>(false);

  useEffect(() => {
    if (employeeValue?.trim() === "") {
      setParamsData((prev: FilterEmployeeType | null) => {
        if (prev && prev.employees_name_pos_inn !== null) {
          const { employees_name_pos_inn, ...rest } = prev;

          dispatch(setClientsEmployeesListingParamsData({...rest}));
          return {...rest};
        } else {
          dispatch(setClientsEmployeesListingParamsData(prev));
          return prev;
        }
      });
    }

    const timeout: NodeJS.Timeout = setTimeout(():void => {
      const hasInputValue: boolean = employeeValue?.trim() !== ""
        && typeof employeeValue === "string"
        && (!paramsData?.employees_name_pos_inn || paramsData?.employees_name_pos_inn !== employeeValue);
      
      if (hasInputValue) {
        setParamsData((prev: FilterEmployeeType | null) => {

          dispatch(setClientsEmployeesListingParamsData({...prev, employees_name_pos_inn: employeeValue}));
          return {
            ...prev,
            employees_name_pos_inn: employeeValue?.trim()
          };
        });
      }
    }, 2000);

    return () => clearTimeout(timeout);
  }, [employeeValue, params]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    employeeFilterForm.setFieldValue("employeeValue", e.target.value);

    if (e.target.value) {
      setTimeout((): void => {
        setCurrentPage(ListingPage.firstPage);
        dispatch(getEmployeesPageNumber(ListingPage.firstPage));
      }, 2000);
    } else {
      setCurrentPage(ListingPage.firstPage);
      dispatch(getEmployeesPageNumber(ListingPage.firstPage));
    }
  };

  useEffect(() => {
    if (params?.id) {
      setLoadingData(true);
      dispatch(getEmployeeListingData(paramsData))
        .finally(() => {
          setLoadingData(false);
        });
      dispatch(setClientsEmployeesListingParamsData(paramsData));
    }
  }, [params?.id, paramsData]);

  useEffect(() => {
    setParamsData((prevParamsData: FilterEmployeeType | null) => {
      const updateParams: FilterEmployeeType = {
        ...prevParamsData,
        page_size: employeesPageSize,
        page: employeesPage,
      };

      dispatch(setClientsEmployeesListingParamsData(updateParams));
      return updateParams;
    });
  }, [employeesPageSize, employeesPage, params]);

  const toClientCard = (record: EmployeeListingDataType): JSX.Element => (
    <Link
      className={`${css.columnText} ml-3`}
      to={`/clients/${params?.id}/employee/${record?.fields?.uuid}`}
      onClick={(): void => {
        navigate(`/clients/${params?.id}/employee/${record?.fields?.uuid}`, {state: {record}});
      }}
    >
      {record?.fields?.full_name}
    </Link>
  );

  const contentTooltip: JSX.Element = (
    <div className={css.tooltip}>
      <span>Статус сотрудника</span>
      <div className="flex items-center">
        <ClientActive/>
        <span className="ml-2">- Работает</span>
      </div>
      <div className="flex items-center">
        <ClientNotActive/>
        <span className="ml-2">- Уволен</span>
      </div>
    </div>
  );

  const renderPosition = (record: EmployeeListingDataType): JSX.Element => {
    const correctPosition: string | undefined = record?.fields?.contract_labor?.length
      ? record?.fields?.contract_labor?.[0]?.position
      : "Сотрудник по договору ГПХ";

    return (
      <div className={css.columnText}>
        {correctPosition}
      </div>
    );
  };

  const renderInn = (record: EmployeeListingDataType): JSX.Element => {
    return (
      <div className={css.columnText}>
        {record?.fields?.inn_document?.[0]?.inn}
      </div>
    );
  };

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter(null, setParamsData);

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="table-title">
        {text}
      </div>
    );
  };

  const [employeeStatusWork, setEmployeeStatusWork] = useState<boolean>(
    clientsEmployeeParams?.employee_status === "APPROVED"
  );
  const [employeeStatusFired, setEmployeeStatusFired] = useState<boolean>(
    clientsEmployeeParams?.employee_status === "DECLINED"
  );

  const handleCheckboxClick = (isActive: boolean): void => {
    if (isActive) {
      setEmployeeStatusWork((prevState) => !prevState);
      if (employeeStatusFired) setEmployeeStatusFired(false);
    } else {
      setEmployeeStatusFired((prevState) => !prevState);
      if (employeeStatusWork) setEmployeeStatusWork(false);
    }
  };

  const changeParams = (): void => {
    setParamsData((prevParamsData: FilterEmployeeType | null) => {
      const statusValue: string | null = employeeStatusWork ? "APPROVED" : (employeeStatusFired ? "DECLINED" : null);

      const updateParams: FilterEmployeeType = {
        ...prevParamsData,
        employee_status: statusValue,
        page: ListingPage.firstPage,
      };

      dispatch(setClientsEmployeesListingParamsData(updateParams));
      setCurrentPage(ListingPage.firstPage);
      dispatch(getEmployeesPageNumber(ListingPage.firstPage));
      return updateParams;
    });
  };

  const filterStatus = (confirm: () => void): JSX.Element => {
    return (
      <div className="table-filters-wrap">
        <div className="table-filters">
          <Checkbox
            checked={employeeStatusWork}
            className="table-checkbox"
            onClick={() => handleCheckboxClick(true)}
          >
            Работает
          </Checkbox>
          <Checkbox
            checked={employeeStatusFired}
            className="table-checkbox"
            onClick={() => handleCheckboxClick(false)}
          >
            Уволен
          </Checkbox>
        </div>
        <div className="table-btns">
          <ButtonCustom
            type="link"
            text="Сбросить"
            onClick={() => {
              setEmployeeStatusWork(false);
              setEmployeeStatusFired(false);
            }}
          />
          <ButtonCustom
            type="primary"
            text="Ок"
            onClick={() => {
              changeParams();
              confirm();
            }
            }
          />
        </div>
      </div>
    );
  };

  //шапка таблицы
  const columns: ColumnsType<EmployeeListingDataType> = [
    {
      title: tableTitle("Сотрудник"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields"],
      key: "employee",
      width: "50%",
      className: css.tableHeader,
      filtered: employeeStatusWork || employeeStatusFired,
      filterDropdown: ({ confirm }) => filterStatus(confirm),
      onFilterDropdownOpenChange: (visible: boolean): void => {
        if (!visible) {
          changeParams();
        }
      },
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "full_name"),
      }),
      render: (_text: string, record: EmployeeListingDataType) => {
        return (
          <div className="flex items-center ml-2">
            <div>
              <Tooltip
                placement="topLeft"
                title={contentTooltip}
                trigger="hover"
                arrow={{pointAtCenter: true}}
              >
                {record?.fields?.employee_status ? <ClientActive/> : <ClientNotActive/>}
              </Tooltip>
            </div>
            {toClientCard(record)}
          </div>
        );
      },
    },
    {
      title: tableTitle("Должность"),
      dataIndex: ["fields", "contract_labor"],
      sorter: true,
      showSorterTooltip: false,
      key: "position",
      width: "25%",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "position"),
      }),
      render: (_text: string, record: EmployeeListingDataType) => renderPosition(record),
    },
    {
      title: tableTitle("ИНН"),
      dataIndex: ["fields", "inn_document"],
      sorter: true,
      showSorterTooltip: false,
      key: "inn",
      width: "25%",
      className: css.tableHeader,
      onHeaderCell: () => ({
        onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, "inn_document.inn"),
      }),
      render: (_text: string, record: EmployeeListingDataType) => renderInn(record),
    },
  ];

  // логика пагинации страницы
  const [currentPage, setCurrentPage] = useState<number>(employeesPage);
  const [pageSize, setPageSize] = useState<number>(employeesPageSize);

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    dispatch(getEmployeesPageNumber(newPage));
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    setPageSize(newPageSize);
    dispatch(getEmployeesPageSize(newPageSize));
    setCurrentPage(ListingPage.firstPage);
    dispatch(getEmployeesPageNumber(ListingPage.firstPage));
  };

  const employeesSearch: string = clientsEmployeeParams?.employees_name_pos_inn ?? "";

  const zeroEmployees = (name: string): JSX.Element => {
    const hasFilter: boolean = !!name || !!paramsData?.employee_status;
    
    return (
      <>
        {!isLoadingData && hasFilter && <ZeroSearch />}
        {!isLoadingData && !hasFilter && <ZeroEmployees variant="clientCard" />}
      </>
    );
  };
  
  const initialValues: FormPropsType = {
    employeeValue: clientsEmployeeParams?.employees_name_pos_inn ?? null,
  };

  return (!employeeListingLoading ? (
    <div>
      <DraftWarning />
      <Form
        name="employeFilter"
        form={employeeFilterForm}
        initialValues={initialValues}
      >
        <div className={css.headerText}>
          Сотрудники клиента
        </div>
        <div className={css.listing}>
          <Form.Item name="employeeValue" noStyle>
            <Input
              id="employeeValue"
              className={css.input}
              allowClear
              suffix={<SearchIcon className={css.searchIcon} />}
              placeholder="Найти по ФИО сотрудника, должности или ИНН"
              onChange={handleInputChange}
            />
          </Form.Item>
          <div>
            <Table
              className={css.table}
              columns={columns}
              loading={isLoadingData}
              dataSource={employeeListing?.length ? employeeListing : []}
              pagination={false}
              locale={{ emptyText: zeroEmployees(employeesSearch) }}
            />
            {employeeListing?.length ? (
              <PaginationCustom
                className={css.paginationShowTotal}
                total={totalDocs ? totalDocs : 0}
                showTotal={(total: number, range: number[]): string =>
                  `${range[0] || "1"}-${range[1] || "1"} из ${total}`
              }
                defaultPageSize={10}
                currentPage={currentPage}
                pageSize={pageSize}
                defaultCurrent={1}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
              />
            ) : (
              <div className={css.noEmployees}/>
            )}
          </div>
        </div>
      </Form>
    </div>
    ) : (
      <div className="text-center mt-96">
        <LoadingCustom/>
      </div>
    )
  );
};

export default EmployeesListing;
