import React, {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useEffect
} from "react";
import css from "../TasksListing.module.css";
import { Tag } from "antd";
import { CatalogTaskStatusType } from "../../../../utils/catalog";
import { roleResolver } from "../../../../utils/roleResolver";
import { useDispatch, useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { FilterTasksType } from "app/types";
import * as jose from "jose";
import { getTaskPageNumber, setTaskListingParams } from "../../../../actions/tasks.actions";
import { AppDispatch } from "../../../../store/store";
import dayjs from "dayjs";
import { filterParamsToString } from "../../../../utils/filterParamsToString";

interface ITaskListingFastFiltersProps {
  filter: boolean;
  setParamsData: Dispatch<SetStateAction<FilterTasksType | null>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  activeKey: string;
  prevActiveKey: string | null;
  setFilterStatusLabel: Dispatch<SetStateAction<string[]>>;
}

interface IJWTPayloadProps extends jose.JWTPayload {
  resource_access?: {
    bc: {
      roles: string[];
    }
  }
}

const TaskListingFastFilters: FC<ITaskListingFastFiltersProps> = ({
  filter = true,
  setParamsData,
  setCurrentPage,
  activeKey,
  prevActiveKey,
  setFilterStatusLabel
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);
  const tasksParams: FilterTasksType | null = useSelector((state: AppStateType) => state.tasks.tasksParams);
  
  const adminRole: boolean = roleResolver(decodedToken).isAdmin;
  const firstPage: number = 1;
  
  //TODO переписать поля status в Срочные, Высокий приоритет и Есть обновления как только будут данные с бэка
  const taskFilterDeadLine: CatalogTaskStatusType[] = [
    {status: "deadline_lte", label: "Срочные"},
    // {status: "HIGH", label: "Высокий приоритет"},
    // {status: "UPDATE", label: "Есть обновления"},
  ];
  
  const taskFilterStatus: CatalogTaskStatusType[] = [
    {status: adminRole ? "NEW,SEARCH_RESPONSIBLE,BOOKED" : "BOOKED", label: "Новые"},
    {status: "IN_PROGRESS,PAUSE,1C_SYNC,1C_SUCCESS,1C_ERROR", label: "В работе"},
    {status: "FIN_TRUE", label: "Выполнены"},
    {status: "FIN_FALSE", label: "Отклонены"},
  ];
  
  const [activeDeadline, setActiveDeadline] = useState<string>(tasksParams?.deadline_lte
   ? tasksParams?.deadline_lte as string
   : ""
  );
  const [activeStatus, setActiveStatus] = useState<string>(tasksParams?.status_task
   ? filterParamsToString(tasksParams?.status_task as string)
   : ""
  );
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
  
  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    
    if (prevActiveKey && activeKey && activeKey !== prevActiveKey) {
      setActiveStatus("");
      setActiveDeadline("");
    }
  }, [activeKey]);
  
  const handleTagDeadlineClick = (status: string): void => {
    const hasDeadline: boolean = activeDeadline.includes(status);
    
    if (!hasDeadline) {
      setActiveDeadline(status);
      setCurrentPage(firstPage);
      dispatch(getTaskPageNumber(firstPage));
      
      setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
        const updatedParams: FilterTasksType | null = prev
         ? { ...prev, deadline_lte: dayjs().format("YYYY-MM-DD"), page: firstPage }
         : null;
        
        dispatch(setTaskListingParams(updatedParams));
        return updatedParams;
      });
    }
  };
  
  const handleDeadlineClose = (): void => {
    setActiveDeadline("");
    setCurrentPage(firstPage);
    dispatch(getTaskPageNumber(firstPage));
    
    setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
      if (prev && prev?.deadline_lte !== null) {
        const { deadline_lte, ...rest } = prev;
        const updatedParams: FilterTasksType | null = prev
         ? { ...rest, page: firstPage }
         : null;
        
        dispatch(setTaskListingParams(updatedParams));
        
        return updatedParams;
      } else {
        return prev;
      }
    });
  };
  
  const handleTagStatusClick = (status: string, label: string): void => {
    const statusArray: string[] = status.includes(",") ? status.split(",") : [status];
    const activeStatusData: string[] = activeStatus.split(",");

    const newStatuses: string[] = statusArray.filter(statusItem => !activeStatusData.includes(statusItem));

    if (newStatuses.length) {
      setCurrentPage(firstPage);
      dispatch(getTaskPageNumber(firstPage));
      
      setActiveStatus(() => {
        const newFilters: string = [...activeStatusData, ...newStatuses].filter(Boolean).join(",");

        setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
          const updatedParams: FilterTasksType | null = prev
           ? { ...prev, ...(newFilters.length ? { status_task: `[${newFilters}]` } : {}), page: firstPage }
           : null;

          dispatch(setTaskListingParams(updatedParams));
          
          return updatedParams;
        });

        return newFilters;
      });
      setFilterStatusLabel((prev) => [...prev, label]);
    }
  };
  
  const handleStatusClose = (status: string, label: string): void => {
    setCurrentPage(firstPage);
    dispatch(getTaskPageNumber(firstPage));
    
    setActiveStatus((prev) => {
      const removedArray: string[] = status.includes(",") ? status.split(",") : [status];
      const statusArray: string[] = prev.split(",");
      const newFilters: string = statusArray.filter((status: string)=> !removedArray.includes(status)).join(",");
      
      setParamsData((prev: FilterTasksType | null): FilterTasksType | null => {
        if (!newFilters.length && prev && prev?.status_task !== null) {
          const { status_task, ...rest } = prev;
          const updatedParams: FilterTasksType | null = prev
           ? { ...rest, page: firstPage }
           : null;
          
          dispatch(setTaskListingParams(updatedParams));
          return updatedParams;
        } else {
          const updatedParams: FilterTasksType | null = prev
           ? { ...prev, status_task: `[${newFilters}]`, page: firstPage }
           : null;
          
          dispatch(setTaskListingParams(updatedParams));
          return updatedParams;
        }
      });
      
      return newFilters;
    });
    setFilterStatusLabel((prev) => prev.filter((item: string): boolean => item !== label));
  };
  
  return (
    <div className={`flex ${filter ? "" : "mb-3"}`}>
      {taskFilterDeadLine.map(({status, label}) => (
        <Tag
          key={status}
          className={`${css.commonTagStyle} ${activeDeadline ? css.activeFilters : css.quickFilters}`}
          closable={!!activeDeadline}
          onClick={() => handleTagDeadlineClick(status)}
          onClose={handleDeadlineClose}
        >
          <div className={activeDeadline ? "mr-1" : ""}>{label}</div>
        </Tag>
      ))}
      {taskFilterStatus.map(({status, label}) => (
        <Tag
          key={status}
          className={`${css.commonTagStyle} ${activeStatus.includes(status) ? css.activeFilters : css.quickFilters}`}
          closable={activeStatus.includes(status)}
          onClick={() => handleTagStatusClick(status, label)}
          onClose={() => handleStatusClose(status, label)}
        >
          <div className={activeStatus.includes(status) ? "mr-1" : ""}>{label}</div>
        </Tag>
      ))}
    </div>
  );
};

export default TaskListingFastFilters;