import React, { FC, JSX } from "react";
import css from "../AboutClient.module.css";
import ListItem from "../../../../../ui-kit/RenderListItem/ListItem";
import { Card } from "antd";
import { DocumentSchemaLabelMapType } from "app/types";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../../../reducers/mainReducer";
import { documentSchemaLabelsMapper } from "../../../../../../utils/documentSchemaLabelsMapper";
import CardLabel from "../../../../../../utils/CardLabel/CardLabel";

interface IOrganizationInfoProps {
}

const OrganizationInfo: FC<IOrganizationInfoProps> = (): JSX.Element => {
  const {
    documentClient,
    schemaClient
  } = useSelector((state: AppStateType) => state.client);
  const clientLabel: DocumentSchemaLabelMapType = documentSchemaLabelsMapper(schemaClient);

  return (
    <Card className={css.card}>
      <CardLabel title="Организация" />
      <ul>
        <ListItem value={documentClient?.fields?.name ?? ""} label={clientLabel?.["name"]?.label} />
        <ListItem
          value={documentClient?.fields?.registered_address ?? ""}
          label={clientLabel?.["registered_address"]?.label}
        />
        <ListItem value={documentClient?.fields?.opf ?? ""} label={clientLabel?.["opf"]?.label} />
        <ListItem value={documentClient?.fields?.inn ?? ""} label={clientLabel?.["inn"]?.label} />
        <ListItem value={documentClient?.fields?.kpp ?? ""} label={clientLabel?.["kpp"]?.label} />
        <ListItem value={documentClient?.fields?.company_ogrn ?? ""} label={clientLabel?.["company_ogrn"]?.label} />
        <ListItem value={documentClient?.fields?.tax_system ?? ""} label={clientLabel?.["tax_system"]?.label} />
        <ListItem value={documentClient?.fields?.primary_okved ?? ""} label={clientLabel?.["primary_okved"]?.label} />
        {!!documentClient?.fields?.secondary_okved?.length && (
          <li className="flex items-center">
            <span className={css.cardItemLabel}>
              {clientLabel?.["secondary_okved"]?.label}
            </span>
            <span className={css.cardItemValue}>
              {documentClient?.fields?.secondary_okved?.map((okved: string, idx: number) => (
                <span className="pr-2" key={idx}>
                  {okved}
                </span>
              ))}
            </span>
          </li>
        )}
        {/*TODO Временно скрываем до реализации МЧД*/}
        {/*<li className="flex items-center mb-[22px]">*/}
        {/*  <span className={css.cardItemLabel}>*/}
        {/*    Срок действия МЧД ?*/}
        {/*  </span>*/}
        {/*  <span className={css.cardItemValue}>*/}
        {/*    до 23.03.2023 г. осталось 12 дней ?*/}
        {/*  </span>*/}
        {/*</li>*/}
        {/*<li className="flex items-center">*/}
        {/*  <ButtonCustom type="primary" text="Ссылка на МЧД" ghost />*/}
        {/*</li>*/}
      </ul>
    </Card>
  );
};

export default OrganizationInfo;