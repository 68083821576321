import React, {
  FC,
  JSX,
  useRef,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction
} from "react";
import {
  NavigateFunction,
  useLocation,
  useNavigate,
  useParams,
  Location,
} from "react-router-dom";
import { useDispatch, useSelector, batch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { AppStateType } from "../../../reducers/mainReducer";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow_left_icon.svg";
import { ReactComponent as ErrorIcon } from "../../../assets/icons/warning_circle_icon.svg";
import { ButtonCustom } from "../../ui-kit/ButtonCustom/ButtonCustom";
import {
  getStatusTask,
  fetchSchemaTask,
  fetchDocumentTask,
  getTaskFile1CFailure,
  getTaskUuid1CFailure,
  getActionHistoryListing,
  getProcessAutomaticallyFileFailure,
  getActionHistoryFailure,
  getOperationMarkupFilesFailure,
  getSchemaTaskFailure,
  getDocumentTaskFailure,
  getStatusTaskFailure
} from "../../../actions/tasks.actions";
import type { TabsProps } from "antd";
import {
  Tabs,
  Modal,
  notification,
  Form,
  message
} from "antd";
import css from "./TaskCards.module.css";
import {
  TaskNotificationStatusPropsType,
  ActionHistoryResultsType,
  ActionHistoryType,
  AbstractDocType,
} from "app/types";
import ModelContent from "./ModalContent/ModelContent";
import LoadingCustom from "../../ui-kit/LoadingCustom/LoadingCustom";
import { finalizeClientTask, finalizeTaskFailure } from "../../../actions/document.actions";
import { parseStatusText } from "../../../utils/parseStatusText";
import TaskCardInfo from "./TaskCardInfo/TaskCardInfo";
import TaskCardTechInfo from "./TaskCardTechInfo/TaskCardTechInfo";
import { fetchSchemaClient, getClientTariffInfo } from "../../../actions/client.actions";
import * as jose from "jose";
import { parseStatus } from "../../../utils/parseStatus";
import { roleResolver } from "../../../utils/roleResolver";
import ModalApplication from "./ModalApplication/ModalApplication";
import { getClarificationResult, updateTaskNotification } from "../../../api/tasks.api";
import { openNotificationsUpdateError } from "../Tasks";
import { getNotificationsNumber } from "../../../actions/notifications.actions";
import { notificationProcessing } from "../../../utils/Notifications/TaskCardNotifications/TaskCardNotifications";

interface ITaskCardsProps {
  setNavBarHidden: Dispatch<SetStateAction<boolean>>;
}

enum historyAction {
  onlyOne = 1,
}

const TaskCards: FC<ITaskCardsProps> = ({
  setNavBarHidden
}): JSX.Element => {
  const [form] = Form.useForm();

  const [currentStep, setCurrentStep] = useState<number | undefined>(-1);
  const [isAdministrator, setAdministrator] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [updateHistory, setUpdateHistory] = useState<boolean>(false);
  const [finishTaskModal, setFinishTaskModal] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [applicationModal, setApplicationModal] = useState<boolean>(false);
  const [isHistoryStepsBlockLoading, setHistoryStepsBlockLoading] = useState<boolean>(true);
  const [taskUpdate, setTaskUpdate] = useState<boolean>(true);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [isClarificationStatus, setClarificationStatus] = useState<boolean>(false);
  //TODO пересмотреть логику как только бэк будет отправлять флаг по обработке уточнений
  const [checkClarification, setCheckClarification] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();

  const decodedToken: jose.JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const adminRole: boolean = roleResolver(decodedToken).isAdmin;
  const accountantRole: boolean = roleResolver(decodedToken).isAccountant;
  const techSupportRole: boolean = roleResolver(decodedToken).isTechSupport;
  const contactCenterRole: boolean = roleResolver(decodedToken).isContactCenter;

  useEffect(() => {
    return () => {
      notification.destroy();
      
      batch(() => {
        dispatch(getDocumentTaskFailure());
        dispatch(getSchemaTaskFailure());
        dispatch(getProcessAutomaticallyFileFailure(0, null));
        dispatch(getOperationMarkupFilesFailure(0));
        dispatch(getTaskUuid1CFailure(0, null));
        dispatch(getTaskFile1CFailure(0, null));
        dispatch(getActionHistoryFailure());
        dispatch(getStatusTaskFailure(0));
      });
    };
  }, []);

  const paramsData: any = useParams();
  const path: string = useLocation().pathname;
  const params = useMemo(() => paramsData, [path]);
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();

  //получение схемы задачи
  const {
    documentTask,
    statusTask,
    schemaTaskData,
    actionHistory,
    getDocumentTaskStatus,
    actionHistoryStatus,
    statusTaskNumber,
  } = useSelector((state: AppStateType) => state.tasks);

  const {
    error,
    finalizeTaskStatus,
  } = useSelector((state: AppStateType) => state.document);

  useEffect(() => {
    if (actionHistoryStatus !== 0 || statusTaskNumber !== 0) setHistoryStepsBlockLoading(false);
  }, [actionHistoryStatus, statusTaskNumber]);

  useEffect(() => {
    if (schemaTaskData && documentTask) {
      setLoading(true);
    }
  }, [schemaTaskData, documentTask]);

  const {state} = useLocation();

  const clientInfo = state?.record;

  const ref: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement | null>(null);

  const {
    task_type,
    status_task,
    client_tariff,
    task_type_id,
    number,
    uuid,
    client,
    responsible_reference,
    temporary_responsible_reference,
    additional_number_of_consultations,
    additional_accounting_restore_months,
    additional_number_of_employees,
    additional_agency_agreements_qty,
    task_reason,
    send_clarification_result,
  } = documentTask?.fields ?? {};

  const clarificationVariant = (status: boolean, value: string): void => {
    notification.destroy();
    setClarificationStatus(status);
    notificationProcessing(true, value);
  };
  
  const isTaskFinished: boolean = status_task === "FIN_FALSE"
   || status_task === "FIN_TRUE"
   || status_task === "1C_SYNC";

  useEffect(() => {
    const fetchClarificationResult = async (): Promise<void> => {
      const clarificationUuid: string = send_clarification_result?.[0]?.uuid ?? "";
      
      try {
        const response = await getClarificationResult(clarificationUuid);
        
        fetchTaskInfo(location);
        const status: string = response?.data?.fields?.send_clarification_result_status;
        const isProgress: boolean = status === "IN_PROGRESS";
        const isSuccess: boolean = status === "SUCCESS";
        const isError: boolean = status === "ERROR";

        //TODO временно скрываем уведомления загрузки и
        // успешной обработки пока не будет флаг результата обработки на бэке
        // лоудер загрузки отображаем если статус обработки "IN_PROGRESS"
        if (isProgress) {
          notificationProcessing(true, "processing");
          setTimeout(() => {
            fetchClarificationResult();
          }, 30000);
        }

        if (isSuccess) {
          // clarificationVariant(false, "success");
          notification.destroy();
          setClarificationStatus(false);
        }

        if (isError) {
          clarificationVariant(false, "failure");
        }

      } catch {
        notification.destroy();
        message.error("Ошибка в обработке уточнения");
      }
    };
    
    if (send_clarification_result && !isTaskFinished) {
      setClarificationStatus(true);
      if (checkClarification) {
        fetchClarificationResult();
      }
      //TODO расскоментировать как будет готов флаг на бэке
      // notificationProcessing(true, "processing");
    }
  }, [send_clarification_result?.[0]?.uuid]);

  const taskType: string | undefined = task_type?.[0]?.label;

  const isTaskFinalised: boolean = status_task === "PAUSE"
    || status_task === "FIN_FALSE"
    || status_task === "FIN_TRUE"
    || status_task === "1C_SYNC";
  const isFinishTaskDisabled: boolean = isTaskFinalised || status_task === "NEW";
  const isCompletedSuccessfully: boolean = status_task === "FIN_FALSE" || status_task === "FIN_TRUE";
  const is1CError: boolean = status_task === "1C_ERROR";
  const separateTaskType: boolean = taskType === "Разметка операций" || taskType === "Обращение";
  const isTaskTerminationService: boolean = taskType === "Прекращение обслуживания";
  const isTaskTariffIncrease: boolean = taskType === "Повышение тарифа";
  const isApplicationTask: boolean = isTaskTerminationService || isTaskTariffIncrease;

  const fetchTaskInfo = (location: Location): void => {
    //Получаем данные Task по uuid документа, uuid получаем из параметров
    const getInfo = (currentTaskUuid: string): void => {
      dispatch(fetchSchemaTask());
      setTimeout(() => dispatch(fetchDocumentTask(currentTaskUuid)), 1000);
      dispatch(fetchDocumentTask(currentTaskUuid));
      checkStepResult();
    };

    let currentTaskUuid: string;

    if (path.includes("clients")) {
      currentTaskUuid = location?.pathname?.split("/")?.[4];
      getInfo(currentTaskUuid);
      return;
    } else {
      currentTaskUuid = location?.pathname?.split("/")?.[2];
      getInfo(currentTaskUuid);
      return;
    }
  };

  useEffect(() => {
    if (path.includes("task")) {
      setNavBarHidden(false);
    }

    fetchTaskInfo(location);
  }, [
    ref,
    path,
    params,
    location,
    dispatch,
    clientInfo,
    updateHistory,
    setNavBarHidden,
    finalizeTaskStatus,
  ]);

  useEffect(() => {
    checkStepResult();
  }, [statusTask]);

  useEffect(() => {
    if (!documentTask) return;

    if (documentTask) {
      if (client_tariff?.[0]?.uuid) {
        dispatch(fetchSchemaClient());
        dispatch(getClientTariffInfo(client_tariff?.[0]?.uuid));
      }

      if (task_type_id) {
        dispatch(getActionHistoryListing({"task.uuid": params.id}));
        setAdministrator(true);
      } else {
        setAdministrator(false);
        dispatch(getStatusTask(params.id));
      }
    }
  }, [documentTask, updateHistory]);

  useEffect(() => {
    const isResponsibleForTask: boolean = documentTask?.fields?.responsible_reference?.[0]?.uuid === decodedToken?.sub;
    const uuidTask: string | undefined = documentTask?.fields?.uuid;

    const fetchNotification = async (): Promise<void> => {
      try {
        await updateTaskNotification({task_uuid: uuidTask, read: true});
        dispatch(getNotificationsNumber());
      } catch (error) {
        openNotificationsUpdateError();
      } finally {
        setTaskUpdate(false);
      }
    };

    if (uuidTask && taskUpdate && isResponsibleForTask) {
      fetchNotification();
    }

  }, [documentTask]);

  const returnToTasksOrClientCard = (): void => {
    const hasClientUuid: boolean = path?.split("/")?.includes("clients");

    if (hasClientUuid) {
      const clientUuid: string = path?.split("/")?.[2];
      navigate(`/clients/${clientUuid}`);
    } else {
      navigate("/tasks");
    }

    dispatch(getActionHistoryFailure());
  };

  const isUserResponsible = (references: AbstractDocType[] | undefined): boolean => {
    const userUuid: string = decodedToken?.sub ?? "";

    if (!references || references.length === 0) return false;

    return references[0]?.uuid === userUuid;
  };

  const isAccessToClientProfile = (): boolean => {
    const isClientResponsible: boolean = client?.[0]?.temporary_responsible_reference !== null
      ? isUserResponsible(client?.[0]?.temporary_responsible_reference)
      : isUserResponsible(client?.[0]?.responsible_reference);

    const isTaskResponsible: boolean = responsible_reference !== null
      ? isUserResponsible(responsible_reference)
      : isUserResponsible(temporary_responsible_reference);

    const isResponsibleForTask: boolean = documentTask?.fields?.client !== null
      ? isClientResponsible
      : isTaskResponsible;

    return (adminRole || isResponsibleForTask || contactCenterRole);
  };

  const isCheckAccess = (): boolean => {
    const isTaskResponsible: boolean = responsible_reference !== null
      ? isUserResponsible(responsible_reference)
      : isUserResponsible(temporary_responsible_reference);

    return (adminRole || isTaskResponsible);
  };

  //данные верхнего меню страницы
  const itemsTabs: TabsProps["items"] = [
    {
      key: "1",
      label: "Задача",
      children: (
        <TaskCardInfo
          currentStep={currentStep}
          isAdministrator={isAdministrator}
          setNavBarHidden={setNavBarHidden}
          isCheckAccess={isAccessToClientProfile()}
          isHistoryStepsBlockLoading={isHistoryStepsBlockLoading}
        />
      ),
      destroyInactiveTabPane: true,
    },
    {
      key: "2",
      label: techSupportRole ? "Служебная информация" : null,
      children: (
        techSupportRole ? <TaskCardTechInfo /> : null
      ),
      destroyInactiveTabPane: true,
    },
  ];

  const checkStepResult = (): void => {
    if (statusTask) {
      if (statusTask?.statuses?.[0].status === "in_progress") {
        setCurrentStep(0);
      } else {
        const lastIndex: number = statusTask.statuses.reduceRight(
          (lastIndex: number,
           currentStep: TaskNotificationStatusPropsType,
           currentIndex: number): number => {
            return lastIndex === -1 && currentStep.status === "passed"
              ? currentIndex
              : lastIndex;
          },
          -1
        );
        setCurrentStep(lastIndex >= 0 ? lastIndex + 1 : -1);
      }
    }
  };

  const showModal = (): void => {
    setIsModalOpen(true);
  };

  const openNotification = (): void => {
    const isFinalStepSuccess: boolean = statusTask?.statuses?.[7]?.status === "passed";
    const isSuccess: boolean = finalizeTaskStatus === 200
      && !!documentTask
      && isCompletedSuccessfully
      && isFinalStepSuccess;
    const isError: boolean = finalizeTaskStatus === 200 && !!documentTask && is1CError;

    if (isSuccess) {
      api.success({
        message: task_type_id
          ? `Задача №${number} завершена`
          : `Валидация Анкеты по задаче  №${number} завершена`,
        description: !task_type_id
          ? `Анкета переведена в статус: ${parseStatusText(status_task)}`
          : null,
        duration: 0,
        style: {
          width: 500,
        },
      });
    }

    if (isError) {
      api.error({
        icon: <ErrorIcon className="flex-shrink-0 w-6 h-6"/>,
        message: <div className={css.error1C}>Произошла ошибка при автоматическом создании карточки в 1С</div>,
        description: <div className={css.error1CText}>Необходимо ввести данные вручную</div>,
        duration: 5,
        style: {
          width: 500,
        },
      });
    }
  };

  const openErrorNotification = (error: string): void => {
    api.error({
      message: task_type_id ? "Ошибка завершения задачи" : "Ошибка валидация задачи",
      description: task_type_id ? error : "Требуется валидировать все поля",
      duration: 0
    });
  };

  useEffect(() => {
    const isTaskSuccessful: boolean = finalizeTaskStatus === 200 && getDocumentTaskStatus === 200 && !!documentTask;

    if (error) {
      openErrorNotification(error);
      dispatch(finalizeTaskFailure());
    }

    if (isTaskSuccessful) {
      setTimeout(() => openNotification(), 1100);

      dispatch(finalizeTaskFailure());
    }
  }, [error, finalizeTaskStatus, getDocumentTaskStatus, documentTask]);

  const showApplicationModal = (): void => {
    setApplicationModal(true);
  };

  const showFinishModal = (): void => {
    setFinishTaskModal(true);
  };

  const closeFinishModal = (): void => {
    setFinishTaskModal(false);
  };

  const finishModalOk = (): void => {
    dispatch(finalizeClientTask(uuid ?? ""));
    setFinishTaskModal(false);
  };

  const finishModalTitle: JSX.Element = (
    <div className={css.modalTitle}>
      Завершение задачи
    </div>
  );

  const finishModalFooter: JSX.Element = (
    <>
      <div key="modalBotton" className="border-t border-gray mt-5"/>
      <ButtonCustom
        className={`${css.buttonBack} mt-4 mr-2`}
        key="cancel"
        size="large"
        type="default"
        text="Назад"
        onClick={closeFinishModal}
      />
      <ButtonCustom
        key="ok"
        size="large"
        text="Подтвердить"
        type="primary"
        onClick={finishModalOk}
      />
    </>
  );

  const isCheckFirstHistory = (actionHistory: ActionHistoryType | null): boolean => {
    if (Array.isArray(actionHistory?.results) && actionHistory?.results?.length === historyAction.onlyOne) {
      const item: ActionHistoryResultsType = actionHistory?.results?.[0];

      const systemMessage: boolean = item?.fields
        && item?.fields?.decision_maker === null
        && item?.fields?.task_decision_type === "system_message";

      if (systemMessage) {
        return true;
      }
    }

    return false;
  };

  const accessTaskAction = (): boolean => {
    if (isTaskFinalised || !isCheckAccess()) {
      return true;
    }

    const taskTypeId: number = Number(task_type_id);
    const isHundredSeriesTask: boolean = !isNaN(taskTypeId) && taskTypeId >= 100 && taskTypeId <= 199;
    const hasDecisionMakerMessage: boolean | undefined =
      actionHistory?.results?.some((result: ActionHistoryResultsType) => !result?.fields.decision_maker);

    switch (taskType) {
      case "Списание":
        return true;
      case "Наличные операции":
        return !(adminRole || accountantRole);
      case "Исходящая задача":
        return !!(!isHundredSeriesTask && hasDecisionMakerMessage && !isCheckFirstHistory(actionHistory));
      default:
        return false;
    }
  };

  const taskComplete: boolean = isFinishTaskDisabled || !isCheckAccess() || separateTaskType;

  useEffect(() => {
    setActiveKey("1");
  }, [path]);

  const handleTabChange = (key: string): void => {
    setActiveKey(key);
  };

  useEffect(() => {
    if (isTaskTariffIncrease) {
      form.setFieldsValue({
        paidConsultation: additional_number_of_consultations ?? 0,
        accountingRestoration: additional_accounting_restore_months ?? 0,
        addingEmployees: additional_number_of_employees ?? 0,
        addAgencyAgreements: additional_agency_agreements_qty ?? 0,
        textTariff: "",
      });
    }

    if (isTaskTerminationService) {
      form.setFieldsValue({
        terminateReason: task_reason ?? "",
      });
    }
  }, [form, documentTask]);

  const isAccessReviewApplication: boolean = !isTaskFinalised && isCheckAccess();

  return (isLoading ? (
    <Form
      form={form}
      className={css.container}
    >
      {contextHolder}
      <div className={isAdministrator ? css.cardsHeaderAdmin : css.cardsHeader}>
        {isAdministrator ? (
          <div>
            <div>
              <ButtonCustom
                className={css.buttonReturn}
                type="link"
                text="Назад"
                onClick={returnToTasksOrClientCard}
              >
                <ArrowLeft />
              </ButtonCustom>
              <div className="flex">
                <h1 className={css.header}>Задача №{number}</h1>
                <span>{parseStatus(status_task)}</span>
              </div>
            </div>
            <div className="flex mt-1">
              {!isApplicationTask ? (
                <>
                  <ButtonCustom
                    className={css.buttonAction}
                    type="default"
                    text="Действия по задаче"
                    htmlType="button"
                    onClick={showModal}
                    disabled={accessTaskAction() || isHistoryStepsBlockLoading || isClarificationStatus}
                  />
                  <ButtonCustom
                    className={css.button}
                    type="primary"
                    text="Завершить задачу"
                    htmlType="button"
                    disabled={taskComplete || isHistoryStepsBlockLoading || isClarificationStatus}
                    onClick={showFinishModal}
                  />
                </>
              ) : (
                <ButtonCustom
                  className={css.button}
                  type="primary"
                  text="Рассмотреть заявку"
                  htmlType="button"
                  onClick={showApplicationModal}
                  disabled={!isAccessReviewApplication || isHistoryStepsBlockLoading}
                />
              )}
            </div>
          </div>
        ) : (
          <div>
            <ButtonCustom
              className={css.buttonReturn}
              type="link"
              text="Назад"
              onClick={returnToTasksOrClientCard}
            >
              <ArrowLeft/>
            </ButtonCustom>
            <div className="flex">
              <h1 className={css.header}>Задача №{number}</h1>
              <span>{parseStatus(status_task)}</span>
            </div>
          </div>
        )}
        <Tabs
          className="mb-[18px]"
          defaultActiveKey="1"
          activeKey={activeKey}
          items={itemsTabs}
          onChange={handleTabChange}
        />
      </div>
      <ModelContent
        documentTask={documentTask}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setUpdateHistory={setUpdateHistory}
        actionHistory={actionHistory}
        currentTaskType={taskType}
        setClarificationStatus={setClarificationStatus}
        setCheckClarification={setCheckClarification}
      />
      <Modal
        title={finishModalTitle}
        open={finishTaskModal}
        onOk={finishModalOk}
        onCancel={closeFinishModal}
        footer={finishModalFooter}
      >
        <p className={css.modalText}>
          Вы уверены, что хотите завершить эту задачу?
        </p>
      </Modal>
      <ModalApplication
        isModalOpen={applicationModal}
        setIsModalOpen={setApplicationModal}
        formApplication={form}
      />
    </Form>
  ) : (
    <div className="text-center mt-96">
      <LoadingCustom/>
    </div>
  ));
};

export default TaskCards;
