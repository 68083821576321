export const deadlineDays = (data: string | undefined): string | null => {
  if (data) {
    const targetDate: Date = new Date(data);
    const now: Date = new Date();

    if (targetDate > now) {
      const delta: number = targetDate.getTime() - now.getTime();
      const millisecondsPerDay: number = 24 * 60 * 60 * 1000;
      const remainingDays: number = Math.round(delta / millisecondsPerDay);

      let daysWord: string;
      let verbForm: string;

      if (remainingDays === 1) {
        daysWord = "день";
        verbForm = "остался";
      } else if (remainingDays % 10 === 1 && remainingDays !== 11) {
        daysWord = "день";
        verbForm = "остался";
      } else if (remainingDays % 10 >= 2
         && remainingDays % 10 <= 4
         && !(remainingDays >= 10 && remainingDays <= 20)
      ) {
        daysWord = "дня";
        verbForm = "осталось";
      } else {
        daysWord = "дней";
        verbForm = "осталось";
      }

      const remainingDaysNumber: number = parseInt(remainingDays.toString().slice(-2));

      if (remainingDaysNumber >= 10 && remainingDaysNumber <= 20) {
        daysWord = "дней";
      }

      return `${verbForm} ${remainingDays} ${daysWord}`;
    } else {
      return "срок истек";
    }
  }

  return null;
};
