import { JSX } from "react";
import dayjs from "dayjs";
import TagCustom from "../components/ui-kit/TagCustom/TagCustom";
import { TagCustomColorType } from "app/types";

const DeadLine = (data: string | undefined, isReportPage?: boolean): string | null | JSX.Element => {
  if (data) {
    const targetDate: Date = new Date(data);
    const now: Date = new Date();

    if (targetDate > now) {
      const delta: number = targetDate.getTime() - now.getTime();
      const millisecondsPerDay: number = 24 * 60 * 60 * 1000;
      const remainingDays: number = Math.round(delta / millisecondsPerDay);

      let daysWord: string;

      if (remainingDays === 1) {
        daysWord = "день";
      } else if (remainingDays % 10 === 1 && remainingDays !== 11) {
        daysWord = "день";
      } else if (
        remainingDays % 10 >= 2 &&
        remainingDays % 10 <= 4 &&
        (remainingDays < 10 || remainingDays > 20)
      ) {
        daysWord = "дня";
      } else {
        daysWord = "дней";
      }

      let verbForm: string;
      if (remainingDays === 1) {
        verbForm = "остался";
      } else if (remainingDays % 10 === 1 && remainingDays !== 11) {
        verbForm = "остался";
      } else {
        verbForm = "осталось";
      }

      let tagCustomColor: TagCustomColorType;
      if (remainingDays > 60) {
        tagCustomColor = "customSuccess";
      } else if (remainingDays > 30 && remainingDays <= 60) {
        tagCustomColor = "customWarning";
      } else {
        tagCustomColor = "customError";
      }

      const remainingDaysSentence: JSX.Element =
        <TagCustom
          className="ml-3 px-1.5 py-0"
          color={tagCustomColor}
          text={`${verbForm} ${remainingDays} ${daysWord}`}
        />;

      return isReportPage
        ? remainingDaysSentence
        : (
          <span style={{ display: "flex", alignItems: "center" }}>
            до {dayjs(data)?.format("DD.MM.YYYY")} {remainingDaysSentence}
          </span>
        );
    }
    else {
      const overdue: JSX.Element =
        <TagCustom
          className="ml-3 px-1.5 py-0"
          color="customError"
          text="просрочено"
        />;
      
      return isReportPage
        ? overdue
        : (
          <span style={{ display: "flex", alignItems: "center" }}>
            до {dayjs(data)?.format("DD.MM.YYYY")} {overdue}
          </span>
        );
    }
  }

  return null;
};

export default DeadLine;
