import React, {
  FC,
  JSX,
  Key,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { Link } from "react-router-dom";
import { AbsenteesListType, AbsentType, ProfileAbsenteesListParamsType } from "app/types";
import {
  Table,
  Button,
  Dropdown,
  MenuProps,
  TableColumnsType,
} from "antd";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings-icon.svg";
import { useTableSorter } from "../../../../utils/handleSorterTable";
import { PaginationCustom } from "../../../ui-kit/PaginationCustom/PaginationCustom";
import EmptyList from "../../../ui-kit/EmptyList/EmptyList";
import ProfileAbsenteesRemoveModal from "./ProfileAbsenteesRemoveModal";
import dayjs from "dayjs";
import * as DocumentAPI from "../../../../api/document.api";
import { JWTPayload } from "jose";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import { roleResolver } from "../../../../utils/roleResolver";
import css from "../ProfileAbsenteesList.module.css";

interface IAbsenteesData {
  key: Key;
  replacement_end_date: string;
  replacement_start_date: string;
  updated_at: string;
  substitutes: AbsentType[];
  substitution_reason: string;
}

interface IProfileAbsenteesTableProps {
  absenteesKey: Key | null;
  absenteesList: AbsenteesListType | null;
  setAbsenteesKey: Dispatch<SetStateAction<Key | null>>;
  setAbsentEdited: Dispatch<SetStateAction<boolean>>;
  setAbsenteesList: Dispatch<SetStateAction<AbsenteesListType | null>>;
  createAbsentStatus: number | null;
  setCreateOrUpdateModalOpen: Dispatch<SetStateAction<boolean>>
  performersUuid?: string | null;
}

const ProfileAbsenteesTable: FC<IProfileAbsenteesTableProps> = ({
  absenteesKey,
  absenteesList,
  setAbsenteesKey,
  setAbsentEdited,
  setAbsenteesList,
  createAbsentStatus,
  setCreateOrUpdateModalOpen,
  performersUuid
}): JSX.Element => {
  const [absenteesListParams, setAbsenteesListParams] = useState<ProfileAbsenteesListParamsType | null>({
    page: 1,
    page_size: 5,
    "absent.uuid": performersUuid
  });
  const [removeModalOpen, setRemoveModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteAbsentStatus, setDeleteAbsentStatus] = useState<number | null>(0);

  const [handleSorter] = useTableSorter(null, setAbsenteesListParams);

  const decodedToken: JWTPayload | null = useSelector((state: AppStateType) => state.account.decodedToken);

  const isAdmin: boolean = roleResolver(decodedToken).isAdmin;

  useEffect(() => {
    if (createAbsentStatus !== null || deleteAbsentStatus ! == null) {
      setIsLoading(true);

      DocumentAPI.getDocumentList("absence-substitution-schema", absenteesListParams)
      .then((response) => {
        setAbsenteesList(response.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Get absence-substitution error:", err);
      });
    }
  }, [absenteesListParams, createAbsentStatus, deleteAbsentStatus]);

  const handleAbsenteesPageChange = (newPage: number): void => {
    setAbsenteesListParams({
      ...absenteesListParams,
      page: newPage
    });
  };

  const handleAbsenteesPageSizeChange = (newPageSize: number): void => {
    setAbsenteesListParams({
      ...absenteesListParams,
      page: 1,
      page_size: newPageSize
    });
  };

  const removeAbsentee = (uuid: React.Key): void => {
    setRemoveModalOpen(true);
    setAbsenteesKey(uuid);
  };

  const updateAbsentee = (uuid: React.Key): void => {
    setAbsenteesKey(uuid);
    setAbsentEdited(true);
    setCreateOrUpdateModalOpen(true);
  };

  const renderFormatedDate = (text: string): string | null =>
    dayjs(text).isValid() ? dayjs(text).format("DD.MM.YYYY") : null;

  const renderTotalDocs = (total: number, range: number[]): string => `${range[0]}-${range[1]} из ${total}`;

  const renderAbsentTableTitle = (title: string): JSX.Element => {
    return (
      <div className="table-title">
        {title}
      </div>
    );
  };

  const headerSortHandler = (fieldName: string) => ({
      onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, fieldName)
    });

  const renderAbsentCardLink = (_: any, record: IAbsenteesData) => record.substitutes?.map(({ uuid, label }) => (
    <Link
      key={uuid}
      state={record}
      to={isAdmin ? `/profile/absent/${uuid}` : ""}
      className="block mb-1"
    >
      {label}
    </Link>
  ));

  const absenteesColumns: TableColumnsType<IAbsenteesData> = [
    {
      title: renderAbsentTableTitle("Дата начала"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "replacement_start_date",
      width: "15%",
      onHeaderCell: () => headerSortHandler("replacement_start_date"),
      render: renderFormatedDate,
    },
    {
      title: renderAbsentTableTitle("Дата окончания"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "replacement_end_date",
      width: "15%",
      onHeaderCell: () => headerSortHandler("replacement_end_date"),
      render: renderFormatedDate,
    },
    {
      title: renderAbsentTableTitle("Дата изменения"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "updated_at",
      width: "15%",
      onHeaderCell: () => headerSortHandler("updated_at"),
      render: renderFormatedDate,
    },
    {
      title: renderAbsentTableTitle("Замещающие"),
      dataIndex: "substitutes",
      sorter: true,
      showSorterTooltip: false,
      width: "25%",
      onHeaderCell: () => headerSortHandler("substitutes"),
      render: renderAbsentCardLink,
    },
    {
      title: renderAbsentTableTitle("Причина отсутствия"),
      dataIndex: "substitution_reason",
      sorter: true,
      showSorterTooltip: false,
      width: "25%",
      onHeaderCell: () => headerSortHandler("substitution_reason"),
    },
    {
      title: "",
      key: "settings",
      width: "5%",
      render: (_, record: IAbsenteesData) => {
        const menuItems: MenuProps["items"] = [
          {
            label: <div>Изменить</div>,
            key: "1",
            onClick: () => updateAbsentee(record?.key)
          },
          {
            label: <div>Удалить</div>,
            key: "2",
            danger: true,
            onClick: () => removeAbsentee(record?.key)
          }
        ];

        return (
          <Dropdown.Button
            menu={{ items: menuItems }}
            buttonsRender={([_leftButton, _rightButton]) => [
              null,
              <Button
                className={css.settingsBtn}
                key={record?.key}
                type="text"
                icon={<SettingsIcon />}
              />
            ]}
          />
        );
      }
    }
  ];

  const absenteesData: IAbsenteesData[] = absenteesList?.results?.map(({
    fields,
    updated_at,
  }) => {
    return {
      key: fields?.uuid,
      replacement_end_date: fields?.replacement_end_date,
      replacement_start_date: fields?.replacement_start_date,
      updated_at,
      substitutes: fields?.substitutes,
      substitution_reason: fields?.substitution_reason,
    };
  }) ?? [];

  return (
    <>
      <Table
        columns={absenteesColumns}
        dataSource={absenteesData}
        pagination={false}
        loading={isLoading}
        locale={{
          emptyText: <EmptyList activeKey="absentees" className="h-[35vh]" />
      }}
      />
      <PaginationCustom
        total={absenteesList?.total_docs}
        showTotal={renderTotalDocs}
        currentPage={absenteesList?.page}
        pageSize={absenteesList?.page_size ?? 0}
        handlePageChange={handleAbsenteesPageChange}
        handlePageSizeChange={handleAbsenteesPageSizeChange}
      />
      <ProfileAbsenteesRemoveModal
        absenteesKey={absenteesKey}
        setAbsenteesKey={setAbsenteesKey}
        removeModalOpen={removeModalOpen}
        setRemoveModalOpen={setRemoveModalOpen}
        deleteAbsentStatus={deleteAbsentStatus}
        setDeleteAbsentStatus={setDeleteAbsentStatus}
      />
    </>
  );
};

export default ProfileAbsenteesTable;
