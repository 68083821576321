import React, { JSX } from "react";
import { ReactComponent as ClientActive } from "../assets/icons/client_active.svg";
import { ReactComponent as ClientNotActive } from "../assets/icons/client_not_active.svg";
import { ReactComponent as ClientDraft } from "../assets/icons/client_draft.svg";

export const renderClientStatus = (value: string): JSX.Element | null => {
  switch (value) {
    case "ACTIVE":
      return <ClientActive/>;
    case "DEACTIVE":
      return <ClientNotActive/>;
    case "DRAFT":
      return <ClientDraft/>;
    default:
      return null;
  }
};