export type CatalogTaskStatusType = {
  status: string;
  label: string;
}

export const taskStatus: CatalogTaskStatusType[] = [
  {status: "NEW", label: "Новая"},
  {status: "IN_PROGRESS", label: "В работе"},
  {status: "BOOKED", label: "Назначена"},
  {status: "FIN_TRUE", label: "Исполнена"},
  {status: "FIN_FALSE", label: "Не исполнена"},
  {status: "PAUSE", label: "Приостановлена"},
  {status: "1C_ERROR", label: "Ошибка синхронизации с 1С"}
];

export const taskType: CatalogTaskStatusType[] = [
  {status: "301", label: "Онбординг"},
  {status: "100-300", label: "Исходящая задача"},
  {status: "302", label: "Обращение"},
  {status: "403", label: "Списание"},
  {status: "303", label: "Разметка операций"},
  {status: "204", label: "Расчет заработной платы"},
  {status: "203", label: "Расчет аванса"},
  {status: "315", label: "Наличные операции"},
];

export const clientStatus: CatalogTaskStatusType[] = [
  {status: "Проспект", label: "Подписана оферта"},
  {status: "Оплачено", label: "Оплачен сервис"},
  {status: "Принят на обслуживание", label: "Принят на обслуживание"},
  {status: "Снят с обслуживания", label: "Снят с обслуживания"},
];