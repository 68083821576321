import React, {
  FC,
  JSX,
  Key,
  Dispatch,
  useState,
  useEffect,
  SetStateAction,
} from "react";
import { useSelector } from "react-redux";
import { AppStateType } from "../../../../reducers/mainReducer";
import {
  Form,
  Select,
  GetProps,
  FormProps,
  DatePicker,
  SelectProps,
  FormInstance,
  notification,
} from "antd";
import {
  UsersListingFilterType,
  AllUsersListingType,
  AttributesTypeProps,
  SelectOptionsType,
  AbsenteesFormType,
  AbsenteesListType,
  IJWTPayloadProps,
  UserListingType,
  AbsenteesType,
  AbsentType,
  SubstitutesType
} from "app/types";
import { AxiosResponse } from "axios";
import { createNewDocument, updateDocument } from "../../../../api/document.api";
import { getUsersListing } from "../../../../api/account.api";
import { ButtonCustom } from "../../../ui-kit/ButtonCustom/ButtonCustom";
import { createAlphaDocStatus } from "../../../../utils/createAlphaDocStatus";
import LoadingCustom from "../../../ui-kit/LoadingCustom/LoadingCustom";
import qs from "qs";
import dayjs from "dayjs";
import debounce from "debounce";
import * as DocumentAPI from "../../../../api/document.api";
import css from "../../../Alpha/Vacancy/CreateVacancy/CreateVacancy.module.css";
import isBetween from "dayjs/plugin/isBetween";
import { useLocation } from "react-router-dom";
dayjs.extend(isBetween);

interface IProfileCreateOrEditAbsentFormProps {
  absentUuid?: React.Key | null;
  absenteesList: AbsenteesListType | null;
  isAbsentEdited?: boolean;
  closeAbsentForm: () => void;
  setAbsenteesKey: Dispatch<SetStateAction<Key | null>>
  createAbsentStatus: number | null;
  setCreateAbsentStatus: Dispatch<SetStateAction<number | null>>;
  setCreateOrUpdateModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedUserRoles?: string[] | null;
}

const absenceReasons: SelectProps["options"] = [
  {
    value: "Отпуск",
    label: "Отпуск",
  },
  {
    value: "Больничный",
    label: "Больничный",
  },
  {
    value: "Прочее",
    label: "Прочее",
  },
];

const debouncedGetUsersListing: debounce.DebouncedFunction<(
  params: string,
  onSuccess: (value: AxiosResponse<AllUsersListingType>) => void,
  onError: any,
) => void> = debounce((params, onSuccess, onError): void => {
    getUsersListing(params)
    .then(onSuccess)
    .catch(onError);
}, 1000);

const { RangePicker } = DatePicker;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const ProfileCreateOrEditAbsentForm: FC<IProfileCreateOrEditAbsentFormProps> = ({
  absentUuid,
  absenteesList,
  closeAbsentForm,
  isAbsentEdited,
  setAbsenteesKey,
  createAbsentStatus,
  setCreateAbsentStatus,
  setCreateOrUpdateModalOpen,
  selectedUserRoles
}): JSX.Element | boolean => {
  const [isAbsentLoaded, setIsAbsentLoaded] = useState<boolean>(false);
  const [absentUser, setAbsentUser] = useState<AbsenteesType | null>(null);
  const [isFormHasValues, setFormHasValues] = useState<boolean>(false);
  const [replacementSelectionUser, setReplacementSelectionUser] = useState<UserListingType[]>([]);
  const [searchEmployeeValue, setSearchEmployeeValue] = useState<string | null>(null);
  const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
  const [getUserErrMessage, setGetUserErrMessage] = useState<string>("");
  const [replacementDatesList, setReplacementDatesList] = useState<[string, string][]>([]);

  const [form]: [FormInstance<AbsenteesFormType>] = Form.useForm();
  const values: AbsenteesFormType | null = Form.useWatch([], form) ?? null;
  const path: string = useLocation().pathname;
  const isPerformers: boolean = path.includes("performers");
  const performerUuid: string | undefined = path.split("/").pop();

  const decodedToken: IJWTPayloadProps | null = useSelector((state: AppStateType) => state.account.decodedToken);

  useEffect(() => {
    const replacementDates: [string, string][] = absenteesList?.results?.map((absent: AbsenteesType) => {
      return [
        absent?.fields?.replacement_start_date ?? "",
        absent?.fields?.replacement_end_date ?? "",
      ];
    }) ?? [];

    setReplacementDatesList(replacementDates);
  }, [absenteesList]);

  useEffect((): void => {
    createAlphaDocStatus(
      createAbsentStatus,
      createAbsentSuccess,
      createAbsentError,
    );
  }, [createAbsentStatus]);

  useEffect((): void => {
    if (values) {
      const hasNoValue: boolean = Object.values(values)
        .some((value: string | string[]): boolean => typeof value === "string" ? !value : !value?.length);

      setFormHasValues(hasNoValue);
    }
  }, [values]);

  useEffect(() => {
    setIsUsersLoading(true);

    const params: UsersListingFilterType = {
      page_size: 100,
      roles: selectedUserRoles?.length ? selectedUserRoles : decodedToken?.resource_access?.bc?.roles,
      ...(searchEmployeeValue ? { name: searchEmployeeValue } : null),
    };

    const queryString: string = qs.stringify(params, { arrayFormat: "repeat" });

    debouncedGetUsersListing(queryString,
      (response: any) => {
        setIsUsersLoading(false);
        setReplacementSelectionUser(response.data.results as UserListingType[]);
      },
      (err: any) => {
        setIsUsersLoading(false);
        console.error("Get users error:", err);
      },
    );

    return (): void => debouncedGetUsersListing.clear();
  }, [searchEmployeeValue]);

  useEffect(() => {
    if (absentUuid) {
      setIsAbsentLoaded(true);

      DocumentAPI.getEmployeeDocument("absence-substitution-schema", String(absentUuid))
      .then((response) => {
        setAbsentUser(response.data);
      })
      .catch((err) => {
        console.error("Get absence-substitution error:", err);
      })
      .finally(() => setIsAbsentLoaded(false));
    }
  }, [absentUuid]);

  useEffect(() => {
    if (absentUser) {
      const substitutesData: SubstitutesType[] = absentUser?.fields?.substitutes?.map(({ uuid, label }: AbsentType) => {
         return {
           key: uuid,
           value: uuid,
           label: label,
         };
       }) ?? [];
   
      form.setFieldValue("substitutes" as any, substitutesData);
    }
  }, [absentUser]);

  const formatLabel = (attributes: AttributesTypeProps): string => {
    const { position, last_name, first_name, second_name } = attributes || {};

    return [
      position,
      last_name,
      first_name,
      second_name,
    ]
    .filter(Boolean)
    .join(" ");
  };

  const replacementSelectionUserOptions: SelectOptionsType[] = replacementSelectionUser
    ?.filter(({ id }: UserListingType): boolean => id !== decodedToken?.sub)
    ?.map(({ id, attributes }: UserListingType) => {
      return {
        key: id,
        value: id,
        label: <span>{formatLabel(attributes)}</span>,
      };
    }) ?? [];

  const onCreatAbsentFinish: FormProps["onFinish"] = async (values: AbsenteesFormType): Promise<void> => {
    const newAbsentDoc: AbsenteesFormType = {
      ...values,
      strategy_type: absentUuid ? "update" : "create",
      replacement_end_date: dayjs(values.replacement_date?.[1]).utc(true).format(),
      replacement_start_date: dayjs(values.replacement_date?.[0]).utc(true).format(),
      ...(isPerformers ? { absent: performerUuid } : null),
    };

    try {
      if (absentUuid) {
        const { status } = await updateDocument(
          "absence-substitution-schema",
          absentUuid?.toString() ?? "",
          newAbsentDoc
        );

        setCreateAbsentStatus(status);
      } else {
        const { status } = await createNewDocument("absence-substitution-schema", newAbsentDoc);

        setCreateAbsentStatus(status);
      }
    } catch (error: any) {
      setGetUserErrMessage(error.response.data?.error);
      setCreateAbsentStatus(error.response.status);

      console.error("Create absent error:", error.response.data);
    }
  };

  const createAbsentSuccess = (): void => {
    notification.success({
      message: "Замещающий сотрудник назначен",
    });

    setAbsentUser(null);
    setAbsenteesKey(null);
    setCreateAbsentStatus(null);
    setCreateOrUpdateModalOpen(false);
  };

  const createAbsentError = (): void => {
    notification.error({
      message: createAbsentStatus === 500
        ? "Ошибка назначения замещающего сотрудника"
        : getUserErrMessage,
    });

    setGetUserErrMessage("");
    setCreateAbsentStatus(null);
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current: dayjs.Dayjs): boolean => {
    // Запрещаем выбрать дату в прошлом
    if (current && current.isBefore(dayjs().startOf("day"))) {
      return true;
    }

    // Проверяем, находится ли текущая дата внутри какого-либо из диапазонов
    return replacementDatesList.some(([start, end]) => {
      const startDate: dayjs.Dayjs = dayjs(start);
      const endDate: dayjs.Dayjs = dayjs(end);
      const hasReplacementDates: boolean = current.isBetween(startDate, endDate)
        || current.isSame(startDate)
        || current.isSame(endDate);

      return hasReplacementDates && !absentUser;
    });
  };

  const isFormLoaded: boolean = !isAbsentLoaded && (!isAbsentEdited || !!absentUser);

  const userSubstitutes: SelectOptionsType[] = absentUser?.fields?.substitutes
  ?.map(({ uuid, label }: AbsentType) => {
    return {
      key: uuid,
      value: uuid,
      label: label,
    };
  }) ?? [];

  const handleSearchEmployees = (searchValue: string): void => {
    setSearchEmployeeValue(searchValue?.trim() || null);
  };

  const initialUserReplacementDates: dayjs.Dayjs[] | null = absentUser
    ? [dayjs(absentUser?.fields?.replacement_start_date), dayjs(absentUser?.fields?.replacement_end_date)]
    : null;

  return (
    isFormLoaded ? (
      <Form
        form={form}
        name="createOrUpdateAbsent"
        layout="vertical"
        onFinish={onCreatAbsentFinish}
      >
        <Form.Item
          className="w-full mb-4"
          id="replacement_date"
          name="replacement_date"
          label="Период отсутствия"
          initialValue={initialUserReplacementDates}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите дату окончания",
            },
          ]}
        >
          <RangePicker
            className="w-full"
            format="DD.MM.YYYY"
            size="large"
            allowClear
            placeholder={["Дата начала", "Дата окончания"]}
            disabledDate={disabledDate}
          />
        </Form.Item>
        <Form.Item
          className="w-full mb-4"
          id="substitution_reason"
          name="substitution_reason"
          label="Причина отсутствия"
          initialValue={absentUser?.fields?.substitution_reason}
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите причину отсутствия",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="Выберите причину"
            allowClear
            filterOption={false}
            options={absenceReasons}
          />
        </Form.Item>
        <Form.Item
          className="w-full mb-4"
          id="substitutes"
          name="substitutes"
          label="Замещающие сотрудники на время отсутствия"
          initialValue={userSubstitutes}
          rules={[
            {
              required: true,
              message: "Пожалуйста, выберите замещающих сотрудников",
            },
          ]}
        >
          <Select
            placeholder="Найдите и выберите сотрудников по ФИО"
            options={replacementSelectionUserOptions}
            searchValue={searchEmployeeValue as string}
            showSearch
            allowClear
            size="large"
            mode="multiple"
            filterOption={false}
            loading={isUsersLoading}
            onSearch={handleSearchEmployees}
          />
        </Form.Item>
        <div className="text-right">
          <ButtonCustom
            className={`${css.buttonBack} mr-2`}
            key="cancel"
            size="large"
            type="default"
            text={!absentUuid ? "Отменить" : "Назад"}
            onClick={closeAbsentForm}
          />
          <ButtonCustom
            key="ok"
            size="large"
            text={!absentUuid ? "Подтвердить" : "Сохранить"}
            type="primary"
            htmlType="submit"
            className={css.buttonOk}
            disabled={isFormHasValues || isUsersLoading}
          />
        </div>
      </Form>
    ) : <LoadingCustom className="m-48"/>
  );
};

export default ProfileCreateOrEditAbsentForm;
