import React, { FC, JSX, useRef, useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";

interface IModalDraggableProps {
  disabled: boolean;
  modal: React.ReactNode;
}

type BoundType = {
  left: number;
  top: number;
  bottom: number;
  right: number;
};

const ModalDraggable: FC<IModalDraggableProps> = ({
  disabled,
  modal,
}): JSX.Element => {
  const [bounds, setBounds] = useState<BoundType>({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  
  const onStart = (_event: DraggableEvent, uiData: DraggableData): void => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect: DOMRect | undefined = draggleRef?.current?.getBoundingClientRect();
    
    if (!targetRect) {
      return;
    }
    
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };
  
  return (
    <Draggable
      disabled={disabled}
      bounds={bounds}
      nodeRef={draggleRef}
      onStart={onStart}
    >
      <div ref={draggleRef}>{modal}</div>
    </Draggable>
  );
};

export default ModalDraggable;