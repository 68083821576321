// Account actions
export const LOGOUT = "LOGOUT";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
export const FETCH_USER_INFO_SUCCESS = "FETCH_USER_INFO_SUCCESS";
export const FETCH_USER_INFO_FAILURE = "FETCH_USER_INFO_FAILURE";
export const GET_USER_FULL_INFO_SUCCESS = "GET_USER_FULL_INFO_SUCCESS";
export const GET_USER_FULL_INFO_FAILURE = "GET_USER_FULL_INFO_FAILURE";
export const UPDATE_USER_FULL_INFO_SUCCESS = "UPDATE_USER_FULL_INFO_SUCCESS";
export const UPDATE_USER_FULL_INFO_FAILURE = "UPDATE_USER_FULL_INFO_FAILURE";
export const GET_USER_ROLE_LIST_SUCCESS = "GET_USER_ROLE_LIST_SUCCESS";
export const GET_USER_ROLE_LIST_FAILURE = "GET_USER_ROLE_LIST_FAILURE";
export const GET_USER_ROLE_SUCCESS = "GET_USER_ROLE_SUCCESS";
export const GET_USER_ROLE_FAILURE = "GET_USER_ROLE_FAILURE";
export const GET_USER_TOKEN_DECRYPTION_SUCCESS = "GET_USER_TOKEN_DECRYPTION_SUCCESS";
export const GET_USER_TOKEN_DECRYPTION_FAILURE = "GET_USER_TOKEN_DECRYPTION_FAILURE";
export const GET_USERS_LISTING_SUCCESS = "GET_USERS_LISTING_SUCCESS";
export const GET_USERS_LISTING_FAILURE = "GET_USERS_LISTING_FAILURE";
export const DECODE_TOKEN = "DECODE_TOKEN";
export const GET_PERFORMERS_LISTING_SUCCESS = "GET_PERFORMERS_LISTING_SUCCESS";
export const GET_PERFORMERS_LISTING_FAILURE = "GET_PERFORMERS_LISTING_FAILURE";
export const GET_CLIENTS_SERVED_SUCCESS = "GET_CLIENTS_SERVED_SUCCESS";
export const GET_CLIENTS_SERVED_FAILURE = "GET_CLIENTS_SERVED_FAILURE";
export const GET_CLIENTS_SERVED_PAGE_SIZE = "GET_CLIENTS_SERVED_PAGE_SIZE";
export const GET_CLIENTS_SERVED_PAGE_NUMBER = "GET_CLIENTS_SERVED_PAGE_NUMBER";

// Task actions 
export const GET_SCHEMA_TASK_SUCCESS = "GET_SCHEMA_TASK_SUCCESS";
export const GET_SCHEMA_TASK_FAILURE = "GET_SCHEMA_TASK_FAILURE";
export const GET_TASKS_LISTING_SUCCESS = "GET_TASKS_LISTING_SUCCESS";
export const GET_TASKS_LISTING_FAILURE = "GET_TASKS_LISTING_FAILURE";
export const CREATE_NEW_TASK_SUCCESS = "CREATE_NEW_TASK_SUCCESS";
export const CREATE_NEW_TASK_FAILURE = "CREATE_NEW_TASK_FAILURE";
export const GET_DOCUMENT_TASK_SUCCESS = "GET_DOCUMENT_TASK_SUCCESS";
export const GET_DOCUMENT_TASK_FAILURE = "GET_DOCUMENT_TASK_FAILURE";
export const GET_STATUS_TASK_SUCCESS = "GET_STATUS_TASK_SUCCESS";
export const GET_STATUS_TASK_FAILURE = "GET_STATUS_TASK_FAILURE";
export const GET_TASKS_PAGE_SIZE = "GET_TASKS_PAGE_SIZE";
export const GET_TASKS_PAGE_NUMBER = "GET_TASKS_PAGE_NUMBER";
export const SEND_TYPICAL_TASK_DECISION_SUCCESS = "SEND_TYPICAL_TASK_DECISION_SUCCESS";
export const SEND_TYPICAL_TASK_DECISION_FAILURE = "SEND_TYPICAL_TASK_DECISION_FAILURE";
export const GET_ACTION_HISTORY_SUCCESS = "GET_ACTION_HISTORY_SUCCESS";
export const GET_ACTION_HISTORY_FAILURE = "GET_ACTION_HISTORY_FAILURE";
export const GET_CLIENT_TASK_LISTING_SUCCESS = "GET_CLIENT_TASK_LISTING_SUCCESS";
export const GET_CLIENT_TASK_LISTING_FAILURE = "GET_CLIENT_TASK_LISTING_FAILURE";
export const GET_PROCESS_FILE_AUTOMATICALLY_SUCCESS = "GET_PROCESS_FILE_AUTOMATICALLY_SUCCESS";
export const GET_PROCESS_FILE_AUTOMATICALLY_FAILURE = "GET_PROCESS_FILE_AUTOMATICALLY_FAILURE";
export const GET_MARKUP_TASK_FILE_AUTOMATICALLY_SUCCESS = "GET_MARKUP_TASK_FILE_AUTOMATICALLY_SUCCESS";
export const GET_MARKUP_TASK_FILE_AUTOMATICALLY_FAILURE = "GET_MARKUP_TASK_FILE_AUTOMATICALLY_FAILURE";
export const TASK_LISTING_SORTER_DATA = "TASK_LISTING_SORTER_DATA";
export const TASK_LISTING_FILTER_DATA = "TASK_LISTING_FILTER_DATA";
export const TASK_LISTING_PARAMS_DATA = "TASK_LISTING_PARAMS_DATA";
export const TASK_LISTING_TAG_KEY = "TASK_LISTING_TAG_KEY";
export const TASK_LISTING_LOADING_DEFAULT = "TASK_LISTING_LOADING_DEFAULT";
export const GET_UUID_TASK_1C_SUCCESS = "GET_UUID_TASK_1C_SUCCESS";
export const GET_UUID_TASK_1C_FAILURE = "GET_UUID_TASK_1C_FAILURE";
export const GET_TASK_FILE_1C_SUCCESS = "GET_TASK_FILE_1C_SUCCESS";
export const GET_TASK_FILE_1C_FAILURE = "GET_TASK_FILE_1C_FAILURE";
export const GET_TASK_STEPS_INFO_SUCCESS = "GET_TASK_STEPS_INFO_SUCCESS";
export const GET_TASK_STEPS_INFO_FAILURE = "GET_TASK_STEPS_INFO_FAILURE";
export const REPEAT_TASK_STEP_SUCCESS = "REPEAT_TASK_STEP_SUCCESS";
export const REPEAT_TASK_STEP_FAILURE = "REPEAT_TASK_STEP_FAILURE";
export const CLEAR_TASKS_NOTIFICATION = "CLEAR_TASKS_NOTIFICATION";

// Client actions 
export const GET_SCHEMA_CLIENT_SUCCESS = "GET_SCHEMA_CLIENT_PROFILE_SUCCESS";
export const GET_SCHEMA_CLIENT_FAILURE = "GET_SCHEMA_CLIENT_PROFILE_FAILURE";
export const GET_CLIENT_LISTING_SUCCESS = "GET_CLIENT_LISTING_SUCCESS";
export const GET_CLIENT_LISTING_FAILURE = "GET_CLIENT_LISTING_FAILURE";
export const GET_CLIENTS_PAGE_SIZE = "GET_CLIENTS_PAGE_SIZE";
export const GET_CLIENTS_PAGE_NUMBER = "GET_CLIENTS_PAGE_NUMBER";
export const GET_DOCUMENT_CLIENT_SUCCESS = "GET_DOCUMENT_CLIENT_PROFILE_SUCCESS";
export const GET_DOCUMENT_CLIENT_FAILURE = "GET_DOCUMENT_CLIENT_PROFILE_FAILURE";
export const GET_CLIENT_TARIFF_SUCCESS = "GET_CLIENT_TARIFF_SUCCESS";
export const GET_CLIENT_TARIFF_FAILURE = "GET_CLIENT_TARIFF_FAILURE";
export const GET_CLIENT_BANKS_DATA_SUCCESS = "GET_CLIENT_BANKS_DATA_SUCCESS";
export const GET_CLIENT_BANKS_DATA_FAILURE = "GET_CLIENT_BANKS_DATA_FAILURE";
export const SEND_CLIENT_REQUEST_SUCCESS = "SEND_CLIENT_REQUEST_SUCCESS";
export const SEND_CLIENT_REQUEST_FAILURE = "SEND_CLIENT_REQUEST_FAILURE";
export const GET_SCHEMA_LABOUR_REGULATION_SUCCESS = "GET_SCHEMA_LABOUR_REGULATION_SUCCESS";
export const GET_SCHEMA_LABOUR_REGULATION_FAILURE = "GET_SCHEMA_LABOUR_REGULATION_FAILURE";
export const CREAT_CLIENT_LABOUR_REGULATION_SUCCESS = "CREAT_CLIENT_LABOUR_REGULATION_SUCCESS";
export const CREAT_CLIENT_LABOUR_REGULATION_FAILURE = "CREAT_CLIENT_LABOUR_REGULATION_FAILURE";
export const CLIENT_LISTING_FILTER_DATA = "CLIENT_LISTING_FILTER_DATA";
export const CLIENT_LISTING_PARAMS_DATA = "CLIENT_LISTING_PARAMS_DATA";
export const CLIENT_LISTING_LOADING_DEFAULT = "CLIENT_LISTING_LOADING_DEFAULT";
export const CLIENT_CARD_TAG_KEY = "CLIENT_CARD_TAG_KEY";
export const CLIENT_BANKS_DATA_PAGE_NUMBER = "CLIENT_BANKS_DATA_PAGE_NUMBER";
export const CLIENT_BANKS_DATA_PAGE_SIZE = "CLIENT_BANKS_DATA_PAGE_SIZE";
export const CLIENT_BANKS_DATA_SORTER = "CLIENT_BANKS_DATA_SORTER";
export const CLIENT_BANKS_DATA_SEARCH = "CLIENT_BANKS_DATA_SEARCH";
export const GET_CLIENT_COMMENTS_DATA_SUCCESS = "GET_CLIENT_COMMENTS_DATA_SUCCESS";
export const GET_CLIENT_COMMENTS_DATA_FAILURE = "GET_CLIENT_COMMENTS_DATA_FAILURE";
export const SET_POPOVER_TARIFF_SWITCH = "SET_POPOVER_TARIFF_SWITCH";

// ClientProfile actions 
export const GET_SCHEMA_CLIENT_PROFILE_SUCCESS = "GET_SCHEMA_CLIENT_PROFILE_SUCCESS";
export const GET_SCHEMA_CLIENT_PROFILE_FAILURE = "GET_SCHEMA_CLIENT_PROFILE_FAILURE";
export const GET_DOCUMENT_CLIENT_PROFILE_SUCCESS = "GET_DOCUMENT_CLIENT_PROFILE_SUCCESS";
export const GET_DOCUMENT_CLIENT_PROFILE_FAILURE = "GET_DOCUMENT_CLIENT_PROFILE_FAILURE";
export const GET_NOT_AGREED_DOCUMENTS_PROFILE_SUCCESS = "GET_NOT_AGREED_DOCUMENTS_PROFILE_SUCCESS";
export const GET_NOT_AGREED_DOCUMENTS_PROFILE_FAILURE = "GET_NOT_AGREED_DOCUMENTS_PROFILE_FAILURE";
export const GET_UN_AGREED_DOCUMENT = "GET_UN_AGREED_DOCUMENT";

// Employee actions 
export const GET_SCHEMA_EMPLOYEE_SUCCESS = "GET_SCHEMA_EMPLOYEE_SUCCESS";
export const GET_SCHEMA_EMPLOYEE_FAILURE = "GET_SCHEMA_EMPLOYEE_FAILURE";
export const GET_DOCUMENT_EMPLOYEE_SUCCESS = "GET_DOCUMENT_EMPLOYEE_SUCCESS";
export const GET_DOCUMENT_EMPLOYEE_FAILURE = "GET_DOCUMENT_EMPLOYEE_FAILURE";
export const UPDATE_DOCUMENT_EMPLOYEE_SUCCESS = "UPDATE_DOCUMENT_EMPLOYEE_SUCCESS";
export const UPDATE_DOCUMENT_EMPLOYEE_FAILURE = "UPDATE_DOCUMENT_EMPLOYEE_FAILURE";
export const GET_EMPLOYEE_LISTING_SUCCESS = "GET_EMPLOYEE_LISTING_SUCCESS";
export const GET_EMPLOYEE_LISTING_FAILURE = "GET_EMPLOYEE_LISTING_FAILURE";
export const GET_EMPLOYEE_PAGE_SIZE = "GET_EMPLOYEE_PAGE_SIZE";
export const GET_EMPLOYEE_PAGE_NUMBER = "GET_EMPLOYEE_PAGE_NUMBER";
export const EMPLOYEE_LISTING_FILTER_DATA = "EMPLOYEE_LISTING_FILTER_DATA";
export const EMPLOYEE_LISTING_PARAMS_DATA = "EMPLOYEE_LISTING_PARAMS_DATA";
export const CLIENT_EMPLOYEE_LISTING_LOADING_DEFAULT = "CLIENT_EMPLOYEE_LISTING_LOADING_DEFAULT";

// EmployeeProfile actions 
export const GET_SCHEMA_EMPLOYEE_PROFILE_SUCCESS = "GET_SCHEMA_EMPLOYEE_PROFILE_SUCCESS";
export const GET_SCHEMA_EMPLOYEE_PROFILE_FAILURE = "GET_SCHEMA_EMPLOYEE_PROFILE_FAILURE";
export const GET_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS = "GET_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS";
export const GET_DOCUMENT_EMPLOYEE_PROFILE_FAILURE = "GET_DOCUMENT_EMPLOYEE_PROFILE_FAILURE";
export const UPDATE_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS = "UPDATE_DOCUMENT_EMPLOYEE_PROFILE_SUCCESS";
export const UPDATE_DOCUMENT_EMPLOYEE_PROFILE_FAILURE = "UPDATE_DOCUMENT_EMPLOYEE_PROFILE_FAILURE";

// Notifications actions
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";
export const READ_NOTIFICATIONS_SUCCESS = "READ_NOTIFICATIONS_SUCCESS";
export const READ_NOTIFICATIONS_FAILURE = "READ_NOTIFICATIONS_FAILURE";
export const GET_NOTIFICATIONS_NUMBER_SUCCESS = "GET_NOTIFICATIONS_NUMBER_SUCCESS";
export const GET_NOTIFICATIONS_NUMBER_FAILURE = "GET_NOTIFICATIONS_NUMBER_FAILURE";

// Document actions 
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_FAILURE = "UPDATE_DOCUMENT_FAILURE";
export const UPDATE_INN_DOCUMENT_SUCCESS = "UPDATE_INN_DOCUMENT_SUCCESS";
export const UPDATE_INN_DOCUMENT_FAILURE = "UPDATE_INN_DOCUMENT_FAILURE";
export const UPDATE_USN_DOCUMENT_SUCCESS = "UPDATE_USN_DOCUMENT_SUCCESS";
export const UPDATE_USN_DOCUMENT_FAILURE = "UPDATE_USN_DOCUMENT_FAILURE";
export const UPDATE_BANK_DOCUMENT_SUCCESS = "UPDATE_BANK_DOCUMENT_SUCCESS";
export const UPDATE_BANK_DOCUMENT_FAILURE = "UPDATE_BANK_DOCUMENT_FAILURE";
export const UPDATE_SNILS_DOCUMENT_SUCCESS = "UPDATE_SNILS_DOCUMENT_SUCCESS";
export const UPDATE_SNILS_DOCUMENT_FAILURE = "UPDATE_SNILS_DOCUMENT_FAILURE";
export const UPDATE_INCOME_DOCUMENT_SUCCESS = "UPDATE_INCOME_DOCUMENT_SUCCESS";
export const UPDATE_INCOME_DOCUMENT_FAILURE = "UPDATE_INCOME_DOCUMENT_FAILURE";
export const UPDATE_DECISION_DOCUMENT_SUCCESS = "UPDATE_DECISION_DOCUMENT_SUCCESS";
export const UPDATE_DECISION_DOCUMENT_FAILURE = "UPDATE_DECISION_DOCUMENT_FAILURE";
export const UPDATE_PASSPORT_DOCUMENT_SUCCESS = "UPDATE_PASSPORT_DOCUMENT_SUCCESS";
export const UPDATE_PASSPORT_DOCUMENT_FAILURE = "UPDATE_PASSPORT_DOCUMENT_FAILURE";
export const UPDATE_CONTRACT_DOCUMENT_SUCCESS = "UPDATE_CONTRACT_DOCUMENT_SUCCESS";
export const UPDATE_CONTRACT_DOCUMENT_FAILURE = "UPDATE_CONTRACT_DOCUMENT_FAILURE";
export const UPDATE_MIGRATION_DOCUMENT_SUCCESS = "UPDATE_MIGRATION_DOCUMENT_SUCCESS";
export const UPDATE_MIGRATION_DOCUMENT_FAILURE = "UPDATE_MIGRATION_DOCUMENT_FAILURE";
export const GET_SCHEMA_DOCUMENT_SUCCESS = "GET_SCHEMA_DOCUMENT_SUCCESS";
export const GET_SCHEMA_INN_DOCUMENT_SUCCESS = "GET_SCHEMA_INN_DOCUMENT_SUCCESS";
export const GET_SCHEMA_USN_DOCUMENT_SUCCESS = "GET_SCHEMA_USN_DOCUMENT_SUCCESS";
export const GET_SCHEMA_BANK_DOCUMENT_SUCCESS = "GET_SCHEMA_BANK_DOCUMENT_SUCCESS";
export const GET_SCHEMA_SNILS_DOCUMENT_SUCCESS = "GET_SCHEMA_SNILS_DOCUMENT_SUCCESS";
export const GET_SCHEMA_INCOME_DOCUMENT_SUCCESS = "GET_SCHEMA_INCOME_DOCUMENT_SUCCESS";
export const GET_SCHEMA_CONTRACT_DOCUMENT_SUCCESS = "GET_SCHEMA_CONTRACT_DOCUMENT_SUCCESS";
export const GET_SCHEMA_DECISION_DOCUMENT_SUCCESS = "GET_SCHEMA_DECISION_DOCUMENT_SUCCESS";
export const GET_SCHEMA_PASSPORT_DOCUMENT_SUCCESS = "GET_SCHEMA_PASSPORT_DOCUMENT_SUCCESS";
export const GET_SCHEMA_MIGRATION_DOCUMENT_SUCCESS = "GET_SCHEMA_MIGRATION_DOCUMENT_SUCCESS";
export const GET_SCHEMA_DOCUMENT_FAILURE = "GET_SCHEMA_DOCUMENT_FAILURE";
export const FINALIZE_TASK_SUCCESS = "FINALIZE_TASK_SUCCESS";
export const FINALIZE_TASK_FAILURE = "FINALIZE_TASK_FAILURE";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const GET_DOCUMENT_FAILURE = "GET_DOCUMENT_FAILURE";
export const CLEAR_DOCUMENT_COMPANY = "CLEAR_DOCUMENT_COMPANY";
export const GET_INN_DOCUMENT_SUCCESS = "GET_INN_DOCUMENT_SUCCESS";
export const GET_INN_DOCUMENT_FAILURE = "GET_INN_DOCUMENT_FAILURE";
export const GET_USN_DOCUMENT_SUCCESS = "GET_USN_DOCUMENT_SUCCESS";
export const GET_USN_DOCUMENT_FAILURE = "GET_USN_DOCUMENT_FAILURE";
export const GET_BANK_DOCUMENT_SUCCESS = "GET_BANK_DOCUMENT_SUCCESS";
export const GET_BANK_DOCUMENT_FAILURE = "GET_BANK_DOCUMENT_FAILURE";
export const GET_SNILS_DOCUMENT_SUCCESS = "GET_SNILS_DOCUMENT_SUCCESS";
export const GET_SNILS_DOCUMENT_FAILURE = "GET_SNILS_DOCUMENT_FAILURE";
export const GET_INCOME_DOCUMENT_SUCCESS = "GET_INCOME_DOCUMENT_SUCCESS";
export const GET_INCOME_DOCUMENT_FAILURE = "GET_INCOME_DOCUMENT_FAILURE";
export const GET_DECISION_DOCUMENT_SUCCESS = "GET_DECISION_DOCUMENT_SUCCESS";
export const GET_DECISION_DOCUMENT_FAILURE = "GET_DECISION_DOCUMENT_FAILURE";
export const GET_PASSPORT_DOCUMENT_SUCCESS = "GET_PASSPORT_DOCUMENT_SUCCESS";
export const GET_PASSPORT_DOCUMENT_FAILURES = "GET_PASSPORT_DOCUMENT_FAILURES";
export const GET_CONTRACT_DOCUMENT_SUCCESS = "GET_CONTRACT_DOCUMENT_SUCCESS";
export const GET_CONTRACT_DOCUMENT_FAILURE = "GET_CONTRACT_DOCUMENT_FAILURE";
export const GET_MIGRATION_DOCUMENT_SUCCESS = "GET_MIGRATION_DOCUMENT_SUCCESS";
export const GET_MIGRATION_DOCUMENT_FAILURE = "GET_MIGRATION_DOCUMENT_FAILURE";
export const GET_DOCUMENT_FILE_SUCCESS = "GET_DOCUMENT_FILE_SUCCESS";
export const GET_DOCUMENT_FILE_FAILURE = "GET_DOCUMENT_FILE_FAILURE";
export const GET_BANK_STATEMENT_SUCCESS = "GET_BANK_STATEMENT_SUCCESS";
export const GET_BANK_STATEMENT_FAILURE = "GET_BANK_STATEMENT_FAILURE";
export const GET_USN_COMPANY_DOCUMENT_SUCCESS = "GET_USN_COMPANY_DOCUMENT_SUCCESS";
export const GET_USN_COMPANY_DOCUMENT_FAILURE = "GET_USN_COMPANY_DOCUMENT_FAILURE";
export const GET_BANK_COMPANY_DOCUMENT_SUCCESS = "GET_BANK_COMPANY_DOCUMENT_SUCCESS";
export const GET_BANK_COMPANY_DOCUMENT_FAILURE = "GET_BANK_COMPANY_DOCUMENT_FAILURE";
export const GET_SNILS_COMPANY_DOCUMENT_SUCCESS = "GET_SNILS_COMPANY_DOCUMENT_SUCCESS";
export const GET_SNILS_COMPANY_DOCUMENT_FAILURE = "GET_SNILS_COMPANY_DOCUMENT_FAILURE";
export const GET_PASSPORT_COMPANY_DOCUMENT_SUCCESS = "GET_PASSPORT_COMPANY_DOCUMENT_SUCCESS";
export const GET_PASSPORT_COMPANY_DOCUMENT_FAILURE = "GET_PASSPORT_COMPANY_DOCUMENT_FAILURE";
export const GET_DECISION_COMPANY_DOCUMENT_SUCCESS = "GET_DECISION_COMPANY_DOCUMENT_SUCCESS";
export const GET_DECISION_COMPANY_DOCUMENT_FAILURE = "GET_DECISION_COMPANY_DOCUMENT_FAILURE";
export const GET_MIGRATION_COMPANY_DOCUMENT_SUCCESS = "GET_MIGRATION_COMPANY_DOCUMENT_SUCCESS";
export const GET_MIGRATION_COMPANY_DOCUMENT_FAILURE = "GET_MIGRATION_COMPANY_DOCUMENT_FAILURE";
export const UPDATE_USN_COMPANY_DOCUMENT_SUCCESS = "UPDATE_USN_COMPANY_DOCUMENT_SUCCESS";
export const UPDATE_USN_DOCUMENT_COMPANY_FAILURE = "UPDATE_USN_DOCUMENT_COMPANY_FAILURE";
export const UPDATE_BANK_COMPANY_DOCUMENT_SUCCESS = "UPDATE_BANK_COMPANY_DOCUMENT_SUCCESS";
export const UPDATE_BANK_COMPANY_DOCUMENT_FAILURE = "UPDATE_BANK_COMPANY_DOCUMENT_FAILURE";
export const UPDATE_SNILS_COMPANY_DOCUMENT_SUCCESS = "UPDATE_SNILS_COMPANY_DOCUMENT_SUCCESS";
export const UPDATE_SNILS_COMPANY_DOCUMENT_FAILURE = "UPDATE_SNILS_COMPANY_DOCUMENT_FAILURE";
export const UPDATE_PASSPORT_COMPANY_DOCUMENT_SUCCESS = "UPDATE_PASSPORT_COMPANY_DOCUMENT_SUCCESS";
export const UPDATE_PASSPORT_COMPANY_DOCUMENT_FAILURE = "UPDATE_PASSPORT_COMPANY_DOCUMENT_FAILURE";
export const UPDATE_DECISION_COMPANY_DOCUMENT_SUCCESS = "UPDATE_DECISION_COMPANY_DOCUMENT_SUCCESS";
export const UPDATE_DECISION_COMPANY_DOCUMENT_FAILURE = "UPDATE_DECISION_COMPANY_DOCUMENT_FAILURE";
export const UPDATE_MIGRATION_COMPANY_DOCUMENT_SUCCESS = "UPDATE_MIGRATION_COMPANY_DOCUMENT_SUCCESS";
export const UPDATE_MIGRATION_COMPANY_DOCUMENT_FAILURE = "UPDATE_MIGRATION_COMPANY_DOCUMENT_FAILURE";
export const CREATE_NEW_DOCUMENT_SUCCESS = "CREATE_NEW_DOCUMENT_SUCCESS";
export const CREATE_NEW_DOCUMENT_FAILURE = "CREATE_NEW_DOCUMENT_FAILURE";
export const GET_BANK_PACKAGES_STATEMENT_SUCCESS = "GET_BANK_PACKAGES_STATEMENT_SUCCESS";
export const GET_BANK_PACKAGES_STATEMENT_FAILURE = "GET_BANK_PACKAGES_STATEMENT_FAILURE";
export const GET_DOCUMENT_LIST_SUCCESS = "GET_DOCUMENT_LIST_SUCCESS";
export const GET_DOCUMENT_LIST_FAILURE = "GET_DOCUMENT_LIST_FAILURE";

// Reports actions 
export const GET_REPORTS_LISTING_SUCCESS = "GET_REPORTS_LISTING_SUCCESS";
export const GET_REPORTS_LISTING_FAILURE = "GET_REPORTS_LISTING_FAILURE";
export const GET_REPORTS_LISTING_PAGE_SIZE = "GET_REPORTS_LISTING_PAGE_SIZE";
export const GET_REPORTS_LISTING_PAGE_NUMBER = "GET_REPORTS_LISTING_PAGE_NUMBER";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";
export const REPORTS_LISTING_PARAMS_DATA = "REPORTS_LISTING_PARAMS_DATA";
export const REPORTS_CREATION_PARAMS_DATA = "REPORTS_CREATION_PARAMS_DATA";

////  Alpha 

// Contractors actions 
export const GET_CONTRACTORS_LIST_SUCCESS = "GET_CONTRACTORS_LIST_SUCCESS";
export const GET_CONTRACTORS_LIST_FAILURE = "GET_CONTRACTORS_LIST_FAILURE";
export const GET_CONTRACTOR_SUCCESS = "GET_CONTRACTOR_SUCCESS";
export const GET_CONTRACTOR_FAILURE = "GET_CONTRACTOR_FAILURE";

// Resumes actions
export const GET_RESUMES_LISTING_SUCCESS = "GET_RESUMES_LISTING_SUCCESS";
export const GET_RESUMES_LISTING_FAILURE = "GET_RESUMES_LISTING_FAILURE";
export const GET_RESUME_SUCCESS = "GET_RESUME_SUCCESS";
export const GET_RESUME_FAILURE = "GET_RESUME_FAILURE";

// Vacancy actions
export const GET_VACANCY_LIST_SUCCESS = "GET_VACANCY_LIST_SUCCESS";
export const GET_VACANCY_LIST_FAILURE = "GET_VACANCY_LIST_FAILURE";
export const GET_VACANCY_SUCCESS = "GET_VACANCY_SUCCESS";
export const GET_VACANCY_FAILURE = "GET_VACANCY_FAILURE";

// Application actions
export const GET_APPLICATION_LIST_SUCCESS = "GET_APPLICATION_LIST_SUCCESS";
export const GET_APPLICATION_LIST_FAILURE = "GET_APPLICATION_LIST_FAILURE";
export const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS";
export const GET_APPLICATION_FAILURE = "GET_APPLICATION_FAILURE";

// Contracts actions
export const GET_CONTRACTS_LIST_SUCCESS = "GET_CONTRACTS_LIST_SUCCESS";
export const GET_CONTRACTS_LIST_FAILURE = "GET_CONTRACTS_LIST_FAILURE";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILURE = "GET_CONTRACT_FAILURE";
