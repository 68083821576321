export const performersRole = (text: string | number): string | number => {
  switch (text) {
    case ("contact-center-role"):
      return "Сотрудник Контакт Центра";
    case ("accountant-role"):
      return "Бухгалтер";
    case("assistant-role"):
      return "Ассистент";
    case("realm-admin"):
    case("admin-role"):
      return "Администратор";
    case("quality-control-role"):
      return "Контроль качества";
    case("supervisor-role"):
      return "Супервайзер";
    case("tech-support-role"):
      return "Техническая поддержка";
    case("alpha-contractor-role"):
      return "Заказчик";
    default:
      return text;
  }
};
