interface ItemsProps {
  value: string,
  label: string,
}

export const itemsTasks:ItemsProps[] = [
  {
    value: "Онбординг",
    label: "Онбординг",
  },
  {
    value: "Исходящая задача",
    label: "Исходящая задача",
  },
  {
    value: "Обращение",
    label: "Обращение",
  },
  {
    value: "Разметка операций",
    label: "Разметка операций",
  },
  {
    value: "Типовая задача",
    label: "Типовая задача",
  },
];

export const itemsStatus: ItemsProps[] = [
  {
    value: "Новая",
    label: "Новая",
  },
  {
    value: "Поиск исполнителя",
    label: "Поиск исполнителя",
  },
  {
    value: "Назначена",
    label: "Назначена",
  },
  {
    value: "В работе",
    label: "В работе",
  },
  {
    value: "Приостановлена",
    label: "Приостановлена",
  },
];

export const itemsTax: ItemsProps[] = [
  {
    value: "УСН Доходы",
    label: "УСН Доходы",
  },
  {
    value: "УСН Доходы минус расходы",
    label: "УСН Доходы минус расходы",
  },
  {
    value: "ОСНО",
    label: "ОСНО",
  },
];

export const itemsTypeOPF: ItemsProps[] = [
  {
    value: "ИП",
    label: "ИП",
  },
  {
    value: "ООО",
    label: "ООО",
  },
];
