import React, { FC, JSX, useState } from "react";
import { ActionHistoryResultsType } from "app/types";
import css from "../ActionHistory.module.css";
import { ButtonCustom } from "../../../../ui-kit/ButtonCustom/ButtonCustom";

interface IActionHistoryCommentProps {
  action: ActionHistoryResultsType;
  widthHistory: number | undefined;
}

const ActionHistoryComment: FC<IActionHistoryCommentProps> = ({
   action,
   widthHistory
}): JSX.Element => {
  const [showText, setShowText] = useState<boolean>(false);

  const messageLabel = (text: string | null): string => {
    if (text) {
      const trimmedText: string = text.trim();
      return trimmedText.charAt(0).toUpperCase() + trimmedText.slice(1);
    }

    return "";
  };

  const actionHasLongText = (action: ActionHistoryResultsType): boolean => {
    const comment: string | null = action?.fields?.comment;

    if (!comment) {
      return false;
    }

    const tempElement: HTMLDivElement = document.createElement("div");
    tempElement.style.width = widthHistory ? `${widthHistory - 14}px` : "";
    tempElement.style.wordBreak = "break-word";
    tempElement.style.font = "14px";
    tempElement.style.whiteSpace = "pre-wrap";
    tempElement.style.lineHeight = "18px";
    tempElement.innerHTML = comment;
    document.body.appendChild(tempElement);

    const scrollHeight: number = tempElement.scrollHeight;
    const lineHeight: number = parseFloat(getComputedStyle(tempElement).lineHeight);

    document.body.removeChild(tempElement);

    const numberOfLines: number = scrollHeight / lineHeight;

    return numberOfLines > 3;
  };

  const finishedTaskAction: string = action?.fields?.task_decision_type === "system_message"
    ? css.historyText
    : css.message;

  return (
    <div>
      <div className={showText ? css.messageText : `${css.messageText} overflow-hidden max-h-[54px]`}>
        <div className={showText ? css.hideMessage : finishedTaskAction}>{messageLabel(action?.fields?.comment)}</div>
      </div>
      {actionHasLongText(action) && (
        <ButtonCustom
          type="link"
          className={css.textButton}
          text={showText ? "Скрыть" : "Показать полностью"}
          onClick={() => setShowText((prev: boolean) => !prev)}
        />
      )}
    </div>
  );
};

export default ActionHistoryComment;
