import React, { JSX } from "react";
import TagCustom from "../components/ui-kit/TagCustom/TagCustom";

export const parseStatus = (text: string = ""): JSX.Element | string => {
  switch (text) {
    //Task status
    case ("NEW"):
      return (
        <TagCustom color="customProcessing" text="Новая" />
      );
    case ("SEARCH_RESPONSIBLE"):
      return (
        <TagCustom color="customProcessing" text="Поиск исполнителя" />
      );
    case ("BOOKED"):
      return (
        <TagCustom color="customProcessing" text="Назначена" />
      );
    case ("IN_PROGRESS"):
      return (
        <TagCustom color="customWarning" text="В работе" />
      );
    case ("PAUSE"):
      return (
        <TagCustom color="customWarning" text="Приостановлена" />
      );
    case ("1C_SYNC"):
      return (
        <TagCustom color="customWarning" text="Синхронизация с 1С" />
      );
    case ("1C_SUCCESS"):
      return (
        <TagCustom color="customWarning" text="Отправлено в 1С" />
      );
    case ("1C_ERROR"):
      return (
        <TagCustom color="customError" text="Ошибка синхронизации с 1С" />
      );
    case ("FIN_TRUE"):
      return (
        <TagCustom color="customSuccess" text="Исполнена" />
      );
    case ("FIN_FALSE"):
      return (
        <TagCustom color="customError" text="Не исполнена" />
      );
    default:
      return "";
  }
};
